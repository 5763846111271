<template>
	<div class="stepContent">
		<ul class="formSteps">
			<li class="step stepOne">
				<p class="step_ttl">希望日<br class="sp">選択</p>
				<!-- <p class="step_cont">見学希望日選択</p> -->
			</li>
			<li class="step stepTwo">
				<p class="step_ttl">情報<br class="sp">入力</p>
				<!-- <p class="step_cont">お客様情報入力</p> -->
			</li>
			<li class="step stepThree">
				<p class="step_ttl">入力<br class="sp">確認</p>
				<!-- <p class="step_cont">お客様情報確認</p> -->
			</li>
			<li class="step stepFour">
				<p class="step_ttl">予約<br class="sp">完了</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Step',
	data () {
		return {
			
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	// ステップ
	.formSteps {
		display: flex;
		justify-content: space-between;
		width: 684px;
		list-style: none;
		margin: 0 auto;
	}

	// 768以下
	.picxel .formSteps {
		width: 100%;
	}

	.step {
		text-align: center;
		color: #FFF;
		&_ttl {
			position: relative;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 0.05em;
			border-radius: 4px;
			padding: 7px 19px 10px;
			opacity: .3;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: -40px;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-left: 10px solid #D5D5D5;
				border-top: 7.5px solid transparent;
				border-bottom: 7.5px solid transparent;
			}
		}
		// &_cont {
		// 	font-size: 16px;
		// 	text-align: center;
		// 	letter-spacing: 0.075em;
		// 	font-weight: bold;
		// }
	}

	.miyagi .step {
		&_ttl {
			background-color: #384D4D;
			border: 1px solid #CAE3C3;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: -40px;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-left: 10px solid #D5D5D5;
				border-top: 7.5px solid transparent;
				border-bottom: 7.5px solid transparent;
			}
		}
	}

	.yoichi .step {
		&_ttl {
			background-color: #5C0101;
			border: 1px solid #F8E5D4;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: -40px;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-left: 10px solid #5C0101;
				border-top: 7.5px solid transparent;
				border-bottom: 7.5px solid transparent;
			}
		}
	}

	// 768以下
	.picxel .step {
		width: calc(100% / 4 - 15px);
		&_ttl {
			font-size: 14px;
			padding: 6px 0;
			border-radius: 4px;
			&::after {
				content: "";
				position: absolute;
				right: -15px;
				border-left: 5px solid #CAE3C3;
				border-top: 3.5px solid transparent;
				border-bottom: 3.5px solid transparent;
			}
		}
		&_cont {
			text-align: left;
			font-size: 13px;
		}
	}

	.picxel .miyagi .step {
		&_ttl {
			&::after {
				content: "";
				position: absolute;
				right: -15px;
				border-left: 5px solid #CAE3C3;
				border-top: 3.5px solid transparent;
				border-bottom: 3.5px solid transparent;
			}
		}
	}

	.picxel .yoichi .step {
		&_ttl {
			&::after {
				content: "";
				position: absolute;
				right: -22px;
				border-left: 10px solid #5C0101;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
			}
		}
	}

	.stepOne {
		.step_ttl {
			opacity: 1;
		}
	}

	.stepFour {
		.step_ttl {
			&::after {
				display: none;
			}
		}
	}

	// お客様情報入力の画面時
	.twoStep {
		.stepOne {
			.step_ttl {
				opacity: 1;
			}
		}
		.stepTwo {
			.step_ttl {
				opacity: 1;
			}
		}
	}

	// お客様情報確認の画面時
	.threeStep {
		.stepOne {
			.step_ttl {
				opacity: 1;
			}
		}
		.stepTwo {
			.step_ttl {
				opacity: 1;
			}
		}
		.stepThree {
			.step_ttl {
				opacity: 1;
			}
		}
	}

	// 予約完了の画面時
	.fourStep {
		.stepOne {
			.step_ttl {
				opacity: 1;
			}
		}
		.stepTwo {
			.step_ttl {
				opacity: 1;
			}
		}
		.stepThree {
			.step_ttl {
				opacity: 1;
			}
		}
		.stepFour {
			.step_ttl {
				opacity: 1;
			}
		}
	}

	.sp {
		display: none;
	}

	.picxel .sp {
		display: block;
	}
</style>
