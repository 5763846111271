<template>
	<div class="tel">
		<div v-show="mygflg == true">
			<p class="tel_ttl">※ご予約・見学等に関するお問合せ</p>
			<div class="telAndFax">
				<div class="backWhite">
					<img src="../assets/img/telIcon.png" alt="電話アイコン" class="backWhite_img">
					<a href="tel:0223952865" class="backWhite_num">022-395-2865</a>
				</div>
				<div class="backWhite">
					<img src="../assets/img/faxIcon.png" alt="FAXアイコン" class="backWhite_img">
					<a href="tel:0223952305" class="backWhite_num">022-395-2305</a>
				</div>
			</div>
			<div class="backRec">
				<p class="backRec_ttl">お電話受付時間<span>9:00～16:00</span><span>（休業日を除く）</span></p>
			</div>
		</div>
		<div v-show="mygflg == false">
			<p class="tel_ttl">※見学等に関するお問い合わせ先</p>
			<div class="telAndFax">
				<div class="backWhite">
					<img src="../assets/img/telIcon.png" alt="電話アイコン" class="backWhite_img">
					<a href="tel:0135233131" class="backWhite_num">0135-23-3131</a>
				</div>
				<div class="backWhite">
					<img src="../assets/img/faxIcon.png" alt="FAXアイコン" class="backWhite_img">
					<a href="tel:0135233137" class="backWhite_num">0135-23-3137</a>
				</div>
			</div>
			<div class="backRec">
				<p class="backRec_ttl">お電話受付時間<span>9:00〜16:15</span><span>（休業日を除く）</span></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Tel',
	data () {
		return {
			mygflg: false
		}
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		var userAgent = window.navigator.userAgent.toLowerCase();
		if(userAgent.indexOf('safari') != -1 && userAgent.indexOf('chrome') == -1) {
			if(performance.navigation.type == 1) {
				localStorage.removeItem('courseInfo');
				localStorage.removeItem('selectCourseInfo');
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('plantId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref');
				localStorage.removeItem('tel');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				localStorage.removeItem('total_num');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				localStorage.removeItem('selectedEvent');
				localStorage.removeItem('prefData');
				localStorage.removeItem('visitNum');
				localStorage.removeItem('comeWay');
				localStorage.removeItem('errs');
				localStorage.removeItem('allergy');
			}
		}else {
			window.$(window).on('beforeunload', () => {
				// ブラウザ閉じたときにまたはリロードしたときに
				localStorage.removeItem('courseInfo');
				localStorage.removeItem('selectCourseInfo');
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('plantId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref');
				localStorage.removeItem('tel');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				localStorage.removeItem('total_num');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				localStorage.removeItem('selectedEvent');
				localStorage.removeItem('prefData');
				localStorage.removeItem('visitNum');
				localStorage.removeItem('comeWay');
				localStorage.removeItem('errs');
				localStorage.removeItem('allergy');
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.tel {
		width: 600px;
		margin: 0 auto 100px;
		border: 1px solid #ebebeb;
		background-color: #fff;
		&_ttl {
			font-size: 18px;
			text-align: center;
			font-weight: bold;
			background-color: #E8E0C4;
			padding: 15px 0;
		}
	}

	// 768以下
	.picxel .tel {
		width: calc(100% - 50px);
		margin-bottom: 70px;
		&_ttl {
			font-size: 14px;
			padding: 15px 0;
		}
	}

	.telAndFax {
		display: flex;
		justify-content: space-between;
		width: 460px;
		margin: 0 auto;
	}

	// 768以下
	.picxel .telAndFax {
		display: block;
		width: 100%;
	}

	.backWhite {
		width: 210px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 10px auto;
		&_img {
			width: 30px;
		}
		&_num {
			font-size: 26px;
			font-weight: bold;
			text-decoration: none;
			color: #000;
		}
	}

	// 768以下
	.picxel .backWhite {
		width: 186px;
		margin: 10px auto;
		&_img {
			width: 27px;
			height: 27px;
		}
		&_num {
			font-size: 23px;
		}
	}

	.backRec {
		width: 390px;
		margin: 0 auto 10px;
		font-size: 18px;
		&_ttl {
			span {
				margin-left: 10px;
			}
		}
	}

	// 768以下
	.picxel .backRec {
		width: 190px;
		margin-bottom: 10px;
		font-size: 14px;
		text-align: center;
		line-height: 1.6 !important;
		&_ttl {
			span {
				margin-left: 10px;
			}
		}
	}
</style>
