<template>
	<div class="eventDetail" :class="{ 'is-maintenance' : maintenance_flag }">

		<template v-if="maintenance_flag === true">
      <div class="maintenance">
        <h2 class="maintenance_ttl">ただいま<br class="sp" />メンテナンス中です</h2>
        <p class="maintenance_txt">メンテナンス中のため一時サービスを停止しております。<br />大変ご不便をおかけいたしますが、今しばらくお待ちください。</p>
      </div>
    </template>
		

		<template v-if="maintenance_flag === false">
			<MainVisual />
			<div class="eventModal">
				<div class="pictureAll" v-show="modalItem.event_picture">
					<div class="pictureAll_conts">
						<p class="pictureAll_name" v-if="modalItem.plant_name" v-html="modalItem.plant_name"></p>
						<p class="pictureAll_ttl mincho" v-show="modalItem.event_title" v-html="modalItem.event_title"></p>
						<div class="pictureAll_txt" v-show="modalItem.event_overview" v-html="modalItem.event_overview"></div>
					</div>
					<div class="pictureAll_img" v-show="modalItem.event_picture">
						<img :src="`/storage/${modalItem.event_picture}`" alt="">
					</div>
				</div>
				<p class="eventModal_name" v-if="!modalItem.event_picture && modalItem.plant_name" v-html="modalItem.plant_name"></p>
				<section class="eventSection">
					<h2 class="eventSection_none" v-show="modalItem.none" v-html="modalItem.none"></h2>
					<h2 class="eventSection_ttl mincho" v-show="!modalItem.event_picture && modalItem.event_title" v-html="modalItem.event_title"></h2>
					<div class="eventSection_txt" v-show="!modalItem.event_picture && modalItem.event_overview" v-html="modalItem.event_overview"></div>
					<ul class="eventContents" v-show="modalItem.event_title">
						<li class="eventContent" v-show="modalItem.event_hall_name">
							<h3 class="eventContent_ttl">会場</h3>
							<p class="eventContent_cont" v-html="modalItem.event_hall_name"></p>
						</li>
						<li class="eventContent" v-show="modalItem.event_date">
							<h3 class="eventContent_ttl">開催日</h3>
							<p class="eventContent_cont" v-html="modalItem.event_date"></p>
						</li>
						<li class="eventContent" v-show="modalItem.event_required_time">
							<h3 class="eventContent_ttl">所要時間</h3>
							<div class="eventContent_cont" v-html="modalItem.event_required_time"></div>
						</li>
						<li class="eventContent" v-show="modalItem.event_capacity">
							<h3 class="eventContent_ttl">定員</h3>
							<div class="eventContent_cont" v-html="modalItem.event_capacity"></div>
						</li>
						<li class="eventContent" v-show="modalItem.event_target">
							<h3 class="eventContent_ttl">対象</h3>
							<div class="eventContent_cont" v-html="modalItem.event_target"></div>
						</li>
						<li class="eventContent" v-show="modalItem.event_detail">
							<h3 class="eventContent_ttl">内容</h3>
							<div class="eventContent_cont" v-html="modalItem.event_detail"></div>
							<div class="prohibited" v-show="modalItem.reminder_icon_flg">
									<ul class="prohibited__list">
										<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
										<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
										<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
									</ul>
								</div>
						</li>
						<li class="eventContent" v-show="modalItem.event_fee">
							<h3 class="eventContent_ttl">参加費</h3>
							<div class="eventContent_cont" v-html="modalItem.event_fee"></div>
						</li>
					</ul>

					<section class="formContent detailContent">
						<h3 class="formContent_ttl mincho">見学予約の流れ</h3>
						<Step class="oneStep" />
						<section class="formNum courseOne">
							<h4 class="formNum_ttl mincho">日付時刻の選択</h4>
							<p class="formNum_txt">
								現時点でご予約可能な時間帯がある日です。ご希望の日を選択してください。
							</p>
							<ul class="carefulRice">
								<li class="carefulRice_li" v-show="plantId == 1000">
									現状、毎月15日に翌1か月分の公開となります。
									<p class='carefulRice_li_cont'>
										<span>例）5/15公開→6/1～6/30分受付</span>
									</p>
								</li>
								<li class="carefulRice_li">ご不明な点がございましたら、お電話にて承ります。</li>
							</ul>
							<section class="eventScedule" v-show="eventAll != 0 && eventAll != null">
								<h5 class="eventScedule_ttl" v-html="modalItem.event_title"></h5>
								<ul class="exFlex eventFlex">
									<li class="exBox">
										<div class="exBox_color eventFree"></div>
										<p class="exBox_txt">受付中</p>
									</li>
									<li class="exBox">
										<div class="exBox_color eventMax"></div>
										<p class="exBox_txt">満席</p>
									</li>
								</ul>
								<ul class="eventTime">
									<li v-for="dateItem in filterDates" :key="dateItem.date">
										<div class="eventTime_li" v-show="dateItem.rsv_slot.length">
											<p class="eventTime_li_date">
												<span class="eventTime_li_date_big">{{ month(dateItem.date) }}</span>月<span class="eventTime_li_date_big">{{ date(dateItem.date) }}</span>日{{ day(dateItem.date) }}
											</p>
											<ul class="eventDateline">
												<li class="eventDateline_li" v-for="timeInfo in filterList(dateItem.rsv_slot)" :key="timeInfo.start_time">
													<ul class="eventTimeline">
														<li class="eventTimeline_li" v-for="eventInfo in timeInfo.rsv_course" :key="eventInfo.rsv_course_id">
															<button class="eventTimeline_li_btn" v-show="eventInfo.rsv_remaining_num > 0" @click="selectTime(eventInfo, timeInfo.start_time, dateItem.date)">
																<div class="eventFreetime">
																	<div class="eventFreetime_time">{{ retakeTime(timeInfo.start_time) }}</div>
																	<div class="eventFreetime_num">
																		残り<span class="timeline_big">{{ eventInfo.rsv_remaining_num }}</span>名
																	</div>
																</div>
															</button>
															<div class="eventMaxtime" v-show="eventInfo.rsv_remaining_num <= 0">
																<div class="eventMaxtime_time">{{ retakeTime(timeInfo.start_time) }}</div>
																<div class="eventMaxtime_num">
																	満席
																</div>
															</div>
														</li>
													</ul>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</section>
							<div class="noneEvent" v-show="eventAll == 0 && plantId == 1000">
								現在、こちらのセミナー開催の<br class="sp">予定はありません。
							</div>
							<div class="noneEvent" v-show="eventAll == 0 && plantId == 1001">
								お問い合わせは<br class="sp">0135-23-3131までお願いします。
							</div>
						</section>
					</section>
					<router-link tag="a" to="/event" class="eventLink bannerLink">
						<img src="../assets/img/event/event_banner.png" alt="イベント・セミナー一覧 詳しくはこちら">
					</router-link>
				</section>

				<!-- 一般見学 -->
				<section class="noarmalEvent">
					<h2 class="noarmalEvent_ttl mincho">一般見学</h2>
					<p class="noarmalEvent_txt">
						ご案内係がウイスキーの製造工程や<br class="sp">ニッカウヰスキーの歴史について丁寧に<br>ご紹介いたします。無料の試飲や<br class="sp">お買い物もお楽しみいただけます。
					</p>
					<router-link tag="a" to="/miyagikyo/reservation" class="plantLink bannerLink" v-show="plantId == 1000">
						<img src="../assets/img/event/miyagikyo_banner.png" alt="宮城峡蒸溜所一般見学 お申込みはこちら">
					</router-link>
					<router-link tag="a" to="/yoichi/reservation" class="plantLink bannerLink" v-show="plantId == 1001">
						<img src="../assets/img/event/yoichi_banner.png" alt="余市蒸溜所一般見学 お申込みはこちら">
					</router-link>
				</section>

					<!-- バナー start -->
					<div class="events_banner eventsBanner">
						<div class="eventsBanner_img">
							<a href="https://www.nikka.com/distilleries/experience/"><img src="../assets/img/event/img_banner.png" alt=""></a>
						</div>
					</div>
					<!-- バナー end -->

					<!-- お問い合わせ start -->
				<div class="events_contact eventsContact" v-show="plantId == 1000">
					<div class="eventsContact_ttl u-font_notoSans">※見学等に関するお問い合わせ先</div>
					<div class="eventsContact_txt">
						<a href="tel:0223952865" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">022-395-2865 <span>(宮城峡蒸溜所)</span></span>
						</a>
						<a href="tel:0135233131" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">0135-23-3131 <span>(余市蒸溜所)</span></span>
						</a>
						<br>
						<div class="eventsContact_time u-font_notoSans">
							<div class="eventsContact_time_ttl">お電話受付時間 </div>
							<div class="plantTimes">
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>宮城峡蒸溜所 9:00～16:00<br class="u-sp">（休業日除く）</span>
								</div>
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>余市蒸溜所 9:00～16:30<br class="u-sp">（休業日除く）</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="events_contact eventsContact"  v-show="plantId == 1001">
					<div class="eventsContact_ttl u-font_notoSans">※見学等に関するお問い合わせ先</div>
					<div class="eventsContact_txt">
						<a href="tel:0135233131" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">0135-23-3131 <span>(余市蒸溜所)</span></span>
						</a>
						<a href="tel:0223952865" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">022-395-2865 <span>(宮城峡蒸溜所)</span></span>
						</a>
						<br>
						<div class="eventsContact_time u-font_notoSans">
							<div class="eventsContact_time_ttl">お電話受付時間 </div>
							<div class="plantTimes">
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>余市蒸溜所 9:00～16:15（休業日除く）</span>
								</div>
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>宮城峡蒸溜所 9:00～16:00（休業日除く）</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- お問い合わせ end -->
			</div>
		</template>
  </div>
</template>

<script>
import MainVisual from './MainVisual'
import Step from './Step'
import moment from 'moment'
import axios from 'axios'

export default {
	name: 'eventDetail',
	components: {
		MainVisual,
		Step
	},
	data () {
		return {
			plantId: 0,
			selectId: null,
			modalItem: [],
			nowHours: new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getHours(),
			eventAll: 0,
			disp_flg: 0,
			courseInfo: localStorage.getItem('courseInfo'),
			plantName: "",
			url: "/api/reserveSlot/list",
			week: ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"],
			resData: '',
			targetDate: '',
			availableDates: [],
			prefData: null,
			comeWay: null,
			selectedDate: null,
			defaultTime: null,
			selectEvent: null,
			selectCourseInfo: null,
			maintenance_flag:false
		}
	},
	computed: {
		filterDates: function() {
			return this.availableDates.filter( (eventDate) => {
				this.disp_flg = 0;
				let diffMilliSec = new Date(eventDate.date) - new Date();
				// forで全ての時間帯をまわして全ての時間でweb_disp_flgが2だったらその日にち自体を表示させない
				for(let i = 0; i < eventDate.rsv_slot.length; i++) {
					if(eventDate.rsv_slot[i].web_disp_flg == 1 && eventDate.rsv_slot[i].rsv_course.length != 0) {
						this.disp_flg++;
						if(parseInt(diffMilliSec / 1000 / 60 / 60 / 24) > 0) {
							this.eventAll++;
						}
					}
				}
				// 12時前だけ次の日も表示させるようにする
				if(this.nowHours < 12) {
					return new Date(eventDate.date) > new Date() && this.disp_flg != 0
				}else {
					return this.eventAll != 0 && this.disp_flg != 0
				}
			})
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'イベント詳細'
			}
		},
		link: [
      { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
      { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    ],
    script: [
      { type: 'text/javascript', src: '/include/include.js' }
    ]
	},
	mounted() {
		// 一番上まで移動
		window.$("body, html").scrollTop(0);
		
		this.plantId = this.$route.query.plant_id;
		this.selectId = this.$route.query.event_id;

		if(this.plantId == 1000) {
			this.plantName = "宮城峡蒸溜所"
		}else if(this.plantId == 1001) {
			this.plantName = "余市蒸溜所"
		}

		window.scrollTo(0, 0);

		if(this.courseInfo == null) {
			axios({
				method: "post",
				url: this.url,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					plant_id: this.plantId,
					display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM")
				}
			}).then(res => {
				this.courseInfo = res.data.data.course_info;
				localStorage.setItem('courseInfo', JSON.stringify(this.courseInfo));
				this.setEvent();
			});
		}else {
			this.courseInfo = JSON.parse(this.courseInfo);
			this.setEvent();
		}

		// メンテナンス時の表示切替用フラグをjsonから取得
    axios.get("/files/maintenance.json").then((res) => {
      this.maintenance_flag = res.data.data.maintenance_flag;
		});


  },
	methods: {
		setEvent() {
			var hit = 0;
			// 一致するコースIDがあるかどうか見る
			for(var c = 0; c < this.courseInfo.length; c++) {
				if(this.courseInfo[c].course_id == this.selectId) {
					hit++;
					this.modalItem = [];
					this.modalItem.selectId = this.courseInfo[c].course_id;
					this.modalItem.plant_name = this.plantName;
					this.modalItem.event_title = this.courseInfo[c].name;
					this.modalItem.event_picture = this.courseInfo[c].image;
					this.modalItem.event_overview = this.courseInfo[c].overview;
					this.modalItem.event_hall_name = this.courseInfo[c].place;
					this.modalItem.event_date = this.courseInfo[c].course_date;
					this.modalItem.event_required_time = this.courseInfo[c].total_time;
					this.modalItem.event_capacity = this.courseInfo[c].capacity;
					this.modalItem.event_target = this.courseInfo[c].target;
					this.modalItem.event_detail = this.courseInfo[c].detail;
					this.modalItem.event_fee = this.courseInfo[c].fee;

					this.modalItem.reminder_icon_flg = this.courseInfo[c].reminder_icon_flg;

					// this.modalItem.event_inquiry = this.courseInfo[c].inquiry;
				}
			}

			if(hit == 0) {
				this.modalItem = [];
				this.modalItem.none = 'コースのデータが存在しません。'
			}

			// ローカルストレージに工場IDをセット
			localStorage.setItem('plantId', this.plantId);

			this.getEvent();
		},
		getEvent() {
			axios({
				method: "post",
				url: this.url,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					plant_id: this.plantId,
					display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM"),
					course_id: this.selectId
				}
			}).then(res => {
				this.resData = res;
				this.availableDates = res.data.data.date_info;
				this.eventAll = 0;
			});
		},

		month(dateData) {
			var dd = new Date(new Date(dateData).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
			return dd.getUTCMonth() + 1;
		},

		date(dateData) {
			var dd = new Date(new Date(dateData).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
			return dd.getUTCDate();
		},

		day(dateData) {
			var dd = new Date(new Date(dateData).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
			return this.week[dd.getDay()];
		},

		filterList(filNum) {
			return filNum.filter((filted) => {
				return filted.web_disp_flg == 1 && filted.rsv_course.length != 0
			})
		},

		retakeTime(time) {
			var slice = Number(time);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		selectTime(eventData, time, date) {
			var dd = new Date(new Date(date).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
			this.selectedDate = moment(dd).format("YYYY-MM-DD");
			this.defaultTime = time;
			this.selectEvent = eventData;

			// 15時半以降出ないようにする
			// this.targetItem = this.targetDate.rsv_slot.filter((result) => {
			// 	return Number(result["start_time"].replace(/^0+/, '')) < 1531;
			// });

			// 住所まとめたもの
			this.prefData = this.resData.data.data.departure;
			// 海外除外処理を入れる
			this.prefData = this.prefData.filter((pref) => {
				return pref["overseas_flg"] == 2;
			});

			// 移動手段をまとめたもの
			this.courseInfo = this.resData.data.data.course_info;
			this.comeWay = this.resData.data.data.come_way;

			// コースの設定の移動手段に絞る
			for(var c = 0; c < this.courseInfo.length; c++) {
				if(this.courseInfo[c].course_id == this.selectId) {
					if(this.courseInfo[c].condition_transportation != null) {
						this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(',');
						for(var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
							for(var m = 0; m < this.comeWay.length; m++) {
								if(this.comeWay[m].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
									this.comeWay.splice(m, 1);
								}
							}
						}
					}
				}
			}

			// シャトルバス対応
			if(this.plantId == 1000) {
				var flg = 0;
				var openDay = this.resData.data.data.plant_info.pickup_bus_operating_day;
				for(var s = 0; s < openDay.length; s++) {
					if(openDay[s] == this.targetDate.date) {
						flg++;
					}
				}
				// jsonデータ内に日付がなかったらシャトルバス除外する
				if(flg == 0) {
					this.comeWay = this.comeWay.filter((come) => {
						return !/送迎バス（シャトルバス）/.test(come["come_way_name"]);
					});
				}
				// 移動手段絞る
				localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
			}else {
				// 余市はそのまま
				localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
			}

			this.selectCourseInfo = this.courseInfo.filter((array) => {
				return array["course_id"] == this.selectEvent["rsv_course_id"];
			});

			localStorage.setItem('prefData', JSON.stringify(this.prefData));
			localStorage.setItem('visitNum', JSON.stringify(this.resData.data.data.vst_come_num));
			localStorage.setItem('selectedDate', this.selectedDate);
			localStorage.setItem('selectedTime', this.defaultTime);
			localStorage.setItem('selectedEvent', JSON.stringify(this.selectEvent));
			localStorage.setItem('selectCourseInfo', JSON.stringify(this.selectCourseInfo));

			// 入力画面へ移動
			if(this.plantId == 1000) {
				this.$router.push({ path: '/miyagikyo/reservation/form?eventflg=true' });
			}else {
				this.$router.push({ path: '/yoichi/reservation/form?eventflg=true' });
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reservation.is-maintenance {
  background-color: transparent!important;
  color: #000;
}
.maintenance {
  padding: 80px 0;
  &_ttl {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 0.05em;
    line-height: 1.5 !important;
  }
  &_txt {
    font-size: 18px;
    line-height: 2 !important;
    margin-bottom: 15px;
    text-align: center;
    letter-spacing: 0.05em;
  }
  &_end {
    width: 455px;
    box-sizing: border-box;
    font-size: 20px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #df1016;
    border: 1px solid #df1016;
    border-radius: 6px;
    padding: 10px 0;
    margin: 0 auto 30px;
  }
  &_last {
    font-size: 18px;
    line-height: 2 !important;
    text-align: center;
    margin-bottom: 80px;
  }
}

// 768以下
.picxel .maintenance {
  padding-top: 40px;
  &_ttl {
    width: calc(100% - 50px);
    font-size: 26px;
    margin: 0 auto 30px;
  }
  &_txt {
    width: calc(100% - 50px);
    margin: 0 auto 15px;
    font-size: 14px;
    text-align: left;
  }
  &_end {
    width: calc(100% - 50px);
    margin: 0 auto 15px;
    font-size: 16px;
    line-height: 1.8 !important;
    padding: 5px 20px;
  }
  &_last {
    width: calc(100% - 50px);
    margin: 0 auto 50px;
    font-size: 14px;
    text-align: left;
  }
}
</style>
<style lang="scss">
.eventModal {
	width: 100%;
	padding: 60px 0 80px;
	&_name {
		width: 100px;
		text-align: center;
		font-size: 16px;
		line-height: 1;
		color: #AF9754;
		border: 1px solid #AF9754;
		padding: 6px;
		margin: 0 auto 15px;
		transform: rotate(0.03deg);
		-moz-transform: rotate(0.03deg);
		-ms-transform: rotate(0.03deg);
		-o-transform: rotate(0.03deg);
		-webkit-transform: rotate(0.03deg);
	}
}

// 768以下
.picxel .eventModal {
	&_name {
		width: 90px;
		font-size: 13px;
		padding: 4px 0;
		margin-bottom: 10px;
	}
}

	.pictureAll {
		width: calc(100% - 30px);
		max-width: 1010px;
		margin: 0 auto 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&_conts {
			width: calc(40% - 20px);
		}
		&_name {
			width: 100px;
			text-align: center;
			font-size: 16px;
			line-height: 1;
			color: #AF9754;
			border: 1px solid #AF9754;
			padding: 6px;
			margin: 0 auto 43px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_ttl {
			width: 100%;
			font-size: 26px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_txt {
			font-size: 16px;
			margin-top: 10px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_img {
			width: calc(60% - 50px);
			img {
				width: 100%;
			}
		}
	}

	// 768以下
	.picxel .pictureAll {
		width: calc(100% - 50px);
		display: block;
		&_conts {
			width: 100%;
		}
		&_name {
			width: 90px;
			font-size: 15px;
			padding: 4px 0;
			margin-bottom: 14px;
		}
		&_ttl {
			width: calc(100% - 30px);
			font-size: 20px;
			margin-bottom: 12px;
		}
		&_txt {
			font-size: 12px;
			margin: 7px 0 20px;
		}
		&_img {
			width: 100%;
		}
	}

	.eventSection {
		width: calc(100% - 100px);
		max-width: 960px;
		margin: 0 auto;
		&_none {
			font-size: 26px;
			text-align: center;
			margin-bottom: 20px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_ttl {
			width: 100%;
			font-size: 26px;
			text-align: center;
			margin: 0 auto 9px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_txt {
			font-size: 20px;
			text-align: center;
			margin-bottom: 20px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_select {
			display: block;
			position: relative;
			width: 320px;
			color: #fff;
			background-color: #AF9754;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			text-decoration: none;
			border: none;
			outline: none;
			padding: 21px 0;
			margin: 40px auto 20px;
			cursor: pointer;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
			&::after {
				content: '';
				width: 7px;
				height: 7px;
				border: 0px;
				border-top: solid 2px #fff;
				border-right: solid 2px #fff;
				position: absolute;
				top: 50%;
				right: 30px;
				transform: rotate(45deg)translateY(-50%);
				margin-top: -2px;
			}
		}
		&_btn {
			display: block;
			width: calc(100% - 100px);
			max-width: 320px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			background-color: #fff;
			border: 1px solid #ADADAD;
			margin: 0 auto;
			padding: 16px 0;
			outline: none;			
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
			cursor: pointer;
			transition: .3s opacity ease-in-out;
			&:hover {
				opacity: .7;
			}
		}
	}

	// 768以下
	.picxel .eventSection {
		&_none {
			font-size: 20px;
			margin-bottom: 20px;
		}
		&_ttl {
			width: calc(100% - 30px);
			font-size: 20px;
			margin-bottom: 12px;
		}
		&_txt {
			text-align: left;
			font-size: 12px;
			margin-bottom: 20px;
		}
		&_select {
			width: 100%;
			font-size: 16px;
			padding: 16px 0;
			margin: 20px auto 15px;
			&::after {
				width: 7px;
				height: 7px;
			}
		}
		&_btn {
			font-size: 12px;
			width: 169px;
			padding: 13px 0;
		}
	}

	// イベントモーダルの詳細
	.eventContents {
		width: 100%;
		border-bottom: 1px solid #E8E8E8;
	}

	.eventContent {
		list-style: none;
		border-top: 1px solid #E8E8E8;
		display: flex;
		padding: 26px 0;
		flex-wrap: wrap;
		&_ttl {
			width: 150px;
			font-size: 16px;
			line-height: 1.75;
			padding-left: 16px;
			box-sizing: border-box;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_cont {
			width: calc(100% - 150px);
			font-size: 16px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
			line-height: 1.75 !important;
			p[style="color:red"] {
				font-size: 14px;
			}
		}
		.prohibited {
    margin: 16px 0 0 150px;
    &__list {
      display: flex;
      gap: 0 12px;
    }
    &__item {
    }
  }
	}

	// 768以下
	.picxel .eventContent {
		padding: 15px 0;
		&_ttl {
			width: 50px;
			width: 100px;
			font-size: 12px;
			padding-left: 0;
		}
		&_cont {
			width: calc(100% - 100px);
			font-size: 12px;
		}
		.prohibited {
			margin: 16px 0 0 100px;
		}
	}

	// TOPとレイアウトが若干違うので上書き
	.eventDetail .formContent {
		padding-bottom: 0;
	}

	.picxel .eventDetail .formContent {
		width: 100%;
	}

	.win .eventDetail.mainFont .formNum {
		padding-bottom: 40px;
	}

	.detailContent {
		.carefulRice {
			.carefulRice_li {
				color: #df0202 !important;
			}
		}
	}

	// スケジュール表
	.eventScedule {
		margin-top: 41px;
		&_ttl {
			font-size: 24px;
		}
	}

	// スケジュール表スマホ時
	.picxel .eventScedule {
		margin-top: 30px;
		&_ttl {
			font-size: 18px;
		}
	}

	.eventFlex {
		width: 240px;
	}

	.eventFree {
		background-color: #AE9754;
	}

	.eventMax {
		background-color: #D5D5D5;
	}

	// スケジュールタイムラインの大枠
	.eventTime {
		width: 100%;
		border-top: 2px solid #E8E8E8;
		margin-top: 40px;
		&_li {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			padding: 20px 0 5px;
			border-bottom: 2px solid #E8E8E8;
			&_date {
				font-size: 14px;
				font-weight: bold;
				&_big {
					font-size: 22px;
				}
			}
		}
	}

	.picxel .eventTime {
		&_li {
			display: block;
			padding: 20px 0 10px;
			&_date {
				font-size: 14px;
				margin-bottom: 15px;
				&_big {
					font-size: 22px;
				}
			}
		}
	}

	// 日付ごとのイベント時間割
	.eventDateline {
		width: calc(100% - 166px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		&_li {
			width: calc(50% - 20px);
			margin-bottom: 15px;
		}
	}

	.picxel .eventDateline {
		width: 100%;
		display: block;
		&_li {
			width: 100%;
			margin-bottom: 10px;
		}
	}

	// イベントの時間割
	.eventTimeline {
		width: 100%;
		&_li {
			width: 100%;
			&_btn {
				width: 100%;
				outline: 0;
				border: none;
			}
		}
	}

	// 空席がある場合のレイアウト
	.eventFreetime {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #AE9754;
		font-weight: bold;
		padding: 20px 0 18px;
		border-radius: 4px;
		color: #FFF;
		cursor: pointer;
		transition: opacity .3s ease-in-out;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 40px;
			width: 11px;
			height: 11px;
			border-right: 2px solid #FFF;
			border-bottom: 2px solid #FFF;
			transform: translateY(-50%)rotate(-45deg);
		}
		&:hover {
			opacity: .7;
		}
		&_time {
			font-size: 22px;
			margin-right: 15px;
		}
		&_num {
			font-size: 14px;
		}
		.timeline_big {
			font-size: 22px;
		}
	}

	.picxel .eventFreetime {
		padding: 14px 0 17px;
		&::after {
			width: 3.5px;
			height: 3.5px;
			border-right: 2px solid #FFF;
			border-bottom: 2px solid #FFF;
		}
		&_time {
			font-size: 18px;
			margin-right: 10px;
		}
		&_num {
			font-size: 14px;
		}
		.timeline_big {
			font-size: 18px;
		}
	}

	// 満席の場合のレイアウト
	.eventMaxtime {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #D5D5D5;
		font-weight: bold;
		padding: 20px 0 18px;
		border-radius: 4px;
		color: #9B9B9B;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 40px;
			width: 11px;
			height: 11px;
			border-right: 2px solid #9B9B9B;
			border-bottom: 2px solid #9B9B9B;
			transform: translateY(-50%)rotate(-45deg);
		}
		&_date {
			font-size: 14px;
		}
		&_time {
			font-size: 22px;
			margin: 0 15px;
		}
		&_num {
			font-size: 22px;
		}
	}

	.picxel .eventMaxtime {
		padding: 14px 0 17px;
		&::after {
			width: 3.5px;
			height: 3.5px;
			border-right: 2px solid #9B9B9B;
			border-bottom: 2px solid #9B9B9B;
		}
		&_time {
			font-size: 18px;
			margin-right: 10px;
		}
		&_num {
			font-size: 14px;
		}
	}

	// イベント時間割ない時
	.noneEvent {
		font-size: 16px;
		text-align: center;
		color: #9B9B9B;
		margin: 41px 0 60px;
		padding: 78px 0;
		background-color: #F1F1F1;
	}

	.picxel .noneEvent {
		font-size: 14px;
		margin-bottom: 40px;
		padding: 50px 10px;
	}

	// バナー
	.bannerLink {
		display: block;
		width: 600px;
		margin: 0 auto;
		transition: opacity .3s ease-out;
		&:hover {
			opacity: .7;
		}
		img {
			width: 100%;
		}
	}

	.picxel .bannerLink {
		width: 100%;
	}

	// イベントセミナーのリンク
	.eventLink {
		margin-bottom: 60px;
	}

	// 一般見学の白背景の欄
	.noarmalEvent {
		position: relative;
		width: 100%;
		padding: 53px 0 80px;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #FFF;
			z-index: -1;
		}
		&_ttl {
			font-size: 34px;
			font-weight: 100;
			text-align: center;
			margin-bottom: 15px;
		}
		&_txt {
			font-size: 15px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 21px;
		}
	}

	.picxel .noarmalEvent {
		padding: 35px 0 60px;
		&_ttl {
			font-size: 24px;
			margin-bottom: 12px;
		}
		&_txt {
			font-size: 14px;
			margin-bottom: 20px;
		}
		.plantLink {
			width: calc(100% - 50px);
		}
	}

	// イベントページのCSSにこのページだけに適用できる上書き
	.picxel .eventDetail .events_banner {
		width: calc(100% - 50px);
		margin: 40px auto 0;
	}

	.picxel .eventDetail .events_contact {
		width: calc(100% - 50px);
	}
</style>
