<template>
	<div class="selectEvent">
		<div class="eventModal">
				<div class="pictureAll" v-show="modalItem.event_picture">
					<div class="pictureAll_conts">
						<p class="pictureAll_ttl" v-show="modalItem.event_title" v-html="modalItem.event_title"></p>
						<div class="pictureAll_txt" v-show="modalItem.event_picture && modalItem.event_overview" v-html="modalItem.event_overview"></div>
					</div>
					<div class="pictureAll_img" v-show="modalItem.event_picture">
						<img :src="`/storage/${modalItem.event_picture}`" alt="">
					</div>
				</div>
				<section class="eventSection">
					<h2 class="eventSection_none" v-show="modalItem.none" v-html="modalItem.none"></h2>
					<h2 class="eventSection_ttl" v-show="!modalItem.event_picture && modalItem.event_title" v-html="modalItem.event_title"></h2>
					<div class="eventSection_txt" v-show="!modalItem.event_picture && modalItem.event_overview" v-html="modalItem.event_overview"></div>
					<ul class="eventContents" v-show="modalItem.event_title">
						<li class="eventContent" v-show="modalItem.event_hall_name">
							<h3 class="eventContent_ttl">Venue</h3>
							<p class="eventContent_cont" v-html="modalItem.event_hall_name"></p>
						</li>
						<li class="eventContent" v-show="modalItem.event_date">
							<h3 class="eventContent_ttl">Date</h3>
							<p class="eventContent_cont" v-html="modalItem.event_date"></p>
						</li>
						<li class="eventContent" v-show="modalItem.event_required_time">
							<h3 class="eventContent_ttl">Required time</h3>
							<div class="eventContent_cont" v-html="modalItem.event_required_time"></div>
						</li>
						<!-- <li class="eventContent" v-show="modalItem.event_capacity">
							<h3 class="eventContent_ttl">Non-eligible</h3>
							<div class="eventContent_cont" v-html="modalItem.event_capacity"></div>
						</li> -->
						<li class="eventContent" v-show="modalItem.event_target">
							<h3 class="eventContent_ttl">Non-eligible</h3>
							<div class="eventContent_cont" v-html="modalItem.event_target"></div>
						</li>
						<li class="eventContent" v-show="modalItem.event_detail">
							<h3 class="eventContent_ttl">Content</h3>
							<div class="eventContent_cont" v-html="modalItem.event_detail"></div>
						</li>
						<li class="eventContent" v-show="modalItem.event_fee">
							<h3 class="eventContent_ttl">Participant fee</h3>
							<div class="eventContent_cont" v-html="modalItem.event_fee"></div>
						</li>
					</ul>
					<button type="button" class="eventSection_select" v-show="modalItem.plant_name" @click="selectCourse()">Select</button>
					<!-- <button type="button" class="eventSection_btn" @click="closeEvent()">戻る</button> -->
					<router-link tag="a" :to="{path: '/eng/miyagikyo/reservation', query: { date: selectedDate }}" class="eventSection_btn" v-show="plantId == 1000">Back<span class="dateLink_arrow"></span></router-link>
					<router-link tag="a" :to="{path: '/eng/yoichi/reservation', query: { date: selectedDate }}" class="eventSection_btn" v-show="plantId == 1001">Back<span class="dateLink_arrow"></span></router-link>
				</section>
			</div>
			<Tel />
	</div>
</template>

<script>
import $ from 'jquery';
import Tel from './EngTel'

export default {
	name: 'selectEvent',
	components: {
		Tel
	},
	data () {
		return {
			mygflg: false,
			plantId: 0,
			plantName: "",
			modalItem: [],
			selectId: null,
			selectTime: null,
			defaultTime: null,
			selectedDate: localStorage.getItem('selectedDate'),
			courseInfo: JSON.parse(localStorage.getItem('courseInfo'))
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
			this.plantId = 1000;
			this.plantName = 'Miyagikyo Distillery';
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
			$(".selectEvent").addClass("eng");
		}else {
			this.mygflg = false;
			this.plantId = 1001;
			this.plantName = 'Yoichi Distillery';
			$(".selectEvent").addClass("eng");
		}

		this.selectId = this.$route.query.event_id;
		this.defaultTime = this.$route.query.time;
		this.selectTime = this.retakeDate(this.defaultTime);

		window.scrollTo(0, 0);
		
		var hit = 0;
		// 一致するコースIDがあるかどうか見る
		for(var c = 0; c < this.courseInfo.length; c++) {
			if(this.courseInfo[c].course_id == this.selectId) {
				hit++;
				this.modalItem = [];
				this.modalItem.selectId = this.courseInfo[c].course_id;
				this.modalItem.plant_name = this.plantName;
				this.modalItem.event_title = this.courseInfo[c].name;
				this.modalItem.event_picture = this.courseInfo[c].image;
				this.modalItem.event_overview = this.courseInfo[c].overview;
				this.modalItem.event_hall_name = this.courseInfo[c].place;
				this.modalItem.event_date = this.courseInfo[c].course_date;
				this.modalItem.event_required_time = this.courseInfo[c].total_time;
				this.modalItem.event_capacity = this.courseInfo[c].capacity;
				this.modalItem.event_target = this.courseInfo[c].target;
				this.modalItem.event_detail = this.courseInfo[c].detail;
				this.modalItem.event_fee = this.courseInfo[c].fee;
				// this.modalItem.event_inquiry = res.data.data.course_info[c].event_inquiry;
			}
		}

		if(hit == 0) {
			this.modalItem = [];
			this.modalItem.none = 'コースのデータが存在しません。'
		}
	},
	methods: {
		retakeDate(date) {
			var slice = Number(date);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		selectCourse() {
			// コースを選択したら入力画面へ移動
			if(this.mygflg == true) {
				this.$router.push({ path: '/eng/miyagikyo/reservation/form' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation/form' });
			}
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.selectEvent.eng {
	background-color: #251E1C;
}

.eventModal {
	width: 100%;
	padding: 60px 0 80px;
}

// 768以下
.picxel .eventModal {
	&_name {
		width: 180px;
		font-size: 26px;
		padding: 8px 0;
		margin-bottom: 20px;
	}
}

	.eng .pictureAll {
		width: calc(100% - 20px);
		max-width: 1010px;
		margin: 0 auto 48px;
		display: block;
		&_conts {
			width: 100%;
			margin-bottom: 32px;
		}
		&_ttl {
			font-size: 42px;
			font-weight: 400;
			text-align: center;
			word-break: break-all;
			color: #FFF;
			margin: 0;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_txt {
			font-size: 20px;
			text-align: center;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_img {
			width: 100%;
			max-width: 450px;
			margin: 0 auto;
			img {
				width: 100%;
			}
		}
	}

	// 768以下
	.picxel .eng .pictureAll {
		width: calc(100% - 20px);
		display: block;
		margin-bottom: 32px;
		&_conts {
			width: 100%;
			margin-bottom: 24px;
		}
		&_ttl {
			font-size: 28px;
			margin: 0 auto;
		}
		&_img {
			width: 100%;
		}
	}

	.eng .eventSection {
		width: calc(100% - 20px);
		max-width: 900px;
		margin: 0 auto;
		&_none {
			font-size: 26px;
			text-align: center;
			margin-bottom: 20px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_ttl {
			width: 100%;
			font-size: 26px;
			text-align: center;
			word-break: break-all;
			margin: 0 auto 9px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_txt {
			font-size: 20px;
			text-align: center;
			margin-bottom: 20px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_select {
			display: block;
			position: relative;
			width: 360px;
			color: #fff;
			background-color: #91701e;
			font-size: 20px;
			font-weight: 400;
			text-align: center;
			text-decoration: none;
			border: none;
			outline: none;
			padding: 17px 0;
			margin: 40px auto 20px;
			cursor: pointer;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
			border-radius: 4px;
			&::after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 10px;
				border-color: transparent transparent transparent #FFF;
				position: absolute;
				top: 50%;
				right: 32px;
				transform: translateY(-50%);
			}
		}
		&_btn {
			display: block;
			width: 360px;
			max-width: 360px;
			font-size: 20px;
			text-align: center;
			text-decoration: none;
			color: #FFF;
			background-color: #898989;
			border: none;
			margin: 0 auto;
			padding: 17px 0;
			outline: none;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				opacity: 1 !important;
			}
		}
	}

	// 768以下
	.picxel .eng .eventSection {
		&_none {
			font-size: 28px;
			margin-bottom: 24px;
		}
		&_ttl {
			width: calc(100% - 20px);
			font-size: 28px;
			margin-bottom: 24px;
		}
		&_select {
			width: 100%;
			max-width: 360px;
			font-size: 20px;
			padding: 17px 0;
			margin: 32px auto 16px;
			&::after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4px 0 4px 8px;
				border-color: transparent transparent transparent #FFF;
				position: absolute;
				top: 50%;
				right: 24px;
				transform: translateY(-50%);
			}
		}
		&_btn {
			width: 100%;
			max-width: 360px;
			font-size: 20px;
			padding: 17px 0;
		}
	}

	// イベントモーダルの詳細
	.eng .eventContents {
		position: relative;
		width: 100%;
		border-bottom: none;
		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 2px;
			background-size: 4px 2px;
			background-image: linear-gradient(to right, #595858, #595858 2px, transparent 2px, transparent 4px);
			background-repeat: repeat-x;
		}
	}

	.eng .eventContent {
		position: relative;
		list-style: none;
		border-top: none;
		display: flex;
		padding: 26px 0;
		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 2px;
			background-size: 4px 2px;
			background-image: linear-gradient(to right, #595858, #595858 2px, transparent 2px, transparent 4px);
			background-repeat: repeat-x;
		}
		&_ttl {
			width: 150px;
			font-size: 17px;
			line-height: 1.75;
			color: #898989;
			padding-left: 16px;
			box-sizing: border-box;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_cont {
			width: calc(100% - 150px);
			font-size: 17px;
			color: #FFF;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
			line-height: 1.75 !important;
			p[style="color:red"] {
				font-size: 14px;
			}
		}
	}

	// 768以下
	.picxel .eng .eventContent {
		display: block;
		padding: 24px 0;
		&_ttl {
			width: 100%;
			font-size: 17px;
			padding-left: 0;
		}
		&_cont {
			width: 100%;
			font-size: 17px;
			margin-top: 16px;
		}
	}
</style>
