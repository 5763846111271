<template>
	<div class="num eng">
		<h2 class="num_ttl">Reservation Number</h2>
		<p class="num_txt">
			Please enter your reservation number and phone number as shown in the “Reservation Confirmation Email” sent to the email address you provided during the reservation process or on the “Reservation Completion” page.
		</p>
		<ul class="num_red">
			<li>*Internet cancellations are accepted until 12:00 PM (noon) the day before the tour.</li>
			<li>*Any changes and cancellations after can only be made by phone. Please note that changes to the tour time may not be possible depending on the availability. Thank you for your understanding.</li>
			<!-- <li>※WEBでのご予約内容の変更、キャンセルはご予約いただきました日付の3日前まで承ります。それ以降の変更、キャンセルについてはお電話でのお問い合わせをお願いいたします。</li> -->
		</ul>
		<form action="/check" method="post" class="checkForm">
			<div class="inputBox">
				<p class="inputBox_ttl">Reservation Number<br>(alphanumeric characters)</p>
				<input type="text" name="res_num" id="password" class="inputBox_input">
			</div>
			<div class="inputBox">
				<p class="inputBox_ttl">Phone Number <br>(half-width numbers)</p>
				<div class="halfRight">
					<input type="text" name="number" id="number" class="inputBox_input">
					<p class="inputBox_txt">※Please include country code</p>
				</div>
			</div>
			<button type="button" class="checkForm_btn" @click="checkReservation()">Confirmation of <br class="sp">Reservation Details</button>
		</form>
		<router-link tag="a" to="/eng/miyagikyo/reservation" class="goToLink" v-show="mygflg == true">Return to Top Page</router-link>
		<router-link tag="a" to="/eng/yoichi/reservation" class="goToLink" v-show="mygflg == false">Return to Top Page</router-link>
		<Tel />
		<div class="errModal">
			<div class="errCont">
				<div class="errCont_img">
					<img src="../assets/img/error.png" alt="×">
				</div>
				<p class="errCont_ttl">Error !</p>
				<p id="errTxt" class="errCont_txt"></p>
				<p class="errCont_txt">Please double-check your<br class="sp"> reservation number and <br>the phone number you registered,<br class="sp"> and enter again.</p>
				<button class="errCont_close" @click="modalClose()">Close</button>
			</div>
			<div class="errModal_shadow" @click="modalClose()"></div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import Tel from './EngTel'
import moment from 'moment'
import axios from 'axios'

export default {
	name: 'ReservationInput',
	components: {
		Tel
	},
	data () {
		return {
			url: "/api/reserve/get",
			plantName: null,
			inputNum: null,
			inputTel: null,
			resData: null,
			resCourse: null,
			courseInfo: null,
			selectCourseInfo: null,
			error: null,
			scrollPos: 0,
			mygflg: false,
			availableDates: null,
			targetDate: null,
			prefData: null,
			visitNum: null,
			comeWay: null,
			paidInfo: false,
			selectAvailable: null
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1 || localStorage.getItem('plantId') == 1000) {
			this.mygflg = true;
			this.plantName = 'Miyagikyo Distillery';
		}else {
			this.plantName = 'Yoichi Distillery';
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
		}
		this.$emit('updateHead');
	},
	methods: {
		checkReservation() {
			// ボタンを一回しか押せないようにする
			$('.checkForm_btn').prop('disabled',true);

			// 入力欄に入力されたものの取得
			this.inputNum = $("#password").val();
			this.inputTel = $("#number").val();

			axios({
				method: "post",
				url: this.url,
				timeout: 500000,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					telno: this.inputTel,
					password: this.inputNum,
					language: 1
				}
			}).then(res => {
				this.resData = res.data.data;
				// 予約内容データの格納
				localStorage.setItem('old_tel', this.inputTel);
				localStorage.setItem('password', this.inputNum);
				localStorage.setItem('resId', this.resData.reservation_id);
				localStorage.setItem('plantId', this.resData.plant_id);
				localStorage.setItem('course_id', this.resData.course_id);
				localStorage.setItem('selectedDate', this.resData.date);
				localStorage.setItem('selectedTime', this.resData.time);
				localStorage.setItem('group_name', this.resData.group_name);
				localStorage.setItem('group_name_kana', this.resData.group_name_kana);
				// if(this.mygflg == false) {
				// 	localStorage.setItem('note', this.resData.represent_note);
				// }
				localStorage.setItem('pref', this.resData.start_place_cd);
				localStorage.setItem('tel', this.resData.represent_tel);
				localStorage.setItem('email', this.resData.vst_mail_address);
				localStorage.setItem('companion_name', this.resData.companion_name);
				localStorage.setItem('companion_name_kana', this.resData.companion_name_kana);
				localStorage.setItem('count', this.resData.vst_come_num_id);
				localStorage.setItem('total_num', this.resData.total_num);
				localStorage.setItem('adult_num', this.resData.adult_num);
				// localStorage.setItem('men', this.resData.adult_men_num);
				// localStorage.setItem('women', this.resData.adult_female_num);
				localStorage.setItem('minor_num', this.resData.minor_num);
				localStorage.setItem('visit', this.resData.come_way_cd);
				localStorage.setItem('unit', this.resData.come_way_num);
				
				//202408
				localStorage.setItem('non_alcohol_flg', this.resData.non_alcohol_flg);
				localStorage.setItem('non_alcohol_num', this.resData.non_alcohol_num);
				localStorage.setItem('alcohol_num', this.resData.alcohol_num);

				// イベント情報も取得しておく
				axios({
					method: "post",
					url: "/api/reserveSlot/list",
					headers: {
						"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
					},
					data: {
						plant_id: this.resData.plant_id,
						display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM"),
						language: 1
					}
				}).then(res => {
					// 予約してある日を元にデータを探す
					this.resCourse = res.data.data;
					this.availableDates = this.resCourse.date_info;
					this.courseInfo = this.resCourse.course_info;
					this.prefData = this.resCourse.departure;
					this.visitNum = this.resCourse.vst_come_num;
					this.comeWay = this.resCourse.come_way;
					// 冬季期間のデータ
					this.winter = this.resCourse.plant_info;

					// コース情報をローカルストレージに保存
					localStorage.setItem('courseInfo', JSON.stringify(this.courseInfo));
					
					this.targetDate = this.availableDates.find((v) => v.date === this.resData.date);

					for(var i = 0; i < this.targetDate.rsv_slot.length; i++) {
						if(this.targetDate.rsv_slot[i].start_time == this.resData.time) {
							for(var j = 0; j < this.targetDate.rsv_slot[i].rsv_course.length; j++) {
								if(this.targetDate.rsv_slot[i].rsv_course[j].rsv_course_id == this.resData.course_id) {
									this.selectEvent = this.targetDate.rsv_slot[i].rsv_course[j]
								}
							}
						}
					}

					// 有料イベントかどうか
					if(this.selectEvent.rsv_course_type_name.indexOf('有料') != -1) {
						this.paidInfo = true;
					}

          // 緊急事態宣言が出ている地域は住所から除外する
          // if(this.resData.plant_id == 1000) {
          //   this.prefData = this.prefData.filter((pref) => {
          //     return !/北海道|茨城県|栃木県|群馬県|埼玉県|千葉県|東京都|神奈川県|新潟県|富山県|石川県|福井県|山梨県|長野県|岐阜県|静岡県|愛知県|三重県|滋賀県|京都府|大阪府|兵庫県|奈良県|和歌山県|鳥取県|島根県|岡山県|広島県|山口県|徳島県|香川県|愛媛県|高知県|福岡県|佐賀県|長崎県|長崎県|熊本県|大分県|宮崎県|鹿児島県|沖縄県/.test(pref["departure_name"]);
          //   });
          // }
					// API側の対応で必要なくなった
          // 海外以外除外
          // this.prefData = this.prefData.filter((pref) => {
          //   return pref["overseas_flg"] == 1;
          // });

					// コースの設定の移動手段に絞る
					for(var c = 0; c < this.courseInfo.length; c++) {
						if(this.courseInfo[c].course_id == this.resData.course_id) {
							if(this.courseInfo[c].condition_transportation != null) {
								this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(',');
								for(var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
									for(var y = 0; y < this.comeWay.length; y++) {
										if(this.comeWay[y].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
											this.comeWay.splice(y, 1);
										}
									}
								}
							}
						}
					}
					// シャトルバス対応
					if(this.resData.plant_id == 1000) {
						var flg = 0;
						// シャトルバス運行json読み込み
						var openDay = this.resCourse.plant_info.pickup_bus_operating_day;
						for(var s = 0; s < openDay.length; s++) {
							if(openDay[s] == this.targetDate.date) {
								flg++;
							}
						}
						// jsonデータ内に日付がなかったらシャトルバス除外する
						if(flg == 0) {
							this.comeWay = this.comeWay.filter((come) => {
								return !/送迎バス（シャトルバス）/.test(come["come_way_name"]);
							});
						}
						// 移動手段絞る
						localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
					}else {
						// 余市はそのまま
						localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
					}

					// 今までのシャトルバス対応
					// if(this.plantId == 1000) {
					//   // 選択した日の月と日をつなげた数字を出す
					//   var selectNum = String(this.selectMonth) + String(("00" + this.selectDate).slice( -2 ));
					//   // 冬季期間終了日の先頭の0を消す
					//   this.winter.pickup_bus_winter_period_to = this.winter.pickup_bus_winter_period_to.replace(/^0+/, '');
					//   // 冬季期間中かどうか
					//   if(Number(selectNum) >= Number(this.winter.pickup_bus_winter_period_from) || Number(selectNum) <= Number(this.winter.pickup_bus_winter_period_to)) {
					//     // 冬季期間中ならばcomeWayからシャトルバスを除く
					//     for(var w = 0; w < this.comeWay.length; w++) {
					//       if(this.comeWay[w].come_way_name.indexOf('シャトルバス') != -1) {
					//         this.comeWay.splice(w, 1);
					//       }
					//     }
					//   }else {
					//     // 選択した日のdate_infoをとってくる
					//     for(var s = 0; s < this.availableDates.length; s++) {
					//       if(this.availableDates[s].date == this.selectedDate) {
					//         this.selectAvailable = this.availableDates[s];
					//       }				
					//     }
					//     // 選択した日が土日または祝日かどうか
					//     if(this.selectDay == '土' || this.selectDay == '日' || this.selectAvailable.holiday_flg == 2) {
					//       console.log('シャトルバスが出ます');
					//     }else {
					//       for(var a = 0; a < this.comeWay.length; a++) {
					//         if(this.comeWay[a].come_way_name.indexOf('シャトルバス') != -1) {
					//           this.comeWay.splice(a, 1);
					//         }
					//       }
					//     }
					//   }
					// }

					this.selectCourseInfo = this.courseInfo.filter((array) => {
						return array["course_id"] == this.selectEvent.rsv_course_id;
					});

					// ローカルストレージに値をセット
					localStorage.setItem('prefData', JSON.stringify(this.prefData));
					localStorage.setItem('visitNum', JSON.stringify(this.resCourse.vst_come_num));
					localStorage.setItem('selectedEvent', JSON.stringify(this.selectEvent));
					localStorage.setItem('selectCourseInfo', JSON.stringify(this.selectCourseInfo));
					if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
						localStorage.setItem('allergy', this.resData.allergy_flg);
					}
					// 予約内容確認ページに移動する
					if(localStorage.getItem('plantId') == 1000) {
						this.$router.push({ path: '/eng/miyagikyo/reservation/reserveconfirm' });
					}else {
						this.$router.push({ path: '/eng/yoichi/reservation/reserveconfirm' });
					}
				});
			}).catch(err => {
				this.error = err.response.data.data;
				$("#errTxt").text("Authentication failed.");
				// モーダルを表示
				this.scrollPos = $(window).scrollTop();
				$("body").addClass("fixed").css({
					top: -this.scrollPos
				});
				
				// 処理が終わったらボタン元に戻す
				$('.checkForm_btn').prop('disabled',false);
				
				$(".errModal").fadeIn();
			});
		},

		modalClose() {
			// モーダル非表示
			$("body").removeClass("fixed").css({
				top: 0
			});
			window.scrollTo(0, this.scrollPos);
			$(".errModal").fadeOut();
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	.num.eng {
		background-color: #251E1C;
		color: #FFF;
		padding-top: 70px;
		.num_ttl {
			font-size: 42px;
			text-align: center;
			font-weight: 400;
			margin-bottom: 48px;
		}
		.num_txt {
			width: 100%;
			max-width: 746px;
			font-size: 17px;
			text-align: left;
			line-height: 1.5 !important;
			margin: 0 auto;
		}
		.num_red {
			font-size: 17px;
			width: 100%;
			max-width: 746px;
			line-height: 1.5 !important;
			margin: 16px auto 32px;
			list-style: none;
			color: #FFF;
		}
	}

	// 768以下
	.picxel .eng .num {
		padding-top: 40px;
		&_ttl {
			font-size: 28px;
			margin-bottom: 40px;
		}
		&_txt {
			font-size: 17px;
			width: calc(100% - 20px);
			margin: 0 auto;
		}
		&_red {
			font-size: 17px;
			margin: 16px auto 57px;
			width: calc(100% - 20px);
			li {
				margin-bottom: 0;
			}
		}
	}

	.eng .checkForm {
		width: 746px;
		margin: 0 auto 16px;
		background-color: transparent;
		padding: 0;
		border-top: 1px solid #595858;
		box-sizing: border-box;
		&_btn {
			position: relative;
			display: block;
			width: 100%;
			max-width: 480px;
			font-size: 18px;
			line-height: 1 !important;
			letter-spacing: 0.07em;
			margin: 48px auto 0;
			padding: 17px 0;
			border: none;
			outline: none;
			color: #fff;
			background-color: #91701e;
			border-radius: 4px;
			cursor: pointer;
			&:disabled {
				background-color: #cccccc;
			}
			&::after {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 10px;
				border-color: transparent transparent transparent #fff;
				top: 50%;
				right: 32px;
				transform: translateY(-50%);
			}
		}
	}

	// 768以下
	.picxel .eng .checkForm {
		width: calc(100% - 20px);
		padding: 0;
		margin-bottom: 16px;
		&_btn {
			font-size: 20px;
			padding: 17px 0;
			margin-top: 32px;
			&:disabled {
				background-color: #cccccc;
			}
		}
	}

	.num.eng .inputBox {
		display: flex;
		align-items: flex-start;
		border-bottom: 1px solid #595858;
		margin-bottom: 0;
		padding: 16px 30px;
		&_ttl {
			width: calc(100% - 442px);
			font-size: 17px;
			color: #898989;
			margin-bottom: 0;
		}
		&_txt {
			font-size: 14px;
			letter-spacing: .05em;
			margin-top: 8px;
			color: #FFF;
		}
		&_input {
			width: 442px;
			font-size: 17px;
			padding: 14px 10px;
			box-sizing: border-box;
			outline: none;
			border-radius: 4px;
			border: 1px solid #898989;
		}
	}

	// 768以下
	.picxel .num.eng .inputBox {
		display: block;
		margin-bottom: 0;
		padding: 16px 10px;
		&_ttl {
			width: 100%;
			font-size: 17px;
			margin-bottom: 8px;
		}
		&_input {
			font-size: 17px;
			transform: scale(0.75);
			width: 133%;
			margin-left: -16%;
			padding: 14px 10px;
			border: 1px solid #DDDDDD;
		}
	}

	.num.eng .halfRight {
		width: 442px;
	}

	.picxel .num.eng .halfRight {
		width: 100%;
	}

	// .checkErr {
	// 	font-size: 16px;
	// 	text-align: left;
	// 	font-weight: bold;
	// 	margin-top: 8px;
	// 	letter-spacing: 0.05em;
	// 	color: #df0202;
	// }

	// // 768以下
	// .picxel .checkErr {
	// 	font-size: 28px;
	// 	margin-top: 16px;
	// }

	.goToLink {
		display: block;
		text-decoration: none;
		font-size: 20px;
		width: 100%;
		max-width: 480px;
		text-align: center;
		line-height: 1;
		margin: 0 auto 80px;
		background-color: #898989;
		color: #fff;
		padding: 17px 0;
		border-radius: 4px;
		cursor: pointer;
	}

	// 768以下
	.picxel .goToLink {
		font-size: 20px;
		width: calc(100% - 20px);
		margin-bottom: 64px;
		padding: 17px 0;
	}

	.pc {
		display: block;
	}

	// 768以下
	.picxel .pc {
		display: none;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}

	// エラーモーダル
	.errModal {
		// 初期非表示
		display: none;
		&_shadow {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba($color: #000, $alpha: 0.8);
			cursor: pointer;
			z-index: 99;
		}
	}

	.eng .errCont {
		position: fixed;
		width: 640px;
		padding: 56px 70px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-sizing: border-box;
		background-color: #251E1C;
		z-index: 999;
		&_img {
			width: 50px;
			margin: 0 auto 10px;
			img {
				display: block;
				width: 100%;
			}
		}
		&_ttl {
			font-size: 36px;
			font-weight: 400;
			line-height: 1.5 !important;
			text-align: center;
			color: #FFF;
			margin-bottom: 16px;
		}
		&_txt {
			font-size: 17px;
			line-height: 1.5 !important;
			text-align: center;
			color: #FFF;
		}
		&_close {
			display: block;
			width: 300px;
			font-size: 20px;
			color: #FFF;
			padding: 17px 0;
			border: none;
			text-align: center;
			margin: 32px auto 0;
			background: #898989;
			outline: none;
			cursor: pointer;
		}
	}

	// 768以下
	.picxel .eng .errCont {
		width: calc(100% - 20px);
		padding: 32px 16px;
		&_ttl {
			font-size: 36px;
			margin-bottom: 16px;
		}
		&_txt {
			font-size: 17px;
			width: 100%;
		}
		&_close {
			width: 100%;
			max-width: 300px;
			font-size: 20px;
			padding: 17px 0;
			margin-top: 24px;
			border: none;
		}
	}

	.eng #errTxt {
		width: 500px;
		margin: 0 auto;
	}

	// 768以下
	.picxel .eng #errTxt {
		width: 100%;
		margin: 0;
	}
</style>
