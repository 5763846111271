<template>
  <div class="reservation" :class="{ 'is-maintenance' : maintenance_flag, 'miyagi': plantId == 1000, 'yoichi': plantId == 1001}">
    <template v-if="maintenance_flag === true">
      <div class="maintenance">
        <h2 class="maintenance_ttl">ただいま<br class="sp" />メンテナンス中です</h2>
        <p class="maintenance_txt">メンテナンス中のため一時サービスを停止しております。<br />大変ご不便をおかけいたしますが、今しばらくお待ちください。</p>
      </div>
    </template>

    <template v-if="maintenance_flag === false">
      <MainVisual />
      <section class="formContent topContent">
        <h2 class="formContent_ttl topContent_ttl mincho">蒸溜所見学について</h2>
        <div v-show="topAnnotation != undefined && topAnnotation != 'undefined'">
          <p class="formContent_eme" v-for="annotation in topAnnotation" :key="annotation" v-html="annotation"></p>
        </div>
        <ul class="factoryContents">
          <li class="factoryContent" v-for="topInfo in topTxts" :key="topInfo.title">
            <p class="factoryContent_ttl">{{ topInfo.title }}</p>
            <ul>
              <li class="factoryList" v-for="inseption in topInfo.inseption_info" :key="inseption.name">
                <p class="factoryList_ttl">{{ inseption.name }}</p>
                <div class="factoryList_cont">
                  <p v-for="content in inseption.content" :key="content" v-html="content"></p>
                </div>
              </li>
            </ul>
            <ul class="factoryRice">
              <li class="factoryRice_li factoryRice_rice" v-for="rice in topInfo.rice" :key="rice" v-html="rice"></li>
              <li class="factoryRice_li factoryRice_rice riceRed" v-for="red in topInfo.red_rice" :key="red" v-html="red"></li>
            </ul>
          </li>
        </ul>
        <!-- <section class="factoryContent">
          <h3 class="factoryContent_ttl">{{ topTxts.name }}</h3>
          <ul>
            <li class="factoryList" v-show="topTxts.sale">
              <p class="factoryList_ttl">開催時間</p>
              <div class="factoryList_cont">
                <p v-for="sale in topTxts.sale" :key="sale" v-html="sale"></p>
              </div>
            </li>
            <li class="factoryList">
              <p class="factoryList_ttl">所要時間</p>
              <div class="factoryList_cont">
                <p v-for="time in topTxts.time" :key="time" v-html="time"></p>
              </div>
            </li>
            <li class="factoryList">
              <p class="factoryList_ttl">参加費</p>
              <p class="factoryList_cont" v-html="topTxts.fee"></p>
            </li>
            <li class="factoryList">
              <p class="factoryList_ttl">条件</p>
              <div class="factoryList_cont">
                <p v-for="condition in topTxts.conditions" :key="condition" v-html="condition"></p>
              </div>
            </li>
          </ul>
          <ul class="factoryRice">
            <li class="factoryRice_li factoryRice_rice" v-for="rice in topTxts.rice" :key="rice" v-html="rice"></li>
            <li class="factoryRice_li factoryRice_rice riceRed" v-show="topTxts.red_rice" v-for="red in topTxts.red_rice" :key="red" v-html="red"></li>
          </ul>
        </section> -->
      </section>

      <!-- 蒸留所からの最新情報 -->
      <section class="twContent">
        <a href="https://x.com/nikka_miyagikyo" target="_blank" class="twContent_link" v-show="plantId == 1000">公式Xもフォローお願いします</a>
        <a href="https://x.com/nikka_yoichi" target="_blank" class="twContent_link" v-show="plantId == 1001">公式Xもフォローお願いします</a>
      </section>

      <section class="formContent">
        <h3 class="formContent_ttl mincho">見学予約の流れ</h3>
        <Step class="oneStep" />
        <section class="dateSelect courseOne">
          <section class="formNum courseOne">
            <h4 class="formNum_ttl">1.日付の選択</h4>
            <p class="formNum_txt">
              現時点でご予約可能な時間帯がある日です。ご希望の日を選択してください。
            </p>
            <ul class="carefulRice">
              <li class="carefulRice_li" v-for="cal in topCalendar.red_rice" :key="cal" v-html="cal"></li>
            </ul>
            <ul class="exFlex">
              <li class="exBox">
                <div class="exBox_color boxFree"></div>
                <p class="exBox_txt">受付中</p>
              </li>
              <li class="exBox">
                <div class="exBox_color boxMax"></div>
                <p class="exBox_txt">満席</p>
              </li>
              <li class="exBox">
                <div class="exBox_color boxOut"></div>
                <p class="exBox_txt">受付期間外</p>
              </li>
              <li class="exBox">
                <div class="exBox_color boxVacation"></div>
                <p class="exBox_txt">休館日</p>
              </li>
            </ul>
            <div id="calendarBox">
              <div id="calendar"></div>
            </div>
          </section>
          <section class="formNum" v-show="selectYear == ''">
            <h4 class="formNum_ttl">2.コース選択</h4>
            <p class="formNum_txt">
              日付を選択してください
            </p>
          </section>
          <section class="formNum courseTwo">
            <h4 class="formNum_ttl">2.コース選択</h4>
            <p class="formNum_txt" v-show="mygflg == true">
              残り人数は目安です。<br />
              他のお客様のお申し込み人数により変化いたしますのでご注意下さい。<br />
              ご不明な点がございましたらお電話にてお問い合わせ願います。
            </p>
            <p class="formNum_txt" v-show="mygflg == false">
              残り人数は目安です。<br />
              他のお客様のお申し込み人数により変化いたしますのでご注意下さい。<br />
              ご不明な点がございましたらお電話にてご連絡下さい。
            </p>
            <div class="twoTtl">
              <p class="twoTtl_date">{{ selectYear }}年{{ selectMonth }}月{{ selectDate }}日 ({{ selectDay }})</p>
              <p class="twoTtl_one" @click="stepOne()">日付を再選択する</p>
            </div>
            <div class="scaduleBox">
              <div class="cellBox">
                <div class="cellBox_one"></div>
                <div class="cellBox_two cellBox_free">蒸溜所見学</div>
                <div class="cellBox_three cellBox_paid">セミナー・イベント</div>
              </div>
              <div class="cellBox" v-for="dateItem in targetItem" :key="dateItem.start_time">
                <div class="cellBox_one">{{ retakeDate(dateItem.start_time) }}</div>
                <div class="cellBox_two cellBox_infree">
                  <div v-if="dateItem.rsv_course.length && dateItem.web_disp_flg == 1">
                    <div v-for="courseItem in dateItem.rsv_course" :key="courseItem.rsv_course_id">
                      <button
                        type="button"
                        class="selBtn freeBtn"
                        :class="{ selected: selectedId + selectedTime === courseItem.rsv_course_id + dateItem.start_time }"
                        v-if="courseItem.rsv_course_type != 3 && courseItem.rsv_course_type != 4 && courseItem.rsv_course_type != 8 && courseItem.rsv_course_type != 9 && courseItem.rsv_remaining_num > 0"
                        :name="courseItem.rsv_course_id + dateItem.start_time"
                        @click="getTxt(courseItem.rsv_course_id, dateItem.start_time)"
                      >
                        {{ trim(courseItem.rsv_course_name) }}<br />
                        残り{{ courseItem.rsv_remaining_num }}人
                      </button>

                      <button type="button" class="maxBtn" v-if="courseItem.rsv_course_type != 3 && courseItem.rsv_course_type != 4 && courseItem.rsv_course_type != 8 && courseItem.rsv_course_type != 9 && courseItem.rsv_remaining_num == 0">
                        {{ trim(courseItem.rsv_course_name) }}<br />
                        満席
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      <button type="button" class="noneBtn">-</button>
                    </div>
                  </div>
                </div>
                <div class="cellBox_three cellBox_inpaid">
                  <div v-if="dateItem.rsv_course.length && dateItem.web_disp_flg == 1">
                    <div v-for="courseItem in dateItem.rsv_course" :key="courseItem.rsv_course_id">
                      <button
                        type="button"
                        class="selBtn paidBtn"
                        :class="{ selected: selectedId + selectedTime === courseItem.rsv_course_id + dateItem.start_time }"
                        v-if="
                          (courseItem.rsv_course_type == 3 && courseItem.rsv_remaining_num > 0) ||
                          (courseItem.rsv_course_type == 4 && courseItem.rsv_remaining_num > 0) ||
                          (courseItem.rsv_course_type == 8 && courseItem.rsv_remaining_num > 0) ||
                          (courseItem.rsv_course_type == 9 && courseItem.rsv_remaining_num > 0)
                        "
                        :name="courseItem.rsv_course_id + dateItem.start_time"
                        @click="getTxt(courseItem.rsv_course_id, dateItem.start_time)"
                      >
                        {{ trim(courseItem.rsv_course_name) }}<br />
                        残り{{ courseItem.rsv_remaining_num }}人
                      </button>

                      <button
                        type="button"
                        class="maxBtn"
                        v-if="
                          (courseItem.rsv_course_type == 3 && courseItem.rsv_remaining_num == 0) ||
                          (courseItem.rsv_course_type == 4 && courseItem.rsv_remaining_num == 0) ||
                          (courseItem.rsv_course_type == 8 && courseItem.rsv_remaining_num == 0) ||
                          (courseItem.rsv_course_type == 9 && courseItem.rsv_remaining_num == 0)
                        "
                      >
                        {{ trim(courseItem.rsv_course_name) }}<br />
                        満席
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      <button type="button" class="noneBtn">-</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="calendarCareful">最終ツアーについては、閉館時間の関係上ツアー後のお時間が短くなっております。</p>
          </section>
        </section>
      </section>
      <Toptel />
    </template>
  </div>
</template>

<script>
import MainVisual from "./MainVisual";
import Step from "./TopStep";
import Toptel from "./TopTel";
import moment from "moment";
import "jquery-ui";
import axios from "axios";
require("../../node_modules/jquery-ui/ui/i18n/datepicker-ja.js");
require("@/assets/css/jquery-ui.css");
require("@/assets/css/calendar.scss");

export default {
  name: "RservationTop",
  components: {
    MainVisual,
    Step,
    Toptel
  },
  data() {
    return {
      windowSize: 0,
      url: "/api/reserveSlot/list",
      plantId: 0,
      mygflg: false,
      plantName: null,
      topTxts: [],
      topAnnotation: [],
      topCalendar: [],
      maintenance_flag: false,
      dateParam: undefined,
      changeParam: undefined,
      thisDate: moment(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).format("YYYY-MM"),
      resData: "",
      date: new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }),
      year: new Date(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getFullYear(),
      month: new Date(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getMonth(),
      lastDayOfFourthMonth: new Date(new Date(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getFullYear(), new Date(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getMonth() + 2, 0),
      numberOfMonths: "",
      availableDates: "",
      availableDatesList: [],
      restDateList: [],
      maxDateList: [],
      $calendar: window.$(".jp #calendar"),
      prevBtn: document.querySelector(".ui-datepicker-prev"),
      nextBtn: document.querySelector(".ui-datepicker-next"),
      selectedDate: "",
      selectYear: "",
      selectMonth: "",
      selectDate: "",
      selectDay: "",
      nowHours: new Date(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getHours(),
      nowMinutes: new Date(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getMinutes(),
      dayOfWeekStr: ["日", "月", "火", "水", "木", "金", "土"],
      targetDate: "",
      targetItem: "",
      modalItem: [],
      selectId: "",
      selectTime: "",
      selectEvent: null,
      selectedId: null,
      selectedTime: null,
      scrollPos: "",
      selectAvailable: null,
      winter: null,
      prefData: null,
      courseInfo: null,
      selectCourseInfo: null,
      comeWay: null,
      distance: 0,
      scrollStop: window.$(".twoTtl"),
      maxTxt: 0,
    };
  },
  head: {
    title: function() {
      return {
        inner: this.plantName,
        separator: "|",
        complement: "予約TOP",
      };
    },
    link: [
      { rel: "stylesheet", href: "https://www.nikka.com/common/css/reset.css" },
      { rel: "stylesheet", href: "https://www.nikka.com/common/css/style.css" },
    ],
    script: [{ type: "text/javascript", src: "/include/include.js" }],
  },
  mounted() {
    // 一番上まで移動
    window.$("body, html").scrollTop(0);

    // 余市か宮城峡か判定
    if (location.pathname.indexOf("miyagikyo") != -1) {
      this.plantId = 1000;
      this.plantName = "宮城峡蒸溜所";
      this.mygflg = true;
      window.$(".reservation").addClass("miyagi");
    } else {
      this.plantId = 1001;
      this.plantName = "余市蒸溜所";
      this.mygflg = false;
      window.$(".reservation").addClass("yoichi");
    }

    // 画面幅取得
    this.windowSize = window.$(window).width();

    localStorage.setItem("plantId", this.plantId);
    this.$emit("updateHead");

    axios.get("/files/" + this.plantId + "_top.json").then((res) => {
      this.topAnnotation = res.data.data.annotation;
      this.topTxts = res.data.data.course_info;
      this.topCalendar = res.data.data.calendar_top[0];
    });

    // メンテナンス時の表示切替用フラグをjsonから取得
    axios.get("/files/maintenance.json").then((res) => {
      this.maintenance_flag = res.data.data.maintenance_flag;
    });


    // dateパラメーラがあるかどうか入れてみる
    this.dateParam = this.$route.query.date;
    this.changeParam = this.$route.query.change;

    this.resize();
    this.createCalendar();

    // リサイズの処理
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.resize);
  },
  methods: {
    setBox() {
      window.$(function() {
        const $tweetBack = window.$(".getTweet_back");
        const setWidth = $tweetBack.width();
        if (window.$("body").hasClass("picxel")) {
          $tweetBack.css("height", setWidth * 0.75);
        }
      });

      return "";
    },

    setImg() {
      window.$(function() {
        window.$(".getTweet_img").each((_, elm) => {
          const $mainImg = window.$(elm);
          let imgWidth = $mainImg.width();
          let imgHeight = $mainImg.height();
          if (imgWidth / imgHeight <= 1.3333) {
            $mainImg.css("height", "100%");
          } else {
            $mainImg.css("width", "100%");
          }
        });
      });

      return "";
    },

    resize() {
      var Pixcel = window.$("#app").width();
      if (Pixcel <= 768) {
        // スマホ
        this.numberOfMonths = 1;
      } else {
        // PC
        this.numberOfMonths = 2;
      }
    },

    update(data) {
      for (var i = 0; i < data.length; i++) {
        console.log(data[i].user.name + " : " + data[i].text);
      }
    },

    // カレンダーの追加
    createCalendar() {
      axios({
        method: "post",
        url: this.url,
        headers: {
          "X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7",
        },
        data: {
          plant_id: this.plantId,
          display_start_date: moment(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).format("YYYY-MM"),
        },
      })
        .then((res) => {
          this.resData = res;
          this.availableDates = res.data.data.date_info;
          this.availableDates.forEach((item) => {
            let flg = 0;
            let max = 0;
            for (let i = 0; i < item.rsv_slot.length; i++) {
              if (item.rsv_slot[i].web_disp_flg == 1 && item.rsv_slot[i].rsv_course.length != 0) {
                flg++;
              }
              for (let m = 0; m < item.rsv_slot[i].rsv_course.length; m++) {
                if (item.rsv_slot[i].rsv_course[m].rsv_remaining_num > 0) {
                  max++;
                }
              }
            }

            if (item.rest_flg == 1 && flg != 0 && max != 0) {
              this.availableDatesList.push(item);
            } else if (item.rest_flg == 1 && flg != 0 && max == 0) {
              this.maxDateList.push(item);
            } else if (item.rest_flg == 2) {
              this.restDateList.push(item);
            }
          });
        })
        .then(() => {
          var that = this;
          function available(date) {
            var daysFromCalendar = moment(date).format("YYYY-MM-DD");
            // JSON 内の営業日とカレンダー機能内の日を比較する
            var isAvailable = function(item) {
              return item.date == daysFromCalendar;
            };
            // 祝日かどうかを確認する
            var holiday = that.availableDatesList.filter(function(item) {
              return item.holiday_flg == 2 && isAvailable(item);
            });
            // 祝日の営業日なら holiday クラスを付ける
            if (holiday && holiday.length) return [true, "holiday", "Available"];

            // 予約一杯の日を取得する
            var maxday = that.maxDateList.filter(function(item) {
              return isAvailable(item);
            });
            if (maxday && maxday.length) return [false, "maxday", "unAvailable"];

            // 休館日を取得
            var restday = that.restDateList.filter(function(item) {
              return isAvailable(item);
            });
            if (restday && restday.length) return [false, "restday", "unAvailable"];

            return that.availableDatesList.filter(function(item) {
              return isAvailable(item);
            }).length
              ? // 営業日
                [true, "", "Available"]
              : // 休館日
                [false, "", "unAvailable"];
          }

          // 時間によって日付選択ができる範囲を変更（12時以降なら次の日も選択できないようにする）
          // 言語を日本語に設定
          window.$.datepicker.setDefaults(window.$.datepicker.regional["ja"]);
          if (this.nowHours < 12) {
            // カレンダー機能
            window
              .$(".jp #calendar")
              .datepicker({
                // 一番左の日を 月曜日にする
                firstDay: 0,
                // 画面サイズによって表示する月数を指定する
                numberOfMonths: this.numberOfMonths,
                dateFormat: "yy-mm-dd",
                // スマホでも最大 4ヵ月先までにしか行けないようにする
                minDate: "+1d",
                maxDate: this.lastDayOfFourthMonth,
                // 上記の available 変数を使って カレンダー作成する前に 表示するべき日を指定する
                beforeShowDay: available,
                // 日付が選択されたら選択された日を 送信する (別ウインドウを開く)
                onSelect: function(date) {
                  if (localStorage.getItem("selectedEvent") != null) {
                    localStorage.removeItem("selectedEvent");
                    // イベント情報の非表示
                    window.$("#selectedEvent").hide();
                  }
                  that.stepTwo(date);
                },
              })
              .datepicker("setDate", new Date());
          } else {
            // カレンダー機能
            window
              .$(".jp #calendar")
              .datepicker({
                // 一番左の日を 月曜日にする
                firstDay: 0,
                // 画面サイズによって表示する月数を指定する
                numberOfMonths: this.numberOfMonths,
                dateFormat: "yy-mm-dd",
                // スマホでも最大 4ヵ月先までにしか行けないようにする
                minDate: "+2d",
                maxDate: this.lastDayOfFourthMonth,
                // 上記の available 変数を使って カレンダー作成する前に 表示するべき日を指定する
                beforeShowDay: available,
                // 日付が選択されたら選択された日を 送信する (別ウインドウを開く)
                onSelect: function(date) {
                  if (localStorage.getItem("selectedEvent") != null) {
                    localStorage.removeItem("selectedEvent");
                    // イベント情報の非表示
                    window.$("#selectedEvent").hide();
                  }
                  that.stepTwo(date);
                },
              })
              .datepicker("setDate", new Date());
          }

          // ロード時に選択状態につくクラスがついている場合外してあげる
          window.$("td").removeClass("ui-datepicker-current-day");
          window.$("a").removeClass("ui-state-active");

          // 蒸留所TOPのカレンダーからこのページに来たらステップ2に行く
          if (this.dateParam != undefined && this.changeParam == undefined) {
            window.$(".jp #calendar").datepicker("setDate", this.dateParam);
            this.stepTwo(this.dateParam);
          } else if (this.dateParam != undefined && this.changeParam != undefined) {
            window.$(".jp #calendar").datepicker("setDate", this.dateParam);
            this.stepOne();
          }
        });
    },

    stepTwo(date) {
      window.$(".courseTwo").show();
      var dd = new Date(new Date(date).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }));
      const position = window.$(".courseTwo").offset().top;
      this.selectedDate = moment(dd).format("YYYY-MM-DD");
      this.selectYear = dd.getUTCFullYear();
      this.selectMonth = dd.getUTCMonth() + 1;
      this.selectDate = dd.getUTCDate();
      this.selectDay = this.dayOfWeekStr[dd.getDay()];
      // 日付が一致するものをAPIから探す
      this.targetDate = this.availableDates.find((v) => v.date === date);

      // 15時半以降出ないようにする
      this.targetItem = this.targetDate.rsv_slot.filter((result) => {
        return Number(result["start_time"].replace(/^0+/, "")) < 1531;
      });

      if (localStorage.getItem("selectedEvent") != null) {
        this.selectedId = JSON.parse(localStorage.getItem("selectedEvent")).rsv_course_id;
        this.selectedTime = localStorage.getItem("selectedTime");
        this.selectTime = this.retakeDate(this.selectedTime);
        window.$(".selBtn").removeClass("selected");

        this.selectedDate = localStorage.getItem("selectedDate");
        for (var i = 0; i < this.targetDate.rsv_slot.length; i++) {
          if (this.targetDate.rsv_slot[i].start_time == this.selectedTime) {
            for (var j = 0; j < this.targetDate.rsv_slot[i].rsv_course.length; j++) {
              if (this.targetDate.rsv_slot[i].rsv_course[j].rsv_course_id == this.selectedId) {
                this.selectEvent = this.targetDate.rsv_slot[i].rsv_course[j];
              }
            }
          }
        }

        // 住所まとめたもの
        this.prefData = this.resData.data.data.departure;
        // API側の対応で必要なくなった
        // 余市も宮城峡も海外は削除
        // this.prefData = this.prefData.filter((pref) => {
        // 	return pref["overseas_flg"] == 2;
        // });

        // 移動手段をまとめたもの
        this.courseInfo = this.resData.data.data.course_info;
        this.comeWay = this.resData.data.data.come_way;

        // コースの設定の移動手段に絞る
        for (var c = 0; c < this.courseInfo.length; c++) {
          if (this.courseInfo[c].course_id == this.selectId) {
            if (this.courseInfo[c].condition_transportation != null) {
              this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(",");
              for (var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
                for (var m = 0; m < this.comeWay.length; m++) {
                  if (this.comeWay[m].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
                    this.comeWay.splice(m, 1);
                  }
                }
              }
            }
          }
        }

        // イベント情報の表示
        window.$("#selectedEvent").show();
      }
      if (this.numberOfMonths == 1) {
        // 日付部分までスクロールする
        window.$("html, body").animate(
          {
            scrollTop: position - 220,
          },
          500
        );
      } else {
        // 日付部分までスクロールする
        window.$("html, body").animate(
          {
            scrollTop: position - 250,
          },
          500
        );
      }
    },

    stepOne() {
      const position = window.$(".courseOne").offset().top;
      const speed = 500;
      window.$("html, body").animate(
        {
          scrollTop: position,
        },
        speed
      );
    },

    takeMovie(medias) {
      const selectMovie = medias.filter((media) => {
        return media["content_type"] == "video/mp4";
      });

      return selectMovie[0]["url"];
    },

    retakeText(text, url) {
      const br = text.replace("\n", "<br>");
      if (url != "") {
        return br.replace(url, "");
      }
      return br;
    },

    tweetDate(date) {
      let plastic = new Date(new Date(date).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }));
      let year = plastic.getFullYear();
      let month = plastic.getMonth() + 1;
      let day = plastic.getDate();
      let stickDate = `${year}.${month}.${day}`;

      return stickDate;
    },

    retakeDate(date) {
      var slice = Number(date);
      var toString = String(slice);
      var slicer = ":" + toString.slice(-2) + "～";
      var result = toString.slice(0, -2) + slicer;
      return result;
    },

    // ボタンの文字の制限をかける２１文字以上だったら…を後ろに
    trim(txt) {
      let noTag = window
        .$("<div>")
        .html(txt)
        .text();
      // デバイスサイズによって限界文字サイズを変更
      if (this.windowSize > 768) {
        this.maxTxt = 21;
      } else {
        this.maxTxt = 5;
      }

      // 文字のトリミング
      var txtLength = noTag.length;
      var txtTrim = noTag.substr(0, this.maxTxt);
      if (txtLength > this.maxTxt) {
        return txtTrim + "…";
      } else {
        return noTag;
      }
    },

    getTxt(getId, time) {
      this.selectId = getId;
      this.defaultTime = time;

      window.$(this).removeClass("selected");

      for (var i = 0; i < this.targetDate.rsv_slot.length; i++) {
        if (this.targetDate.rsv_slot[i].start_time == this.defaultTime) {
          for (var j = 0; j < this.targetDate.rsv_slot[i].rsv_course.length; j++) {
            if (this.targetDate.rsv_slot[i].rsv_course[j].rsv_course_id == this.selectId) {
              this.selectEvent = this.targetDate.rsv_slot[i].rsv_course[j];
            }
          }
        }
      }

      // 住所まとめたもの
      this.prefData = this.resData.data.data.departure;
      // API側の対応で必要なくなった
      // 海外除外処理を入れる
      // this.prefData = this.prefData.filter((pref) => {
      // 	return pref["overseas_flg"] == 2;
      // });

      // 移動手段をまとめたもの
      this.courseInfo = this.resData.data.data.course_info;
      this.comeWay = this.resData.data.data.come_way;

      // コースの設定の移動手段に絞る
      for (var c = 0; c < this.courseInfo.length; c++) {
        if (this.courseInfo[c].course_id == this.selectId) {
          if (this.courseInfo[c].condition_transportation != null) {
            this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(",");
            for (var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
              for (var m = 0; m < this.comeWay.length; m++) {
                if (this.comeWay[m].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
                  this.comeWay.splice(m, 1);
                }
              }
            }
          }
        }
      }
      // シャトルバス対応
      if (this.mygflg) {
        var flg = 0;
        var openDay = this.resData.data.data.plant_info.pickup_bus_operating_day;
        for (var s = 0; s < openDay.length; s++) {
          if (openDay[s] == this.targetDate.date) {
            flg++;
          }
        }
        // jsonデータ内に日付がなかったらシャトルバス除外する
        if (flg == 0) {
          this.comeWay = this.comeWay.filter((come) => {
            return !/送迎バス（シャトルバス）/.test(come["come_way_name"]);
          });
        }
        // 移動手段絞る
        localStorage.setItem("comeWay", JSON.stringify(this.comeWay));
      } else {
        // 余市はそのまま
        localStorage.setItem("comeWay", JSON.stringify(this.comeWay));
      }

      this.selectCourseInfo = this.courseInfo.filter((array) => {
        return array["course_id"] == this.selectEvent["rsv_course_id"];
      });

      localStorage.setItem("courseInfo", JSON.stringify(this.courseInfo));
      localStorage.setItem("prefData", JSON.stringify(this.prefData));
      localStorage.setItem("visitNum", JSON.stringify(this.resData.data.data.vst_come_num));
      localStorage.setItem("selectedDate", this.selectedDate);
      localStorage.setItem("selectedTime", this.defaultTime);
      localStorage.setItem("selectedEvent", JSON.stringify(this.selectEvent));
      localStorage.setItem("selectCourseInfo", JSON.stringify(this.selectCourseInfo));

      if (this.mygflg == true) {
        this.$router.push({ path: "/miyagikyo/reservation/select_event", query: { event_id: this.selectId, time: this.defaultTime } });
      } else {
        this.$router.push({ path: "/yoichi/reservation/select_event", query: { event_id: this.selectId, time: this.defaultTime } });
      }
      return false;
    },

    closeModal() {
      window
        .$("body")
        .removeClass("fixed")
        .css({
          top: 0,
        });
      window.scrollTo(0, this.scrollPos);
      window.$(".modal").fadeOut();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reservation.is-maintenance {
  background-color: transparent!important;
  color: #000;
}
.maintenance {
  padding: 80px 0;
  &_ttl {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 0.05em;
    line-height: 1.5 !important;
  }
  &_txt {
    font-size: 18px;
    line-height: 2 !important;
    margin-bottom: 15px;
    text-align: center;
    letter-spacing: 0.05em;
  }
  &_end {
    width: 455px;
    box-sizing: border-box;
    font-size: 20px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #df1016;
    border: 1px solid #df1016;
    border-radius: 6px;
    padding: 10px 0;
    margin: 0 auto 30px;
  }
  &_last {
    font-size: 18px;
    line-height: 2 !important;
    text-align: center;
    margin-bottom: 80px;
  }
}

// 768以下
.picxel .maintenance {
  padding-top: 40px;
  &_ttl {
    width: calc(100% - 50px);
    font-size: 26px;
    margin: 0 auto 30px;
  }
  &_txt {
    width: calc(100% - 50px);
    margin: 0 auto 15px;
    font-size: 14px;
    text-align: left;
  }
  &_end {
    width: calc(100% - 50px);
    margin: 0 auto 15px;
    font-size: 16px;
    line-height: 1.8 !important;
    padding: 5px 20px;
  }
  &_last {
    width: calc(100% - 50px);
    margin: 0 auto 50px;
    font-size: 14px;
    text-align: left;
  }
}
</style>

<style lang="scss">
.reservation.miyagi {
  background-color: #5a9275;
  color: #fff;
}

.reservation.yoichi {
  background-color: #ba543d;
  color: #fff;
}

.infoLink {
  &_link {
    position: relative;
    display: block;
    width: 260px;
    margin: 30px auto 0;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

// Twitterタイムライン
.twContent {
  padding: 0;
  width: 100%;
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin: 20px auto 0;
    padding: 15px 19px 15px 17px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    box-sizing: border-box;
    transition: opacity 0.2s ease;
    &:hover {
      // background-color: rgba(29,155,240,0.3)
      opacity: 0.7;
    }
    &::before {
      content: "";
      width: 20px;
      height: 19px;
      background-image: url(../assets/img/x-logo-black.png);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 8px;
    }
  }
}

// スマホ
.picxel .twContent {
  &_link {
    width: calc(100% - 50px);
    max-width: 300px;
    font-size: 16px;
    margin-top: 20px;
    padding: 15px 14px 15px 15px;
    border: 1px solid #000;
    border-radius: 6px;
    &::before {
      content: "";
      width: 20px;
      height: 19px;
      margin-right: 9.3px;
      background-size: contain;
    }
  }
}

.twWhite {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 53px 110px 60px;
  background-color: #52836c;
  box-sizing: border-box;
  &_ttl {
    font-size: 26px;
    text-align: center;
    font-weight: 100;
    margin-bottom: 35px;
  }
}

.miyagi .twWhite {
  background-color: #52836c;
}

.yoichi .twWhite {
  background-color: #ae4331;
}

// スマホ
.picxel .twWhite {
  max-width: 100%;
  padding: 35px 0 70px 25px;
  &_ttl {
    font-size: 22px;
    margin-bottom: 17px;
  }
}

// ツイッターの中身コンテンツ
// .twArea {
// 	width: 100%;
// 	max-height: 600px;
// 	overflow-y: auto;
// }

// // スマホ
// .picxel .twArea {
// 	width: 100%;
// 	height: auto;
// 	max-height: 10000px;
// 	overflow-x: auto;
// }

// // スケルトンスクリーン
// .loadingLists {
// 	padding: 0 22.5px;
// }

// // スケルトンスクリーン
// .picxel .loadingLists {
// 	width: calc(300% - 260px);
// 	display: flex;
// 	align-items: flex-start;
// 	margin: 0 auto;
// 	padding: 0;
// }

// .loadingList {
// 	display: flex;
// 	flex-wrap: wrap;
// 	align-items: flex-start;
// 	justify-content: space-between;
// 	margin-bottom: 30px;
// 	padding: 0 30px;
// 	&_img {
// 		width: 170px;
// 		height: 128px;
// 		background-color: #eee;
// 	}
// 	&_content {
// 		width: calc(100% - 190px);
// 	}
// 	&_date {
// 		width: 100%;
// 		height: 30px;
// 		margin-bottom: 6px;
// 		background-color: #eee;
// 	}
// 	&_span {
// 		display: block;
// 		width: 100%;
// 		height: 15px;
// 		margin-bottom: 10px;
// 		background-color: #eee;
// 	}
// }

// .picxel .loadingList {
// 	display: inline-block;
// 	width: calc(100% - 50px);
// 	margin: 0 20px 20px;
// 	padding: 40px 0;
// 	&_img {
// 		width: 100%;
// 		height: calc((100vw - 190px) * 0.752);
// 		margin-bottom: 30px;
// 	}
// 	&_content {
// 		width: 100%;
// 	}
// 	&_date {
// 		height: 28px;
// 		margin-bottom: 15px;
// 	}
// 	&_text {
// 		height: 26px;
// 	}
// }

// .loading {
// 	position: relative;
// 	overflow: hidden;
// 	&::before {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		content: "";
// 		display: block;
// 		height: 100%;
// 		width: 100%;
// 		background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
// 		animation: skeleton-anim 1.2s linear infinite;
// 	}
// }

// @keyframes skeleton-anim {
// 	0% {
// 		transform: translateX(-100%);
// 	}
// 	100% {
// 		transform: translateX(100%);
// 	}
// }

// .getTweets {
// 	padding: 0 22.5px;
// }

// // スマホ
// .picxel .getTweets {
// 	width: calc(300% - 260px);
// 	display: flex;
// 	align-items: flex-start;
// 	margin: 0 auto;
// 	padding: 0;
// }

// .win .mainFont .getTweet {
// 	display: flex;
// 	flex-wrap: wrap;
// 	align-items: flex-start;
// 	justify-content: space-between;
// 	margin-bottom: 30px;
// 	padding: 0 30px;
// 	&_back {
// 		position: relative;
// 		width: 170px;
// 		height: 128px;
// 		background-color: #F9F7F0;
// 		overflow: hidden;
// 	}
// 	&_img {
// 		display: block;
// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		width: auto;
// 		height: auto;
// 		transform: translate(-50%, -50%);
// 		margin: 0 auto;
// 	}
// 	&_content {
// 		width: calc(100% - 190px);
// 	}
// 	&_pin {
// 		position: relative;
// 		display: inline-block;
// 		color: #fff;
// 		font-size: 11px;
// 		margin-bottom: 6px;
// 		padding: 1px 8px;
// 		border: 1px solid #fff;
// 		box-sizing: border-box;
// 		&::before {
// 			content: "";
// 			position: absolute;
// 			top: 50%;
// 			transform: translateY(-50%);
// 			width: 8px;
// 			height: 10px;
// 			background-image: url(../assets/img/pin.png);
// 			background-size: contain;
// 			background-position: center center;
// 		}
// 		span {
// 			padding-left: 10px;
// 		}
// 	}
// 	&_date {
// 		font-size: 15px;
// 		letter-spacing: 0.1em;
// 		font-weight: bold;
// 		line-height: 2;
// 		color: #fff;
// 		margin-bottom: 9px;
// 	}
// 	&_text {
// 		font-size: 13px;
// 		line-height: 1.75;
// 		color: #fff;
// 	}
// }

// .miyagi .pinnedTweet {
// 	padding: 30px !important;
// 	background-color: #3D7159;
// }

// .yoichi .pinnedTweet {
// 	padding: 30px !important;
// 	background-color: #963424;
// }

// .picxel.win .mainFont .getTweet {
// 	display: inline-block;
// 	width: calc(100% - 50px);
// 	margin: 0 20px 20px;
// 	padding: 40px 0;
// 	&:last-child {
// 		padding-right: 50px;
// 	}
// 	&_back {
// 		width: 100%;
// 		margin-bottom: 30px;
// 	}
// 	&_content {
// 		width: 100%;
// 	}
// 	&_pin {
// 		font-size: 22px;
// 		margin-bottom: 10px;
// 		padding: 2px 16px;
// 		&::before {
// 			width: 16px;
// 			height: 20px;
// 		}
// 		span {
// 			padding-left: 20px;
// 		}
// 	}
// 	&_date {
// 		font-size: 32px;
// 		line-height: 2.14;
// 		margin-bottom: 10px;
// 	}
// 	&_text {
// 		font-size: 26px;
// 	}
// }

// .picxel.win .mainFont .pinnedTweet {
// 	padding: 40px 30px !important;
// }

// 予約の流れコンテンツ
.formContent {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0 60px;
  &_ttl {
    padding-bottom: 15px;
    font-size: 36px;
    letter-spacing: 0.04em;
    text-align: center;
    font-weight: 100;
  }
  &_careful {
    font-size: 16px;
    text-align: center;
    color: #fff34f;
    margin-bottom: 20px;
  }
  &_eme {
    font-size: 18px;
    text-align: center;
    color: #fff34f;
    margin-bottom: 20px;
  }
}

// 768以下
.picxel .formContent {
  width: calc(100% - 50px);
  padding-top: 41px;
  &_ttl {
    font-size: 26px;
    margin-bottom: 40px;
    padding-bottom: 6px;
  }
  &_careful {
    font-size: 16px;
    margin-bottom: 15px;
  }
  &_eme {
    font-size: 16px;
    text-align: left;
    margin-bottom: 15px;
  }
}

.topContent {
  padding: 73px 0 0 !important;
}

// 768以下
.picxel .topContent {
  padding: 36.5px 0 0 !important;
}

// 一般見学
.factoryContent {
  margin-bottom: 56px;
  &_ttl {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0.08em;
    margin-bottom: 33px;
  }
}

// 768以下
.picxel .factoryContent {
  margin-bottom: 48px;
  &_ttl {
    font-size: 22px;
    margin-bottom: 25px;
  }
}

.factoryUl {
  margin-bottom: 17px;
}

// 768以下
.picxel .factoryUl {
  margin-bottom: 15px;
}

.win .mainFont .factoryList {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background-size: 4px 2px;
    background-image: linear-gradient(to right, #fff, #fff 2px, transparent 2px, transparent 4px);
    background-repeat: repeat-x;
    opacity: 0.16;
  }
  &_ttl {
    position: relative;
    width: 75px;
    font-size: 18px;
    font-weight: bold;
    margin-right: 85px;
    line-height: 2 !important;
  }
  &_cont {
    width: calc(100% - 160px);
    line-height: 2 !important;
    p {
      line-height: 2 !important;
    }
    &_btn {
      position: relative;
      display: inline-block;
      top: -1px;
      font-size: 13px;
      font-weight: bold;
      padding-right: 15px;
      margin-left: 5px;
      color: #fff34f;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #fff34f;
        bottom: -2px;
        left: 0;
        transition: width 0.2s;
      }
      &::after {
        content: "";
        width: 6px;
        height: 6px;
        border: 0px;
        border-top: solid 2px #fff34f;
        border-right: solid 2px #fff34f;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -4px;
      }
      &:hover {
        &::before {
          width: 0;
        }
      }
    }
  }
}

// 768以下
.picxel.win .mainFont .factoryList {
  font-size: 16px;
  margin-bottom: 10px;
  &_ttl {
    width: 100%;
    font-size: 16px;
  }
  &_cont {
    width: 100%;
    &_btn {
      font-size: 16px;
      &::after {
        width: 6px;
        height: 6px;
      }
    }
  }
}

.win .mainFont .factoryRice {
  &_li {
    font-size: 13px;
    line-height: 2 !important;
    color: #fff34f;
    span {
      color: #fff34f !important;
    }
  }
  &_rice {
    position: relative;
    padding-left: 15px;
    &::before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// 768以下
.picxel.win .mainFont .factoryRice {
  &_li {
    font-size: 13px;
  }
  &_rice {
    padding-left: 15px;
  }
}

.riceRed {
  color: #fff34f;
}

.win .mainFont .formNum {
  margin-top: 60px;
  padding-bottom: 80px;
  &_ttl {
    font-size: 26px;
    font-weight: 100;
    padding-bottom: 18.5px;
    margin-bottom: 23px;
    border-bottom: 1px solid #e8e8e8;
  }
  &_txt {
    font-size: 18px;
    line-height: 2 !important;
  }
}

// 768以下
.picxel.win .mainFont .formNum {
  margin-top: 37px;
  padding-bottom: 60px;
  &_ttl {
    font-size: 22px;
    padding-bottom: 7px;
    margin-bottom: 20px;
  }
  &_txt {
    font-size: 16px;
  }
}

.win .mainFont .carefulRice {
  &_li {
    position: relative;
    padding-left: 15px;
    font-size: 13px;
    line-height: 2 !important;
    color: #fff34f;
    &::before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
    &_txt {
      text-indent: 1em;
      &_span {
        display: block;
        text-indent: 3em;
      }
    }
    &_cont {
      span {
        display: block;
        line-height: 2 !important;
      }
      &_indent {
        text-indent: 2em !important;
      }
    }
  }
}

// 768以下
.picxel.win .mainFont .carefulRice {
  &_li {
    font-size: 13px;
    padding-left: 15px;
  }
}

.exFlex {
  display: flex;
  width: 509px;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-top: 25px;
}

// 768以下
.picxel .exFlex {
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.exBox {
  display: flex;
  align-items: center;
  &_color {
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
  }
  .boxFree {
    background-color: #fff;
  }
  .boxMax {
    background-color: #fed6d6;
  }
  .boxOut {
    background-color: #c3c3c3;
  }
  .boxVacation {
    background-color: #d0dfea;
  }
  &_txt {
    font-size: 18px;
    margin-left: 15px;
    line-height: 1;
  }
}

// 768以下
.picxel .exBox {
  width: 50%;
  margin-bottom: 15px;
  &:nth-child(3) {
    margin-bottom: 0;
  }
  &:nth-child(4) {
    margin-bottom: 0;
  }
  &_color {
    width: 35px;
    height: 35px;
  }
  &_txt {
    font-size: 16px;
    margin-left: 10px;
  }
}

// カレンダー下のテキスト
.win .mainFont .calendarCareful {
  font-size: 18px;
  line-height: 2 !important;
  text-align: left;
  color: #fff34f;
  margin-top: 20px;
}

.picxel.win .mainFont .calendarCareful {
  font-size: 16px;
}

.courseTwo {
  display: none;
}

.twoTtl {
  display: flex;
  align-items: center;
  margin-top: 65px;
  padding: 18px 25px 19px;
  &_date {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.075em;
    margin-right: 12px;
    line-height: 1;
  }
  &_one {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    margin-left: 12px;
    padding-right: 15px;
    cursor: pointer;
    &::after {
      content: "";
      width: 11px;
      height: 11px;
      border-top: solid 2px #f5e1d0;
      border-right: solid 2px #f5e1d0;
      position: absolute;
      right: 0;
      top: 8px;
      transform: rotate(45deg);
    }
  }
}

// 768以下
.picxel .twoTtl {
  display: block;
  margin-top: 40px;
  padding: 16px;
  &_date {
    font-size: 18px;
    margin-bottom: 12.5px;
  }
  &_one {
    font-size: 16px;
    width: 130px;
    margin-left: 0;
    padding-right: 15px;
    &::after {
      width: 11px;
      height: 11px;
    }
  }
}

.miyagi .twoTtl {
  background-color: #384d4d;
  &_one {
    color: #cae3c3;
    &::after {
      border-top: solid 2px #cae3c3;
      border-right: solid 2px #cae3c3;
    }
  }
}

.yoichi .twoTtl {
  background-color: #5c0101;
  &_one {
    color: #f8e5d4;
    &::after {
      border-top: solid 2px #f8e5d4;
      border-right: solid 2px #f8e5d4;
    }
  }
}

// スケジュールボックス
.scaduleBox {
  margin-top: 40px;
  border-bottom: 1px solid #eeeeee;
}

// 768以下
.picxel .scaduleBox {
  margin-top: 16px;
}

.cellBox {
  width: 100%;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #eeeeee;
  &_free {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  &_paid {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  &_one {
    font-size: 16px;
    width: calc(100% / 3);
    padding: 15px 0 10px 31px;
    margin-top: 5px;
    line-height: 1;
  }
  &_two {
    width: calc(100% / 3);
    margin-right: 30px;
    line-height: 2.2 !important;
  }
  &_three {
    width: calc(100% / 3);
    margin-right: 30px;
    line-height: 2.2 !important;
  }
}

// 768以下
.picxel .cellBox {
  padding: 4px 0;
  &_free {
    font-size: 11px;
  }
  &_paid {
    font-size: 11px;
  }
  &_one {
    font-size: 12px;
  }
  &_two {
    line-height: 1.5 !important;
    margin-right: 5px;
  }
  &_three {
    line-height: 1.5 !important;
    margin-right: 5px;
  }
}

.miyagi .cellBox {
  &_free {
    color: #fff;
  }
  &_paid {
    color: #9ae8ff;
  }
}

.yoichi .cellBox {
  &_free {
    color: #f8e5d4;
  }
  &_paid {
    color: #ffb469;
  }
}

// ボタン
.noneBtn {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
}

.miyagi .noneBtn {
  color: #5a9275;
  border: 3px solid #5a9275;
  background-color: #5a9275;
}

.yoichi .noneBtn {
  color: #ba543d;
  border: 3px solid #ba543d;
  background-color: #ba543d;
}

// 768以下
.picxel .noneBtn {
  margin: 5px 0;
  padding: 10px 0;
  font-size: 13px;
  border-radius: 4px;
}

// 満席ボタン
.maxBtn {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
  font-size: 12px;
  outline: none;
  color: #c0c0c0;
  border-radius: 4px;
  box-sizing: border-box;
  opacity: 0.3;
}

// 768以下
.picxel .maxBtn {
  margin: 5px 0;
  padding: 10px 0;
  font-size: 12px;
  border-radius: 4px;
}

.miyagi .maxBtn {
  background-color: #384d4d;
  border: 1px solid #cae3c3;
}

.yoichi .maxBtn {
  background-color: #5c0101;
  border: 1px solid #f8e5d4;
}

// 動的に追加された無料見学スケジュールボタン
.freeBtn {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
  font-size: 12px;
  outline: none;
  color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

// 768以下
.picxel .freeBtn {
  margin: 5px 0;
  padding: 10px 0;
  font-size: 12px;
  border-radius: 4px;
}

.miyagi .freeBtn {
  border: 1px solid #cae3c3;
  background-color: #384d4d;
}

.yoichi .freeBtn {
  border: 1px solid #f8e5d4;
  background-color: #5c0101;
}

// 動的に追加された有料イベントスケジュールボタン
.paidBtn {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
  font-size: 12px;
  border: 3px solid #7eafd9;
  outline: none;
  color: #fff;
  background-color: #7eafd9;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

// 768以下
.picxel .paidBtn {
  margin: 5px 0;
  padding: 10px 0;
  font-size: 12px;
  border-radius: 4px;
}

.miyagi .paidBtn {
  border: 1px solid #cae3c3;
  background-color: #384d4d;
}

.yoichi .paidBtn {
  border: 1px solid #f8e5d4;
  background-color: #5c0101;
}

// 選択済みのボタン
.selBtn {
  .selected {
    color: #af9754;
    background-color: #fff;
    border-color: #af9754;
  }
}

#selectedEvent {
  display: none;
}

.selectedList {
  list-style: none;
  display: flex;
  width: 480px;
  margin: 80px auto 0;
  font-size: 20px;
  letter-spacing: 0.15em;
  line-height: 2 !important;
  font-weight: bold;
  &_ttl {
    margin-right: 15px;
  }
  &_name {
    list-style: none;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.15em;
    width: 480px;
    margin: 0 auto;
  }
}

// 768以下
.picxel .selectedList {
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 22px;
  &_ttl {
    margin-right: 0;
  }
  &_name {
    width: 100%;
    font-size: 16px;
  }
}

.nextPage {
  display: block;
  position: relative;
  width: 320px;
  color: #fff;
  background-color: #af9754;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 21px 0;
  margin: 40px auto 20px;
  cursor: pointer;
  &::after {
    content: "";
    width: 7px;
    height: 7px;
    border: 0px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: rotate(45deg) translateY(-50%);
    margin-top: -2px;
  }
}

// 768以下
.picxel .nextPage {
  width: 100%;
  font-size: 16px;
  padding: 16px 0;
  margin: 20px auto 15px;
  &::after {
    width: 7px;
    height: 7px;
  }
}

.pc {
  display: block;
}

// 768以下
.picxel .pc {
  display: none;
}

.sp {
  display: none;
}

// 768以下
.picxel .sp {
  display: block;
}
</style>
