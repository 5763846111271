<template>
	<div class="result eng">
		<Step class="fourStep" />
		<h2 class="result_ttl" v-html="ttlMessage"></h2>
		<div class="resultBox" v-if="reserve_flg == true">
			<p class="resultBox_careful">Please make sure to keep your “Reservation Number” as it is necessary for confirmation, changes, and cancellations.</p>
			<ul>
				<li v-show="mygflg == true && this.reserve_flg == true" class="resultRice">
					*Changes and cancellations regarding the date, time, and number of people via the internet can be made until 12:00 PM(noon) the day before the tour.<br>
					Please make any changes or cancellations through the reservation change/cancellation button on the reservation application page.
				</li>
				<li v-show="mygflg == false && this.reserve_flg == true" class="resultRice">
					*Changes and cancellations regarding the date, time, and number of people via the internet can be made until 12:00 PM(noon) the day before the tour.<br>
					Please make any changes or cancellations through the reservation change/cancellation button on the reservation application page.
				</li>
				<li class="resultList">
					<p class="resultList_ttl">Reservation Number</p>
					<p class="resultList_txt">{{ password }}</p>
				</li>
			</ul>
			<ul class="explanList">
				<li class="explanList_li">*In addition to the reservation details, we have sent a confirmation email to the registered email address with the subject “Confirmation Email”. Please check your email.</li>
				<li class="explanList_li">*If you wish to make changes or cancellations to your reservation, you can do so through the reservation change/cancellation button at the top of the reservation application page.</li>
			</ul>
		</div>
		<p class="result_txt" v-if="reserve_flg == false" v-html="resultTxt"></p>
		<a href="/eng/miyagikyo/reservation" class="goToLink" v-if="reserveErr == false">Return to the top page</a>
		<router-link tag="a" :to="{path: '/eng/miyagikyo/reservation', query: { date: selectedDate }}" class="goToLink" v-if="reserveErr == true && mygflg == true">Return to date and<br class="sp"> time selection.</router-link>
		<router-link tag="a" :to="{path: '/eng/yoichi/reservation', query: { date: selectedDate }}" class="goToLink" v-if="reserveErr == true && mygflg == false">Return to date and<br class="sp"> time selection.</router-link>
		<Tel />
	</div>
</template>

<script>
import $ from 'jquery';
import Tel from './EngTel'

export default {
	name: 'Result',
	components: {
		Tel
	},
	data () {
		return {
			plant_id: localStorage.getItem('plantId'),
			plantName: null,
			complementName: null,
			mygflg: false,
			selectedDate: null,
			errors: JSON.parse(localStorage.getItem('errs')),
			reserveErr: false,
			err_flg: false,
			ttlMessage: null,
			resultTxt: null,
			reserve_flg: false,
			password: localStorage.getItem('password')
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
			separator: '|',
			complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	mounted() {
		// 一番上まで移動
		$("body, html").scrollTop(0);

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(location.pathname.indexOf('miyagikyo') != -1) {
				this.$router.push({ path: '/eng/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = 'Miyagikyo Distillery';
		}else {
			this.plantName = 'Yoichi Distillery';
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
		}

		// トップに戻る先の変更デフォルトが宮城峡トップなのでpland_idが1001の場合は余市のトップをリンク先に設定する
		if(this.plant_id == 1000) {
			this.mygflg = true;
			this.selectedDate = localStorage.getItem('selectedDate');
		}else {
			$(".goToLink").attr('href', '/eng/yoichi/reservation');
		}

		if(location.pathname.indexOf('reservation/error') != -1) {
			this.reserveErr = true;
			this.complementName = 'Reservation was not possible';
			if(this.errors[0].errcode == 500999 || this.errors[0].errcode == 500001 || this.errors[0].errcode == 401000 || this.errors[0].errcode == 401010 || this.errors[0].errcode == 400010 || this.errors[0].errcode == 400075) {
				this.ttlMessage = 'Reservation was not possible<br>';
				this.resultTxt = 'due to capacity.';	
			}else if(this.errors[0].errcode == 400060 || this.errors[0].errcode == 400065) {
				this.ttlMessage = 'Sorry. Your desired course and time have reached capacity. Please consider another time.';
				this.resultTxt = 'Please consider another time.';
			}else {
				this.ttlMessage = 'Reservation was not possible';
				this.resultTxt = this.errors[0].message;
			}
		}else if(location.pathname.indexOf('thanks') != -1) {
			this.ttlMessage = 'Thank you for your<br class="sp"> reservation.<br>Your reservation has<br class="sp"> been accepted.';
			this.reserve_flg = true;
			this.complementName = 'Thank you for your reservation';
		}else if(location.pathname.indexOf('cancel') != -1) {
			this.ttlMessage = 'Cancel complete<br>We have accepted<br class="sp"> your reservation <br class="sp">cancellation';
			this.resultTxt = 'Thank you for contacting us. We look forward to your next reservation.';
			this.complementName = 'Cancel complete';
			localStorage.removeItem('password');
		}else {
			this.ttlMessage = '500 Internal Server Error';
			this.resultTxt = this.errors[0].message;
			this.complementName = 'Cancel failure';
		}
		this.$emit('updateHead');

		if(this.reserveErr == false) {
			// それ以外のlocalStorageは全て外す
			localStorage.removeItem('courseInfo');
			localStorage.removeItem('selectCourseInfo');
			localStorage.removeItem('prefData');
			localStorage.removeItem('visitNum');
			localStorage.removeItem('comeWay');
			localStorage.removeItem('selectedDate');
			localStorage.removeItem('selectedTime');
			localStorage.removeItem('selectedEvent');
			// localStorage.removeItem('men');
			// localStorage.removeItem('women');
			localStorage.removeItem('adult_num');
			localStorage.removeItem('adult_num');
			localStorage.removeItem('minor_num');
			localStorage.removeItem('total_num');
			localStorage.removeItem('group_name');
			localStorage.removeItem('group_name_kana');
			localStorage.removeItem('companion_name');
			localStorage.removeItem('companion_name_kana');
			localStorage.removeItem('pref');
			localStorage.removeItem('tel');
			localStorage.removeItem('email');
			localStorage.removeItem('count');
			localStorage.removeItem('visit');
			localStorage.removeItem('unit');
			// localStorage.removeItem('note');
			localStorage.removeItem('old_tel');
			localStorage.removeItem('resId');
			localStorage.removeItem('errs');
			localStorage.removeItem('error_code');
			localStorage.removeItem('allergy');
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.result.eng {
		background-color: #251E1C;
		padding-top: 124px;
		.result_ttl {
			font-size: 42px;
			text-align: center;
			font-weight: 400;
			color: #FFF;
			margin-bottom: 48px;
		}
		.result_txt {
			width: 100%;
			max-width: 746px;
			font-size: 17px;
			text-align: center;
			line-height: 1.5 !important;
			color: #FFF;
		}
	}

	// 768以下
	.picxel .result.eng {
		padding-top: 40px;
		.result_ttl {
			font-size: 28px;
			margin-bottom: 40px;
		}
		.result_txt {
			font-size: 17px;
			text-align: left;
			width: calc(100% - 20px);
			margin: 0 auto;
		}
	}

	.resultBox {
		width: calc(100% - 20px);
		max-width: 746px;
		margin: 0 auto;
		&_careful {
			font-size: 17px;
			font-weight: 400;
			text-align: left;
			color: #FFF;
		}
	}

	// 768以下
	.picxel .resultBox {
		width: calc(100% - 20px);
		&_careful {
			text-align: left;
			font-size: 17px;
		}
	}

	.win .mainFont.eng .resultRice {
		font-size: 17px;
		line-height: 1.5 !important;
		color: #FFF;
		margin-top: 16px;
	}

	.picxel .eng .resultRice {
		font-size: 17px;
		margin-top: 16px;
	}

	.eng .resultList {
		list-style: none;
		border-top: 1px solid #595858;
		border-bottom: 1px solid #595858;
		margin-top: 32px;
		padding: 24px 30px;
		box-sizing: border-box;
		display: flex;
		&_ttl {
			position: relative;
			font-size: 17px;
			font-weight: 400;
			color: #898989;
			width: calc(100% - 442px);
			&::after {
				display: none;
			}
		}
		&_txt {
			font-size: 17px;
			color: #FFF;
			padding-left: 0;
			width: 442px;
			box-sizing: border-box;
		}
	}

	// 768以下
	.picxel .eng .resultList {
		display: block;
		width: 100%;
		border-top: 1px solid #E8E8E8;
		border-bottom: 1px solid #E8E8E8;
		margin-top: 48px;
		padding: 40px 0;
		&_ttl {
			font-size: 17px;
			width: 100%;
			margin-bottom: 15px;
			&::after {
				position: static;
				display: inline-block;
				content: "：";
				top: 0;
				right: 0;
			}
		}
		&_txt {
			width: 100%;
			font-size: 17px;
			padding-left: 0;
		}
	}

	.eng .explanList {
		list-style: none;
		margin-top: 32px;
		&_li {
			font-size: 17px;
			color: #FFF;
		}
	}

	// 768以下
	.picxel .eng .explanList {
		width: 100%;
		margin: 24px auto 0;
		&_li {
			font-size: 17px;
		}
	}

	.eng .goToLink {
		display: block;
		text-decoration: none;
		font-size: 20px;
		width: 480px;
		text-align: center;
		line-height: 1 !important;
		margin: 48px auto 80px;
		background-color: #898989;
		color: #fff;
		padding: 17px 0;
		border-radius: 4px;
		cursor: pointer;
	}

	// 768以下
	.picxel .eng .goToLink {
		font-size: 20px;
		width: calc(100% - 20px);
		max-width: 480px;
		margin: 24px auto 64px;
		padding: 17px 0;
	}

	.sp {
		display: none;
	}

	.picxel .eng .sp {
		display: block;
	}

	.pc {
		display: block;
	}

	// 768以下
	.picxel .eng .pc {
		display: none;
	}
</style>
