<template>
	<div class="maintenance eng">
		<h2 class="maintenance_ttl">Under maintenance <br class="sp">now</h2>
		<p class="maintenance_txt">The service is temporarily suspended due to maintenance. <br>We apologize for the inconvenience, but please wait for a while.</p>
		<div class="maintenance_end">
				<p class="maintenance_end_ttl">
					Scheduled end time:
				</p>
				<p class="maintenance_end_date">
					Saturday, May 17, 2023
				</p>
		</div>
		<p class="maintenance_last">For reservations and other inquiries regarding reservations<br>Please feel free to contact us by phone.</p>
		<router-link tag="a" to="/eng/miyagikyo/reservation" class="goToLink" v-show="mygflg == true">Return to Top Page</router-link>
		<router-link tag="a" to="/eng/yoichi/reservation" class="goToLink" v-show="mygflg == false">Return to Top Page</router-link>
		<Tel />
	</div>
</template>

<script>
import Tel from './EngTel'

export default {
	name: 'Maintenance',
	components: {
		Tel
	},
	data () {
		return {
			plantName: null,
			mygflg: false
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'maintenance'
			}
		},
		link: [
      { rel: 'stylesheet', href: 'https://www.nikka.com/eng/css/reset.css' },
      { rel: 'stylesheet', href: 'https://www.nikka.com/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: 'https://www.nikka.com/eng/css/layout.css' },
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: 'https://www.nikka.com/eng/css/meanmenu.css' } 
    ],
		// script: [
		// 	{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js' },
    //   { type: 'text/javascript', src: 'https://www.nikka.com/eng/js/jquery.meanmenu.min.js' },
    //   { type: 'text/javascript', src: 'https://www.nikka.com/eng/js/eng_common.js' },
    //   { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js' }
		// ]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1 || localStorage.getItem('plantId') == 1000) {
			this.plantName = '宮城峡蒸溜所';
			this.mygflg = true;
		}else {
			this.plantName = '余市蒸溜所';
			this.mygflg = false;
		}
		this.$emit('updateHead');
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.maintenance {
		padding-top: 80px;
		background: #251E1C;
		color: #FFF;
		&_ttl {
			font-size: 42px;
			font-weight: 400;
			text-align: center;
			margin-bottom: 48px;
			letter-spacing: 0.05em;
			line-height: 1.5 !important;
		}
		&_txt {
			font-size: 17px;
			line-height: 1.5 !important;
			margin-bottom: 24px;
			text-align: center;
			letter-spacing: 0.05em;
		}
		&_end {
			width: calc(100% - 20px);
			max-width: 484px;
			box-sizing: border-box;
			letter-spacing: 0.03em;
			text-align: center;
			color: #BD9C26;
			margin: 0 auto 24px;
			padding: 24px 0;
			border: 1px solid #BD9C26;
			border-radius: 4px;
			&_ttl {
				font-size: 17px;
			}
			&_date {
				font-size: 24px;
			}
		}
		&_last {
			font-size: 17px;
			line-height: 2 !important;
			text-align: center;
			margin-bottom: 0;
		}
	}

	// 768以下
	.picxel .maintenance {
		padding-top: 40px;
		&_ttl {
			width: calc(100% - 20px);
			font-size: 28px;
			margin: 0 auto 40px;
		}
		&_txt {
			width: calc(100% - 20px);
			margin: 0 auto 24px;
			font-size: 17px;
			text-align: left;
		}
		&_end {
			width: calc(100% - 20px);
			margin: 0 auto 24px;
			line-height: 1.8 !important;
			padding: 10px 40px;
		}
		&_last {
			width: calc(100% - 20px);
			margin: 0 auto;
			font-size: 17px;
			text-align: left;
		}
	}

	.maintenance.eng .goToLink {
		margin: 48px auto 80px;
	}

	.picxel .maintenance.eng .goToLink {
		margin: 32px auto 64px;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}
</style>
