<template>
	<div class="reservation">
		<MainVisual />
		<div class="formContent">
			<Step class="twoStep" />
			<section class="formSelect">
				<h3 class="formSelect_ttl">Customer Information</h3>
				<p class="formSelect_red">*Required fields, please make sure to fill them out before applying,</p>
				<p class="formSelect_txt">Please change your settings to allow emails from the domain “@asahi-internet.com”</p>
			</section>
			<form action="/" method="post" class="reservationForm">
				<div class="dateBox plant_id course_id date time" v-if="selectEvent">
					<p class="dateBox_ttl">Reservation date and time</p>
					<div class="dateparent">
						<div class="dateBox_flex">
							<div class="dateBox_flex_cont">
								<p class="dateBox_flex_cont_date">{{ selectDay }}, <br class="sp">{{ selectMonth }} {{ selectDate }}, {{ selectYear }}</p>
								<div class="innerFlex">
									<p class="innerFlex_event" v-html="selectEvent.rsv_course_name"></p>
									<p class="innerFlex_time">{{ retakeDate(selectedTime) }}</p>
								</div>
							</div>
							<!-- <p class="dateBox_flex_time">{{ retakeDate(selectedTime) }}</p> -->
						</div>
						<router-link tag="a" :to="{path: '/eng/miyagikyo/reservation', query: { date: selectedDate, change: 'change' }}" class="dateBox_link" v-show="plant_id == 1000 && eventflg != true">Change date and time<span class="dateLink_arrow"></span></router-link>
						<router-link tag="a" :to="{path: '/eng/yoichi/reservation', query: { date: selectedDate, change: 'change' }}" class="dateBox_link" v-show="plant_id == 1001 && eventflg != true">Change date and time<span class="dateLink_arrow"></span></router-link>
						<a @click="$router.back()" class="dateBox_link" v-show="eventflg == true || eventflg == 'true'">Change date and time<span class="dateLink_arrow"></span></a>
					</div>
				</div>
				<!-- <div class="priceBox" v-if="selectEvent && paidInfo == true">
					<p class="priceBox_ttl">料金</p>
					<p class="priceBox_price">{{ price }}</p>
				</div> -->
				<div class="visitBox inputBox total_num adult_num adult_men_num adult_female_num minor_num">
					<p class="visitBox_ttl mandatory">Number of visitors*</p>
					<div class="parentKind">
						<div class="allKind">
							<div class="allKind_inner">
								<p class="twentyTtl">Over 20 years old</p>
								<div class="selectKind">
									<div class="kindBox Box">
										<!-- <p class="kindBox_ttl">男性</p> -->
										<div class="peopleDiv">
											<select name="grown" id="grown" @change="checkCom()">
												<option value="0">0</option>
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5">5</option>
												<option value="6">6</option>
												<option value="7">7</option>
												<option value="8">8</option>
												<option value="9">9</option>
											</select>
											<span class="peopleDiv_arrow"></span>
										</div>
									</div>
									<!-- <div class="kindBox">
										<p class="kindBox_ttl">女性</p>
										<div class="peopleDiv">
											<select name="women" id="women" @change="checkCom()">
												<option value="0">0</option>
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5">5</option>
												<option value="6">6</option>
												<option value="7">7</option>
												<option value="8">8</option>
												<option value="9">9</option>
											</select>
											<span class="peopleDiv_arrow"></span>
										</div>
										<p>人</p>
									</div> -->
								</div>
							</div>
							<div class="allKind_inner underInner" v-show="minor_flg == true">
								<p class="twentyTtl">Under 20 years old</p>
								<div class="kindBox">
									<div class="peopleDiv">
										<select name="child" id="child" @change="checkCom()">
											<option value="0">0</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
											<option value="6">6</option>
											<option value="7">7</option>
											<option value="8">8</option>
											<option value="9">9</option>
										</select>
										<span class="peopleDiv_arrow"></span>
									</div>
								</div>
							</div>
						</div>
						<p class="err">*Please select the number of visitors.</p>
						<p class="careful">Please make web reservations for a maximum of 9 people. <br class="sp">For reservations of 10 or more people, please contact us by phone or email.</p>
					</div>
					<!-- <p class="yang">※必ず大人の方とお申込みください</p> -->
					<!-- <p class="careful">WEBでのご予約人数は合計9人以内でお願い致します。<br class="sp">10名以上のご予約はお電話にてお願い致します。</p>
					<p class="visitBox_txt">
						<span class="visitBox_txt_red" v-show="selectEvent.condition_minor_disallow_flg == 1">※20歳未満のお客様は参加できません。（お子様の同伴もご遠慮願います。）<br></span>
						<span v-show="mygflg == false">※現在は団体様のご予約は承っておりません。受付再開についてはHPにてお知らせいたします。</span>
					</p> -->
					<!-- <p class="visitBox_txt">
						<span v-show="mygflg == false">20歳未満のみでのご予約は受付していません。必ず、大人の同伴をお願いいたします。<br></span>
						10名様以上は団体となりますので、お電話にてお申込みください。
						<span v-show="mygflg == false"><br>※現在は団体様のご予約は承っておりません。受付再開についてはHPにてお知らせいたします。</span>
					</p> -->
				</div>
				<div id="groupNameBox" class="inputForm group_name flexForm">
					<p class="inputForm_ttl mandatory">Representative Name*</p>
					<div class="halfRight">
						<input type="text" id="group_name" placeholder="Asahi taro" class="inputForm_input">
						<p class="err">*Please enter the representative’s name.</p>
						<p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
					</div>
				</div>
				<!-- <div id="groupKanaBox" class="inputForm group_name_kana">
					<p class="inputForm_ttl mandatory">代表者名（ふりがな）</p>
					<input type="text" id="group_name_kana" placeholder="あさひ たろう" class="inputForm_input">
					<p class="err">※代表者名（ふりがな）を入力してください</p>
					<p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
				</div>
				<div id="companionNameBox" class="inputForm companion_name" v-show="companion_flg">
					<p class="inputForm_ttl mandatory">同伴者様</p>
					<input type="text" id="companion_name" placeholder="朝日 太郎" class="inputForm_input">
					<p class="err">※同伴者様を入力してください</p>
					<p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
				</div>
				<div id="companionKanaBox" class="inputForm companion_name_kana" v-show="companion_flg">
					<p class="inputForm_ttl mandatory">同伴者様（ふりがな）</p>
					<input type="text" id="companion_name_kana" placeholder="あさひ たろう" class="inputForm_input">
					<p class="err">※同伴者様（ふりがな）を入力してください</p>
					<p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
				</div> -->
				<div id="prefBox" class="inputForm start_place_cd flexForm">
					<p class="inputForm_ttl mandatory">Country/Region*</p>
					<div class="halfRight">
						<div class="selectDiv">
							<select name="pref" id="pref" class="inputForm_select" v-if="prefData != null">
								<option value="">Please select</option>
								<option v-for="prefInfo in prefData" :key="prefInfo.departure_id" :value="prefInfo.departure_id">{{ prefInfo.departure_name }}</option>
							</select>
							<span class="selectDiv_arrow"></span>
						</div>
						<!-- <p class="inputForm_txt inputForm_red">政府から発令された緊急事態宣言の対象地域お住いのお客様の見学ご予約・ご来場をお断りさせていたきだきます。</p> -->
						<p class="err">*Please select country</p>
					</div>
				</div>
				<div id="telBox" class="inputForm represent_tel flexForm">
					<p class="inputForm_ttl mandatory">Phone number*</p>
					<div class="halfRight">
						<input type="text" id="tel" class="inputForm_input" placeholder="+8100000000000">
						<p class="err">*Please enter phone number.</p>
						<p class="inputForm_txt">*Please include country code</p>
						<p class="inputForm_txt">*Please enter a contactable number such as a mobile phone number.</p>
					</div>
				</div>
				<div id="emailBox" class="inputForm vst_mail_address flexForm">
					<p class="inputForm_ttl mandatory" v-show="editflg == false">Email address*</p>
					<p class="inputForm_ttl" v-show="editflg">Email address*</p>
					<div class="halfRight">
						<input type="text" id="email" class="inputForm_input" placeholder="sample@asahibeer.co.jp" v-show="editflg == false">
						<p class="inputForm_label">{{ mail }}</p>
						<p class="err">*Please enter email address.</p>
						<p class="inputForm_txt" v-show="editflg == false">*If you have set up any measures to prevent spam emails, please make sure to allow emails from “@asahi-internet.com” to be received.</p>
						<p class="inputForm_txt" v-show="editflg">※Email address cannot be changed</p>
					</div>
				</div>
				<div id="countBox" class="inputForm vst_come_num flexForm">
					<p class="inputForm_ttl mandatory">Number of visits*</p>
					<div class="halfRight">
						<div class="selectDiv">
							<select name="use" id="use" class="inputForm_select" v-if="visitNum != null">
								<option value="">Please select</option>
								<option v-for="coutInfo in visitNum" :key="coutInfo.vst_come_num_id" :value="coutInfo.vst_come_num_id">{{ coutInfo.vst_come_num_name }}</option>
							</select>
							<span class="selectDiv_arrow"></span>
						</div>
						<p class="err">*Please select the number of visits.</p>
					</div>
				</div>
				<div id="meansBox" class="inputForm come_way_cd flexForm">
					<p class="inputForm_ttl mandatory">Method of transportation*</p>
					<div class="halfRight">
						<div class="selectDiv">
							<select name="visit" id="visit" class="inputForm_select" v-if="comeWay != null" @change="selectChange()">
								<option value="">Please select</option>
								<option v-for="wayInfo in comeWay" :key="wayInfo.come_way_id" :value="wayInfo.come_way_id">{{ wayInfo.come_way_name }}</option>
							</select>
							<span class="selectDiv_arrow"></span>
						</div>
						<p class="err">*Please select the method of arrival.</p>
						<div class="shuttleBox" v-show="shuttle_flg">
							<a target="_blank" href="https://www.nikka.com/eng/distilleries/miyagikyo/" class="shuttleBox_link">Shttle bus operation scehdule</a>
							<p class="shuttleBox_txt">*Please check the access page for details.</p>
						</div>
						<div class="couseCareful" v-show="courseInfo[0].condition_transportation_note_1 != null && courseInfo[0].condition_transportation_note_1 != '' && courseInfo[0].condition_transportation_note_1 != undefined">
							<p v-html="courseInfo[0].condition_transportation_note_1"></p>
						</div>
					</div>
				</div>
				<!-- <div id="unitBox" class="inputForm come_way_num" v-show="movePlan">
					<p class="inputForm_ttl mandatory">ご来場台数</p>
					<div class="selectDiv">
						<select name="unit" id="unit" class="inputForm_select">
							<option value="">選択してください</option>
							<option value="1">1台</option>
							<option value="2">2台</option>
							<option value="3">3台</option>
							<option value="4">4台</option>
							<option value="5">5台</option>
							<option value="6">6台</option>
							<option value="7">7台</option>
							<option value="8">8台</option>
							<option value="9">9台</option>
						</select>
						<span class="selectDiv_arrow"></span>
					</div>
					<p class="err">※ご来場台数を選択してください</p>
				</div> -->
				<!-- <div id="allergyBox" class="inputForm allergy" v-show="selectCourseInfo[0].chk_allergy_flg == 1">
					<p class="inputForm_ttl mandatory">アレルギー有無確認</p>
					<div class="allergyBox_flex">
						<div class="radio-content">
							<label for="allergy_no">
								<input id="allergy_no" type="radio" name="allergy" value="0">
								<div class="radio-flex">
									<span></span>
									アレルギー無し
								</div>
							</label>
						</div>
						<div class="radio-content">
							<label for="allergy_yes">
								<input id="allergy_yes" type="radio" name="allergy" value="1">
								<div class="radio-flex">
									<span></span>
									アレルギー有り
								</div>
							</label>
						</div>
					</div>
					<p class="inputForm_txt">※参加者にアレルギーをお持ちの方がいらっしゃる際は「アレルギー有り」を選択してください。<br>後日、詳細確認のためお電話を致します。</p>
					<p class="err">※アレルギー有無を選択してください</p>
				</div> -->
				<!-- <div class="inputForm represent_note" v-show="mygflg == false">
					<p class="inputForm_ttl">備考欄</p>
					<textarea name="represent_note" id="represent_note" class="inputForm_note" rows="8" placeholder="食事を伴うイベントの場合はアレルギー有無をご記入ください"></textarea>
				</div> -->
				<button type="button" class="checkBtn" @click="inputCheck()">Proceed to confirm<br class="sp"> customer information</button>
			</form>
		</div>
		<Tel />

		<!-- 同意確認ボックスモーダル -->
		<div class="modal">
			<div class="checkModal">
				<!-- バツボタン -->
				<button class="closeBtn" @click="modalClose()"></button>
				<section id="overScroll" class="modalScroll">
					<h2 class="modalScroll_ttl">Before making<br class="sp"> an application,<br>please read the<br class="sp"> following</h2>
					<!-- <div class="modalScroll_red">※下記の注意事項を必ずご一読いただき、ご理解いただいた上でお申し込みください。</div> -->
					<!-- <section class="measuresBox checkCont">
						<h3 class="measuresBox_ttl checkCont_ttl">感染予防対策のお願い</h3>
						<ul class="measuresBox_lists checkCont_lists">
							<li>感染防止対策にご協力いただけない場合は、ご入場並びに工場見学への参加をお断りさせていただきます。</li>
							<li>・ご来場時の非接触型体温計での検温を実施します。</li>
							<li>・下記に該当する方およびそのグループの入場をお断りします。</li>
							<li class="checkCont_lists_left">・37.5度以上の発熱、体調不良の方</li>
							<li class="checkCont_lists_left">・日本への入国・帰国された方で、日本政府が定める入国時の検疫措置（検査や待機期間）を満たしていない方</li>
							<li class="checkCont_lists_left">・上記の濃厚接触者に該当する方およびその疑いのある方</li>
							<li>・ご予約時の個人情報を保健所等関係機関に提出する場合もあります。</li>
							<li>・見学は、定時に出発いたします。遅れた場合ご参加いただけない場合がございます。</li>
						</ul>
					</section> -->
					<section class="carefulBox checkCont">
						<h3 class="carefulBox_ttl checkCont_ttl">“Notes on distillery tour reservations”</h3>
						<p class="carefulBox_txt">Please check the following before proceeding.</p>
						<!-- <div class="exampleList" v-show="mygflg == true">
							<p class="exampleList_txt">
								Please refrain from filming during guided tours. <br>
								The tour is an outdoor walking tour. Please bring rain gear in case of rain.<br>
								As this is a whisky distillery, please refrain from bringing pets with you for hygiene management purposes. (However, this does not apply to service animals for people with disabilities.)
							</p>
						</div> -->
						<section class="exampleList" v-show="mygflg == false">
							<h4 class="exampleList_ttl">&lt;For travel agencies&gt;</h4>
							<p class="exampleList_txt">
								“The reservation status on the calendar indicates the availability of groups of 1 to 9 people. <br>
								Please note that even if the number of people is within the available capacity, we will not be able to accommodate you if you use a chartered bus or microbus.”
							</p>
							<!-- <p class="exampleList_txt">
								団体や個人のお客様の代理予約はお電話またはFAXにて承っております。
							</p> -->
							<!-- <p class="exampleList_txt" v-show="mygflg == true">（※現在は団体様のご予約は一切承っておりません、受付の再開につきましてはHPにてお知らせいたします。）</p> -->
							<!-- <p class="exampleList_txt" v-show="mygflg == true">ご見学についてのお問い合わせは、お電話ください。</p> -->
						</section>
						<!-- <section class="exampleList" v-show="mygflg == true">
							<h4 class="exampleList_ttl">&lt;For individual customers&gt;</h4>
							<ul class="exampleList_list">
								<li>・Please cooperate with temperature checks and disinfection measures inside the facility.</li>
								<li>・Customers with a fever of 37.5 degrees Celsius or higher on the day of the visit, those with cold symptoms, and their companions will not be allowed to enter.</li>
								<li>・The tour is an outdoor walking tour. Please bring rain gear in case of rain.</li>
								<li>・The factory tour may be canceled due to inclement weather or natural disasters. Please note that we cannot compensate for transportation expenses in such cases.</li>
							</ul>
						</section> -->
						<section class="exampleList">
							<h4 class="exampleList_ttl">&lt;For individual customers&gt;</h4>
							<ul class="exampleList_list">
								<!-- <li>・場内では検温、消毒のご協力をお願いします。</li>
								<li>・当日37.5度以上の発熱があるお客様、風邪症状のあるお客様及びお連れ様も含めてご入場をお断りいたします。</li> -->
								<li>・Please cooperate with temperature checks and disinfection measures inside the facility.</li>
								<!-- <li>・Customers with a fever of 37.5 degrees Celsius or higher on the day of the visit, those with cold symptoms, and their companions will not be allowed to enter.</li> -->
								<li>・The tour is an outdoor walking tour. Please bring rain gear in case of rain.</li>
								<li>・The factory tour may be canceled due to inclement weather or natural disasters. Please note that we cannot compensate for transportation expenses in such cases.</li>
								<li>・We do not allow drunk visitors on the tour.</li>
								<li>・Please refrain from filming during guided tours.</li>
								<li>・Soft drinks are available for those who drive a car or motorcycle, come by bicycle, are under 20 years old, or are pregnant or nursing.</li>
								<li>・Alcohol tasting is limited to one drink per person.</li>
								<li>・Please note that the production line may not be in operation.</li>
								<li>・As this is a whisky distillery, please refrain from bringing in food for hygiene management purposes."</li>
								<li>・As this is a whisky distillery, please refrain from bringing pets with you for hygiene management purposes. (However, this does not apply to service animals for people with disabilities.)”</li>
							</ul>
						</section>
					</section>
					<section class="privacyBox checkCont">
						<h3 class="privacyBox_ttl checkCont_ttl">Privacy Policy</h3>
						<ol class="privacyBox_lists checkCont_lists">
							<li>1. Asahi Group will use legal and fair means to obtain personal information from customers, and will clearly specify the purpose of use as much as possible.</li>
							<li>2. Unless exceptions are allowed by law or other regulations, Asahi Group will handle the personal information obtained within the scope of the stated purpose of use for notification or disclosure.</li>
							<li>3.Asahi Group will make reasonable security measures to prevent loss, leakage, or unauthorized access, and strive to keep the personal information provided by customers accurate and up to date within the scope of the purpose of use.</li>
							<li>4.When entrusting the handling of personal information to a third party, Asahi Group will select a contractor with sufficient management standards and perform necessary and appropriate supervision.</li>
							<li>5.Except where exceptions are allowed by law or other regulations, Asahi Group will not disclose or provide personal information to third parties without obtaining prior consent from the customer.</li>
							<li>6.Asahi Group will disclose procedures for disclosure and other matters required by law or other regulations, and appropriately respond to requests from customers for inquiries or disclosure.</li>
							<li>7.Asahi Group will comply with laws and regulations regarding the protection of personal information and continue to implement and improve internal systems through employee education and other means.”</li>
						</ol>
					</section>
				</section>
				<!-- ボタン -->
				<div class="checkBox">
					<input type="checkbox" id="privacy" class="checkBox_input" name="privacy" value="I agree to the above terms and conditions.">
					<label for="privacy" class="checkBox_label" @click="checkInput()"></label>
					<p class="checkBox_txt">I agree to the above<br class="sp"> terms and conditions.</p>
				</div>
			</div>
			<div class="modalShadow"></div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import MainVisual from './EngMainVisual'
import Step from './EngStep'
import Tel from './EngTel'

export default {
	name: 'RservationTop',
	components: {
		MainVisual,
		Step,
		Tel
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	data () {
		return {
			plant_id: localStorage.getItem('plantId'),
			mygflg: false,
			editflg: false,
			mail: '',
			plantName: null,
			eventflg: this.$route.query.eventflg,
			prefData: JSON.parse(localStorage.getItem('prefData')),
			visitNum: JSON.parse(localStorage.getItem('visitNum')),
			comeWay: JSON.parse(localStorage.getItem('comeWay')),
			selectedDate: localStorage.getItem('selectedDate'),
			selectedTime: localStorage.getItem('selectedTime'),
			courseInfo: JSON.parse(localStorage.getItem('courseInfo')),
			selectCourseInfo: JSON.parse(localStorage.getItem('selectCourseInfo')),
			selectEvent: JSON.parse(localStorage.getItem('selectedEvent')),
			price: 0,
			selectYear: null,
			selectMonth: null,
			selectDate: null,
			selectDay: null,
			dayOfMonthStr: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
			dayOfWeekStr: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
			paidInfo: false,
			companion_flg: false,
			shuttle_flg: false,
			// movePlan: false,
			errors: JSON.parse(localStorage.getItem('errs')),
			// 入力確認
			error: 0,
			minor_flg: false,
			// men: 0,
			// women: 0,
			adult_num: 0,
			minor_num: 0,
			total_num: 0,
			group_name: null,
			pref: null,
			tel: null,
			email: null,
			count: null,
			visit: null,
			unit: null,
			// note: null,
			scrollPos: ''
		}
	},
	mounted() {
		$("body, html").scrollTop(0);

		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
			$(".reservation").addClass("eng");
		}else {
			this.mygflg = false;
			$(".reservation").addClass("eng");
		}

		// 20歳未満が参加できるか否か
		if(this.selectEvent.condition_minor_disallow_flg == 2) {
			this.minor_flg = true;
		}else {
			this.minor_flg = false;
		}

		// 金額
		this.price = this.selectEvent.rsv_course_fee;
		// this.price = this.price.replace(/,/g, '');
		// this.price = this.price.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );

		// 選択したイベントを絞り込み
		this.courseInfo = this.courseInfo.filter((array) => {
			return array["course_id"] == this.selectEvent.rsv_course_id
		});

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(this.mygflg == true) {
				this.$router.push({ path: '/eng/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation' });
			}
		}else if(this.mygflg == true) {
			this.plantName = 'Miyagikyo Distillery';
		}else {
			this.plantName = 'Yoichi Distillery';
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
		}
		this.$emit('updateHead');
		
		
		// ここに出すべきエラーが残っている時
		if(this.errors != null) {
			if(this.errors[0].errcode == 400000 || this.errors[0].errcode == 400011 || this.errors[0].errcode == 400020 || this.errors[0].errcode == 400031 || this.errors[0].errcode == 400033 || this.errors[0].errcode == 400034 || this.errors[0].errcode == 400040 || this.errors[0].errcode == 400051 || this.errors[0].errcode == 400052 || this.errors[0].errcode == 400053 || this.errors[0].errcode == 400054 || this.errors[0].errcode == 400055 || this.errors[0].errcode == 400056 || this.errors[0].errcode == 400057 || this.errors[0].errcode == 400058 || this.errors[0].errcode == 400059) {
				for(var e = 0; e < this.errors.length; e++) {
					$('.' + this.errors[e].itemname).append('<p class="problemTxt">' + this.errors[e].message + '</p>');
				}
			}
		}

		// シャトルバスの時刻表リンクを出すかどうか判定
		for(var i = 0; i < this.comeWay.length; i++) {
			if(this.comeWay[i].come_way_name.indexOf('Shuttle') != -1) {
				this.shuttle_flg = true;
			}
		}

		this.selectYear = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getFullYear();
		this.selectMonth = this.selectMonth = this.dayOfMonthStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getMonth()];
		this.selectDate = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDate();
		this.selectDay = this.dayOfWeekStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDay()];

		// 有料イベントかどうかの判別
		if(this.selectEvent.rsv_course_type_name.indexOf('有料') != -1) {
			this.paidInfo = true;
		}

		// 住所のデータ整理
		for(var p = 0; p < this.prefData.length; p++) {
			if(this.prefData[p].departure_name == "") {
				this.prefData.splice(p, 1);
			}
		}

		// ご来場回数のデータ整理
		for(var v = 0; v < this.visitNum.length; v++) {
			if(this.visitNum[v].vst_come_num_name == "") {
				this.visitNum.splice(v, 1);
			}
		}

		// 入力の変更に戻ったとき、または予約内容の変更を選択された場合あらかじめ値を入力されている状態にしておく
		if(localStorage.getItem('group_name') != null) {
			$("#grown").val(localStorage.getItem('adult_num'));
			// $("#women").val(localStorage.getItem('women'));
			$("#child").val(localStorage.getItem('minor_num'));
			$("#group_name").val(localStorage.getItem('group_name'));
			$("#pref").val(localStorage.getItem('pref'));
			$("#tel").val(localStorage.getItem('tel'));
			$("#email").val(localStorage.getItem('email'));
			$("#use").val(localStorage.getItem('count'));
			$("#visit").val(localStorage.getItem('visit'));
			if(localStorage.getItem('unit') != null) {
				$("#unit").val(localStorage.getItem('unit'));
			}
			// なおかつ編集時であればメールアドレスを入力不可にする
			if(localStorage.getItem('old_tel') != null) {
				this.editflg = true;
				this.mail = localStorage.getItem('email');
			}
			// if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
			// 	let allergySet = localStorage.getItem('allergy');
			// 	if(allergySet == 1) {
			// 		$('#allergy_yes').prop('checked', true);
			// 	}else {
			// 		$('#allergy_no').prop('checked', true);
			// 	}
			// }
			// if(this.mygflg == false && localStorage.getItem('note') != null && localStorage.getItem('note') != 'null') {
			// 	$("#represent_note").val(localStorage.getItem('note'));
			// }
		}

		// 有料イベントかつ参加合計人数が二人以上の場合
		this.checkCom();
		// 移動手段で台数入力が必要なものかどうか
		this.firstChange();
		
	},
	methods: {
		retakeDate(time) {
			var slice = Number(time);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		checkCom() {
			// 来場者入力確認
			// this.men = $("#grown option:selected").val();
			// this.women = $("#women option:selected").val();
			// this.adult_num = Number(this.men) + Number(this.women);
			this.adult_num = $("#grown option:selected").val();
			this.minor_num = $("#child option:selected").val();
			this.total_num = Number(this.adult_num) + Number(this.minor_num);

			if(this.paidInfo == true && this.total_num >= 2 && this.mygflg == false) {
				this.companion_flg = true;
			}else {
				this.companion_flg = false;
			}
		},

		firstChange() {
			this.visit = $("#visit option:selected").val();
			for(var i = 0; i < this.comeWay.length; i++) {
				if(this.comeWay[i].come_way_id == this.visit) {
					// if(this.comeWay[i].come_way_name.match('車|貸切バス')) {
					// if(this.comeWay[i].come_way_name.indexOf('貸切バス') != -1) {
						// this.movePlan = true;
					// }
					// else {
						// this.movePlan = false;
					$("#unit").val("");
					localStorage.removeItem('unit');
					// }
				}
				else if(this.visit == "") {
					$("#unit").val("");
					// this.movePlan = false;
					localStorage.removeItem('unit');
				}
			}
		},

		selectChange() {
			this.visit = $("#visit option:selected").val();
			$("#unit").val("");
			localStorage.removeItem('unit');
			for(var i = 0; i < this.comeWay.length; i++) {
				if(this.comeWay[i].come_way_id == this.visit) {
					// if(this.comeWay[i].come_way_name.match('車|貸切バス')) {
					if(this.comeWay[i].come_way_name.indexOf('貸切バス') != -1) {
						this.movePlan = true;
					}
					else {
						this.movePlan = false;
					}
				}else if(this.visit == "") {
					this.movePlan = false;
				}
			}
		},

		inputCheck() {
			// 来場者入力確認
			this.error = 0;
			// this.men = $("#men option:selected").val();
			// this.women = $("#women option:selected").val();
			// this.adult_num = Number(this.men) + Number(this.women);
			this.adult_num = $("#grown option:selected").val();
			this.minor_num = $("#child option:selected").val();
			this.total_num = Number(this.adult_num) + Number(this.minor_num);

			// 選択したイベントが存在するかどうか
			if(this.selectEvent == null) {
				this.error++;
			}

			if(this.total_num == 0 || this.adult_num == undefined || this.minor_num == undefined) {
				this.error++;
				$(".visitBox").find("select").addClass("errBox");
				$(".visitBox").find(".err").show();
			}else if(this.total_num >= 10){
				this.error++;
				$(".visitBox").find("select").addClass("errBox");
				$(".visitBox").find(".careful").show();
			}else {
				// 前回入力時にエラー文が発生した場合エラー文削除
				$(".visitBox").find("select").removeClass("errBox");
				$(".visitBox").find(".err").hide();
			}

			// 代表者名
			this.group_name = $("#group_name").val().replace(/ /g,"　");
			if(this.group_name == "") {
				this.error++;
				$("#groupNameBox").find("input").addClass("errBox");
				$("#groupNameBox").find(".err").show();
			}else if(this.group_name.match(/^[\x20\u3000\r\n\t]*$/)) {
				this.error++;
				$("#groupNameBox").find("input").addClass("errBox");
				$("#groupNameBox").find(".not").show();
			}else {
				// 前回入力時にエラー文が発生した場合エラー文削除
				$("#groupNameBox").find("input").removeClass("errBox");
				$("#groupNameBox").find(".err").hide();
			}

			// 住所
			this.pref = $("#pref option:selected").val();
			if(this.pref == "" || this.pref == undefined) {
				this.error++;
				$("#prefBox").find("select").addClass("errBox");
				$("#prefBox").find(".err").show();
			}else {
				// 前回入力時にエラー文が発生した場合エラー文削除
				$("#prefBox").find("select").removeClass("errBox");
				$("#prefBox").find(".err").hide();
			}

			// 電話番号
			this.tel = $("#tel").val();
			if(this.tel == "") {
				this.error++;
				$("#telBox").find("input").addClass("errBox");
				$("#telBox").find(".err").show();
			}else {
				// 前回入力時にエラー文が発生した場合エラー文削除
				$("#telBox").find("input").removeClass("errBox");
				$("#telBox").find(".err").hide();
			}

			// メールアドレス
			this.email = $("#email").val();
			if(this.email == "") {
				this.error++;
				$("#emailBox").find("input").addClass("errBox");
				$("#emailBox").find(".err").show();
			}else {
				// 前回入力時にエラー文が発生した場合エラー文削除
				$("#emailBox").find("input").removeClass("errBox");
				$("#emailBox").find(".err").hide();
			}

			// ご来場回数
			this.count = $("#use option:selected").val();
			if(this.count == "" || this.count == undefined) {
				this.error++;
				$("#countBox").find("select").addClass("errBox");
				$("#countBox").find(".err").show();
			}else {
				$("#countBox").find("select").removeClass("errBox");
				$("#countBox").find(".err").hide();
			}

			// ご来場手段
			this.visit = $("#visit option:selected").val();
			if(this.visit == "" || this.visit == undefined) {
				this.error++;
				$("#meansBox").find("select").addClass("errBox");
				$("#meansBox").find(".err").show();
			}else {
				$("#meansBox").find("select").removeClass("errBox");
				$("#meansBox").find(".err").hide();
			}

			if(this.movePlan == true) {
				this.unit = $("#unit option:selected").val();
				if(this.unit == "") {
					this.error++;
					$("#unitBox").find("select").addClass("errBox");
					$("#unitBox").find(".err").show();
				}else {
					$("#unitBox").find("select").removeClass("errBox");
					$("#unitBox").find(".err").hide();
				}
			}

			// アレルギーの有無
			// if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
			// 	this.allergy = $("#allergyBox input:checked").val();
			// 	if(this.allergy == 0 || this.allergy == 1) {
			// 		$("#allergyBox").find(".err").hide();
			// 	}else {
			// 		this.error++;
			// 		$("#allergyBox").find(".err").show();
			// 	}
			// }

			// 備考欄は必須ではない
			// if(this.mygflg == false) {
			// 	this.note = $("#represent_note").val();
			// }
			if(this.error == 0) {
				// 既に前の段階でチェックを入れていた時または、編集の時プライバシーポリシーのモーダルを表示せずに入力確認へ
				if($("#privacy").prop('checked') == true || localStorage.getItem('old_tel') != null) {
					// お客様情報確認に移動する
					// 全てローカルストレージにはめる
					// localStorage.setItem("men", this.men);
					localStorage.setItem("adult_num", this.adult_num);
					localStorage.setItem("minor_num", this.minor_num);
					localStorage.setItem("total_num", this.total_num);
					localStorage.setItem("group_name", this.group_name);
					localStorage.setItem("pref", this.pref);
					localStorage.setItem("tel", this.tel);
					localStorage.setItem("email", this.email);
					localStorage.setItem("count", this.count);
					localStorage.setItem("visit", this.visit);
					if(this.movePlan == true) {
						localStorage.setItem("unit", this.unit);
					}
					// if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					// 	localStorage.setItem("allergy", this.allergy);
					// }
					// if(this.mygflg == false) {
					// 	localStorage.setItem("note", this.note);
					// }

					// エラーがあった場合は一度空にする
					localStorage.removeItem('errs');
					if(this.plant_id == 1000) {
						this.$router.push({ path: '/eng/miyagikyo/reservation/confirm' });
					}else {
						this.$router.push({ path: '/eng/yoichi/reservation/confirm' });
					}
				}else {
					this.scrollPos = $(window).scrollTop();
					$("body").addClass("fixed").css({
						top: -this.scrollPos
					});
					$(".modal").fadeIn();

					this.checkScroll();
					return false;
				}
			}else {
				// エラーがあった場合は各項目にエラー文字を表示させつつ一番上まで移動するようにする
				$("html, body").animate({
					scrollTop: $('body').offset().top
				}, 500);
			}
		},

		modalClose() {
			$("body").removeClass("fixed").css({
				top: 0
			});
			window.scrollTo(0, this.scrollPos);
			$(".modal").fadeOut();
		},

		checkScroll() {
			const element = document.getElementById("overScroll");
			element.onscroll = () => {
				let clientHeight = element.clientHeight;
				let scrollHeight = element.scrollHeight;
				if(scrollHeight - (clientHeight + element.scrollTop) < 200) {
					$(".checkBox").addClass("is-active");
					$(".checkBox_label").css('pointer-events', 'auto');
				}
			}
		},

		// 規約に同意したとき
		checkInput() {
			if($("#privacy").prop('checked') == false) {
				// 飛ぶ前にbodyの固定を解除
				$("body").removeClass("fixed").css({
					top: 0
				});
				// 全てローカルストレージにはめる
				// localStorage.setItem("men", this.men);
				// localStorage.setItem("women", this.women);
				localStorage.setItem("adult_num", this.adult_num);
				localStorage.setItem("minor_num", this.minor_num);
				localStorage.setItem("total_num", this.total_num);
				localStorage.setItem("group_name", this.group_name);
				localStorage.setItem("pref", this.pref);
				localStorage.setItem("tel", this.tel);
				localStorage.setItem("email", this.email);
				localStorage.setItem("count", this.count);
				localStorage.setItem("visit", this.visit);
				if(this.movePlan == true) {
					localStorage.setItem("unit", this.unit);
				}
				// if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
				// 	localStorage.setItem("allergy", this.allergy);
				// }
				// if(this.mygflg == false) {
				// 	localStorage.setItem("note", this.note);
				// }

				// エラーがあった場合は一度空にする
				localStorage.removeItem('errs');
				// お客様情報確認に移動する
				if(this.plant_id == 1000) {
					this.$router.push({ path: '/eng/miyagikyo/reservation/confirm' });
				}else {
					this.$router.push({ path: '/eng/yoichi/reservation/confirm' });
				}
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}

	.eng .reservation {
		background-color: #251E1C;
	}

	.eng .formContent {
		width: 100%;
		max-width: 746px;
		margin: 0 auto;
		padding: 50px 0 60px;
	}

	// 768以下
	.picxel .eng .formContent {
		// width: calc(100% - 100px);
		width: calc(100% - 20px);
	}

	.win .mainFont.eng .formSelect {
		margin-top: 48px;
		&_ttl {
			width: auto;
			margin: 0 auto;
			font-size: 36px;
			letter-spacing: 0.04em;
			text-align: center;
			font-weight: 400;
			color: #FFF;
			padding-bottom: 0;
			border-bottom: none;
		}
		&_red {
			margin-top: 24px;
			font-size: 14px;
			line-height: 2 !important;
			color: #FFF;
		}
		&_txt {
			font-size: 17px;
			line-height: 2 !important;
			color: #FFF;
		}
	}

	// 768以下
	.picxel.win .mainFont.eng .formSelect {
		margin-top: 48px;
		&_ttl {
			width: auto;
			font-size: 28px;
			padding-bottom: 0;
		}
		&_red {
			margin-top: 24px;
			font-size: 14px;
		}
		&_txt {
			font-size: 17px;
		}
	}

	.reservationForm {
		width: 100%;
		margin: 0 auto;
	}

	// 768以下
	.picxel .reservationForm {
		width: 100%;
	}

	.eng .dateBox {
		position: relative;
		margin: 48px auto 32px;
		&_ttl {
			font-size: 17px;
			font-weight: 400;
			color: #FFF;
			margin-bottom: 0;
		}
		&_flex {
			display: block;
			font-size: 17px;
			margin-bottom: 0;
			&_cont {
				&_date {
					font-size: 28px;
					margin-right: 0;
				}
			}
		}
		&_link {
			position: relative;
			top: auto;
			right: auto;
			font-size: 17px;
			color: #FFF;
			padding-left: 18px;
			text-decoration: underline;
			cursor: pointer;
			.dateLink_arrow {
				position: absolute;
				top: 50%;
				right: auto;
				left: 0;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 10px;
				border-color: transparent transparent transparent #ffffff;
				margin-left: 0;
				&::before {
					display: none;
				}
			}
		}
	}

	// 768以下
	.picxel .eng .dateBox {
		margin: 32px auto 16px;
		&_ttl {
			font-size: 17px;
			margin-bottom: 17px;
		}
		&_flex {
			font-size: 17px;
			margin-bottom: 20px;
			&_date {
				font-size: 24px;
				margin-right: 0;
			}
		}
		&_cont {
			font-size: 28px;
			margin-bottom: 40px;
		}
		&_link {
			position: relative;
			font-size: 17px;
			.dateLink_arrow {
				width: 0;
				height: 0;
				&::before {
					display: none;
				}
			}
		}
	}

	.eng .dateparent {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #595858;
		color: #FFF;
		margin-top: 16px;
		padding: 24px 48px 21px 24px;
		box-sizing: border-box;
	}
	
	.picxel .eng .dateparent {
		width: 100%;
		display: block;
		margin-top: 17px;
		padding: 16px;
	}

	.eng .innerFlex {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		margin-top: 12px;
		&_event {
			font-size: 17px;
			word-break: break-all;
			margin-right: 8px;
		}
		&_time {
			font-size: 17px;
		}
	}

	.reservation.eng .inputBox {
		display: flex;
		align-items: center;
		margin-bottom: 0;
		padding: 16px 30px;
		border-top: 1px solid #595858;
		border-bottom: 1px solid #595858;
	}

	// 768以下
	.picxel .reservation.eng .inputBox {
		display: block;
		margin-bottom: 0;
		padding: 16px 10px;
	}

	// 来場者数
	.eng .visitBox {
		&_ttl {
			position: relative;
			width: calc(100% - 442px);
			font-size: 17px;
			color: #898989;
			font-weight: bold;
			margin-bottom: 0;
			&::after {
				display: none;
			}
		}
		&_txt {
			font-size: 17px;
			line-height: 1.5 !important;
			color: #656565;
			margin-top: 10px;
			span {
				font-size: 14px;
			}
			&_red {
				color: #FF2121;
			}
		}
	}

	// 768以下
	.picxel .reservation.eng .visitBox {
		&_ttl {
			width: 100%;
			font-size: 17px;
			margin-bottom: 8px;
		}
	}

	.eng .parentKind {
		width: 442px;
	}

	.picxel .eng .parentKind {
		width: 100%;
	}

	.eng .allKind {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		&_inner {
			display: flex;
			align-items: center;
		}
		.twentyTtl {
			font-size: 17px;
			color: #FFF;
			margin-bottom: 0;
		}
	}

	// 768以下
	.picxel .eng .allKind {
		display: block;
		.underInner {
			margin-top: 16px;
		}
		.twentyTtl {
			width: 150px;
			font-size: 17px;
			margin-top: 0;
		}

	}

	.eng .selectKind {
		display: block;
		margin-right: 32px;
	}

	// 768以下
	.picxel .eng .selectKind {
		margin-right: 0;
	}

	.eng .kindBox {
		display: flex;
		align-items: center;
		p {
			font-size: 16px;
		}
		.peopleDiv {
			position: relative;
			margin: 0 0 0 8px;
			&_arrow {
				position: absolute;
				top: 50%;
				right: 11px;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 5px 0 5px;
				border-color: #000 transparent transparent transparent;
			}
		}
		select {
			font-size: 17px;
			color: #000;
			background-color: #fff;
			padding: 13px 31px 12px 16px;
			border: 1px solid #898989;
			outline: none;
			-webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
			-moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
			appearance: none; /* 標準のスタイルを無効にする */
			border-radius: 4px;
			&::-ms-expand { /* select要素のデザインを無効にする（IE用） */
				display: none;
			}
			&.errBox {
				background-color: #FFDEE5;
				border: 2px solid #E9514A;
			}
		}
	}

	// 768以下
	.picxel .eng .kindBox {
		p {
			font-size: 24px;
		}
		.peopleDiv {
			&_arrow {
				margin: 0 0 0 25px;
				right: 13px;
				border-width: 6px 3px 0 3px;
			}
		}
		select {
			font-size: 17px;
			transform: scale(1);
			margin: 0;
			padding: 13px 31px 12px 16px;
		}
	}

	.eng .flexForm {
		display: flex;
		align-items: flex-start;
		padding: 16px 30px;
		border-bottom: 1px solid #595858;
	}

	.picxel .eng .flexForm {
		display: block;
		padding: 16px 10px;
	}

	// テキスト系の入力フォーム
	.eng .inputForm {
		margin-bottom: 0;
		&_ttl {
			position: relative;
			width: calc(100% - 442px);
			font-size: 17px;
			letter-spacing: 0.05em;
			font-weight: 400;
			color: #898989;
			margin: 13px 0 0 0;

		}
		.mandatory::after {
			display: none;
		}
		&_txt {
			font-size: 14px;
			letter-spacing: 0.05em;
			margin-top: 10px;
			color: #FFF;
		}
		&_red {
			color: #FF2121;
		}
		&_input {
			width: 100%;
			font-size: 17px;
			padding: 15px 13px;
			background-color: #fbfbfb;
			border: 1px solid transparent;
			outline: none;
			box-sizing: border-box;
			&.error{
				border-color: #FF2121;
			}
			&.errBox {
				background-color: #FFDEE5;
				border: 2px solid #E9514A;
			}
			&::placeholder {
				color: #B2B2B2;
			}
		}
		&_label {
			font-size: 17px;
			color: #FFF;
		}
		.selectDiv {
			position: relative;
			width: 100%;
			&_arrow {
				position: absolute;
				top: 50%;
				right: 18px;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 5px 0 5px;
				border-color: #000 transparent transparent transparent;
			}
		}
		&_select {
			font-size: 17px;
			color: #000;
			background-color: #fff;
			outline: none;
			border: 1px solid #e5e5e5;
			width: 100%;
			box-sizing: border-box;
			padding: 15px 12px;
			-webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
			-moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
			appearance: none; /* 標準のスタイルを無効にする */
			&::-ms-expand { /* select要素のデザインを無効にする（IE用） */
				display: none;
			}
			&.error{
				border-color: #FF2121;
			}
			&.errBox {
				background-color: #FFDEE5;
				border: 2px solid #E9514A;
			}
		}
		// &_note {
		// 	width: 100%;
		// 	font-size: 16px;
		// 	line-height: 1.8 !important;
		// 	padding: 22px 20px;
		// 	background-color: #fbfbfb;
		// 	border: 1px solid transparent;
		// 	outline: none;
		// 	box-sizing: border-box;
		// 	@media screen and (max-width: 768px) {
		// 		font-size: 32px;
		// 		transform: scale(0.75);
		// 		width: 133%;
		// 		margin: -56px 0 0 -17%;
		// 		padding: 55px 50px;
		// 	}
		// 	&.error{
		// 		border-color: #df0202;
		// 	}
		// 	&::placeholder {
		// 		color: #ADADAD;
		// 	}
		// }
	}

	// 768以下
	.picxel .eng .inputForm {
		margin-bottom: 0;
		&_ttl {
			width: 100%;
			font-size: 17px;
			margin-top: 0;
			margin-bottom: 8px;
		}
		.mandatory::after {
			display: none;
		}
		&_txt {
			font-size: 14px;
			margin-top: 8px;
		}
		&_input {
			font-size: 17px;
			transform: scale(1);
			width: 100%;
			margin-left: 0;
			padding: 15px 12px;
		}
		&_label {
			font-size: 17px;
		}
		.selectDiv {
			right: 0;
			border-width: 6px 3px 0 3px;
		}
		&_select {
			font-size: 17px;
			transform: scale(1);
			width: 100%;
			margin-left: 0;
			padding: 15px 12px;
		}
	}

	.eng .halfRight {
		width: 442px;
	}

	.picxel .eng .halfRight {
		width: 100%;
	}

	.err {
		display: none;
		font-size: 14px;
		font-weight: bold;
		margin-top: 5px;
		letter-spacing: 0.05em;
		color: #df0202;
	}

	// 768以下
	.picxel .eng .err {
		font-size: 14px;
		margin-top: 8px;
	}

	.not {
		display: none;
		font-size: 14px;
		font-weight: bold;
		margin-top: 5px;
		letter-spacing: 0.05em;
		color: #df0202;
	}

	// 768以下
	.picxel .eng .not {
		font-size: 14px;
		margin-top: 8px;
	}

	// .yang {
	// 	display: none;
	// 	font-size: 14px;
	// 	font-weight: bold;
	// 	margin-top: 5px;
	// 	letter-spacing: 0.05em;
	// 	color: #df0202;
	// 	@media screen and (max-width: 768px) {
	// 		font-size: 24px;
	// 		margin-top: 10px;
	// 	}
	// }

	.careful {
		display: none;
		font-size: 14px;
		font-weight: bold;
		word-break: break-all;
		margin-top: 5px;
		letter-spacing: 0.05em;
		color: #df0202;
	}

	// 768以下
	.picxel .eng .careful {
		font-size: 14px;
		margin-top: 8px;
	}

	.problemTxt {
		font-size: 14px;
		font-weight: bold;
		margin-top: 5px;
		letter-spacing: 0.05em;
		color: #df0202;
	}

	// 768以下
	.picxel .eng .problemTxt {
		font-size: 14px;
		margin-top: 8px;
	}

	.eng .shuttleBox {
		display: block;
		margin-top: 10px;
		&_link {
			position: relative;
			font-size: 14px;
			text-decoration: none;
			color: #FFF;
			padding-bottom: 1px;
			margin-right: 8px;
			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 1px;
				background-color: #FFF;
				bottom: 0;
				left: 0;
			}
			&::after {
				content: "";
				display: inline-block;
				width: 11px;
				height: 11px;
				margin: 0 0 -1px 3px;
				background-image: url(../assets/img/tabLink_white.png);
				background-size: cover;
			}
		}
		&_txt {
			font-size: 14px;
			color: #FFF;
		}
	}

	// 768以下
	.picxel .eng .shuttleBox {
		display: block;
		&_link {
			font-size: 14px;
			margin-top: 10px;
			margin-right: 0;
			padding-bottom: 1px;
			&::after {
				width: 11px;
				height: 11px;
				margin: 0 0 -1px 3px;
			}
		}
		&_txt {
			font-size: 14px;
			margin-top: 6px;
		}
	}

	// 移動手段下に表示させるテキスト
	.eng .couseCareful {
		font-size: 14px;
		color: #FFF;
		margin-top: 10px;
	}
	
	.picxel .eng .couseCareful {
		font-size: 14px;
		margin-top: 8px;
	}

	// アレルギーラジオボタン
	// .allergyBox_flex {
	// 	display: flex;
	// 	align-items: center;
	// 	.radio-content {
	// 		margin-right: 30px;
	// 		margin-bottom: 15px;
	// 		label {
	// 			margin-bottom: 0;
	// 			display: inline-block;
	// 		}
	// 		input[type=radio] {
	// 			display: none;
	// 		}
	// 		input[type=radio]:checked + .radio-flex {
	// 			font-weight: 700;
	// 			color: #af9754;
	// 			span {
	// 				&::after {
	// 					display: block;
	// 				}
	// 			}
	// 		}
	// 		.radio-flex {
	// 			display: flex;
	// 			align-items: center;
	// 			font-size: 15px;
	// 			cursor: pointer;
	// 			span {
	// 				position: relative;
	// 				margin-right: 10px;
	// 				background-color: #fff;
	// 				border: 1px solid #a7a7a7;
	// 				border-radius: 50%;
	// 				width: 18px;
	// 				height: 18px;
	// 				&::after {
	// 					content: "";
	// 					display: none;
	// 					width: 10px;
	// 					height: 10px;
	// 					position: absolute;
	// 					top: 50%;
	// 					left: 50%;
	// 					transform: translate(-50%, -50%);
	// 					background-color: #af9754;
	// 					border-radius: 50%;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// .picxel .allergyBox_flex {
	// 	display: block;
	// 	.radio-content {
	// 		margin-right: 0;
	// 		margin-bottom: 30px;
	// 	}
	// 	.radio-flex {
	// 		font-size: 28px;
	// 		span {
	// 			margin-right: 20px;
	// 			width: 36px;
	// 			height: 36px;
	// 			&::after {
	// 				width: 20px;
	// 				height: 20px;
	// 			}
	// 		}
	// 	}
	// }

	// 送信確認ボタン
	.eng .checkBtn {
		display: block;
		position: relative;
		font-size: 20px;
		line-height: 1 !important;
		font-weight: 400;
		color: #fff;
		border: none;
		outline: none;
		background-color: #91701e;
		padding: 17px 0;
		width: 480px;
		margin: 48px auto 0;
		cursor: pointer;
		&::after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 10px;
			border-color: transparent transparent transparent #FFF;
			position: absolute;
			top: 50%;
			right: 32px;
			transform: translateY(-50%);
			margin: 0;
		}
	}

	// 768以下
	.picxel .eng .checkBtn {
		width: 100%;
		font-size: 20px;
		margin-top: 80px;
		padding: 12px 60px;
		&::after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 4px 0 4px 8px;
			border-color: transparent transparent transparent #ffffff;
		}
	}

	// モーダル
	.modal {
		display: none;
	}

	.eng .checkModal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 900px;
		height: 80%;
		padding: 73px 100px 70px;
		background-color: #251E1C;
		z-index: 999;
		box-sizing: border-box;

		.modalScroll {
			width: 100%;
			height: calc(100% - 93px);
			padding: 0 20px 0 40px;
			box-sizing: border-box;
			overflow-y: scroll;
			color: #FFF;
			&_ttl {
				font-size: 36px;
				font-weight: 400;
				text-align: center;
				margin-bottom: 41px;
				transform: rotate(0.03deg);
				-moz-transform: rotate(0.03deg);
				-ms-transform: rotate(0.03deg);
				-o-transform: rotate(0.03deg);
				-webkit-transform: rotate(0.03deg);
			}
			&_red {
				font-size: 14px;
				font-weight: 500;
				color: #FF2121;
				text-align: center;
				margin-bottom: 30px;
				padding: 15px;
				background-color: #FFF9F8;
				border: 1px solid #FF2121;
				border-radius: 8px;
			}

			.checkCont {
				&_ttl {
					font-size: 24px;
					font-weight: 400;
					letter-spacing: 0.1em;
					border-bottom: 1px solid #ebebeb;
					padding-bottom: 10px;
					margin-bottom: 15px;
					transform: rotate(0.03deg);
					-moz-transform: rotate(0.03deg);
					-ms-transform: rotate(0.03deg);
					-o-transform: rotate(0.03deg);
					-webkit-transform: rotate(0.03deg);
				}
				&_lists {
					font-size: 17px;
					list-style: none;
					transform: rotate(0.03deg);
					-moz-transform: rotate(0.03deg);
					-ms-transform: rotate(0.03deg);
					-o-transform: rotate(0.03deg);
					-webkit-transform: rotate(0.03deg);
					li {
						line-height: 2 !important;
					}
					&_left {
						padding-left: 16px;
					}
				}
			}

			// 感染予防対策のお願い
			.measuresBox {
				color: #df0202;
			}

			// プライバシーポリシー
			.privacyBox {
				margin-top: 36px;
			}

			// 見学ご予約時のご注意事項
			.carefulBox {
				margin-top: 36px;
				&_txt {
					font-size: 17px;
					line-height: 2 !important;
					transform: rotate(0.03deg);
					-moz-transform: rotate(0.03deg);
					-ms-transform: rotate(0.03deg);
					-o-transform: rotate(0.03deg);
					-webkit-transform: rotate(0.03deg);
				}

				.exampleList {
					font-size: 17px;
					font-weight: 400;
					transform: rotate(0.03deg);
					-moz-transform: rotate(0.03deg);
					-ms-transform: rotate(0.03deg);
					-o-transform: rotate(0.03deg);
					-webkit-transform: rotate(0.03deg);
					&_ttl {
						line-height: 2 !important;
					}
					&_txt {
						line-height: 2 !important;
					}
					&_list {
						list-style: none;
						li {
							line-height: 2 !important;
						}
					}
				}
			}
		}
	}

	// 768以下
	.picxel .eng .checkModal {
		width: calc(100% - 20px);
		padding: 48px 0 16px;

		.modalScroll {
			padding: 0;
			&_ttl {
				font-size: 28px;
				margin-bottom: 32px;
				padding: 0 16px;
			}
			&_red {
				font-size: 17px;
				width: calc(100% - 80px);
				margin: 0 auto 40px;
				padding: 10px 30px;
				background-color: #FFF9F8;
				border-radius: 16px;
			}

			.checkCont {
				&_ttl {
					width: calc(100% - 32px);
					font-size: 20px;
					padding: 0 0 16px 0;
					margin: 0 auto 16px;
					box-sizing: border-box;
				}
				&_lists {
					font-size: 17px;
					padding: 0 16px;
					&_left {
						padding-left: 24px;
					}
				}
			}

			// プライバシーポリシー
			.privacyBox {
				margin-top: 60px;
			}

			// 見学ご予約時のご注意事項
			.carefulBox {
				margin-top: 32px;
				&_txt {
					font-size: 17px;
					padding: 0 16px;
				}

				.exampleList {
					font-size: 17px;
					padding: 0 16px;
					margin: 24px 0;
					&_txt {
						width: 100%;
					}
				}
			}
		}
	}

	.pc_display {
		display: inline;
	}

	// 768以下
	.picxel .pc_display {
		display: none;
	}

	// 同意するチェックボックス
	.eng .checkBox {
		display: flex;
		justify-content: center;
		width: calc(100% - 20px);
		max-width: 495px;
		background-color: rgba($color: #91701e, $alpha: 0.8);
		padding: 22px 0;
		margin: 24px auto 0;
		align-items: center;
		border-radius: 4px;
		transition: background .3s ease-out;
		// チェックボックスカスタマイズ
		&_input[type="checkbox"] {
			display: none;
		}
		&_label {
			position: relative;
			width: 27px;
			height: 27px;
			background-color: #fff;
			border-radius: 4px;
			cursor: pointer;
			pointer-events: none;
			margin-right: 12px;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
		&_input[type="checkbox"]:checked + &_label::before {
			content: "";
			position: absolute;
			display: block;
			width: 10px;
			height: 6px;
			box-sizing: border-box;
			border-bottom: 2px solid #3949ab;
			border-left: 2px solid #3949ab;
			top: 30%;
			left: 50%;
			transform: rotate(-45deg) translate(-50%, -50%);
		}
		&_txt {
			font-size: 20px;
			color: #fff;
			transform: rotate(0.03deg);
			-moz-transform: rotate(0.03deg);
			-ms-transform: rotate(0.03deg);
			-o-transform: rotate(0.03deg);
			-webkit-transform: rotate(0.03deg);
		}
	}

	.checkBox.is-active {
		background-color: #91701e;
	}

	// 768以下
	.picxel .eng .checkBox {
		align-items: flex-start;
		padding: 14px 0;
		margin-top: 16px;
		border-radius: 0;
		&_label {
			width: 24px;
			height: 24px;
			border-radius: 4px;
			margin-top: 5px;
			margin-right: 16px;
		}
		&_input[type="checkbox"]:checked + &_label::before {
			width: 10px;
			height: 6px;
			border-bottom: 2px solid #3949ab;
			border-left: 2px solid #3949ab;
		}
		&_txt {
			font-size: 20px;
		}
	}

	// バツボタン
	.closeBtn {
		position: absolute;
		top: 0;
		right: 0;
		outline: none;
		border: none;
		background: none;
		width: 56px;
		height: 56px;
		background-color: #91701e;
		transition: opacity .3s ease-out;
		cursor: pointer;
		z-index: 9999;
		&:hover {
			opacity: .7;
		}
		&::before {
			content: "";
			width: 24px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%) rotate(45deg);
		}
		&::after {
			content: "";
			width: 24px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%) rotate(-45deg);
		}
	}

	// 768以下
	.picxel .eng .closeBtn {
		width: 42px;
		height: 42px;
		&::before {
			width: 20px;
			height: 2px;
		}
		&::after {
			width: 20px;
			height: 2px;
		}
	}

	// モーダルの後ろの影
	.modalShadow {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.8);
		z-index: 99;
	}
</style>
