<template>
	<div class="send eng">
		<MainVisual />
		<div class="sendContent">
			<div class="redCareful">
				Your reservation is not complete, <br class="sp">please proceed below
			</div>
			<Step class="threeStep" />
			<section class="formSelect">
				<h3 class="formSelect_ttl">Reservation confirmation</h3>
				<p class="formSelect_txt">Before applying, please change your settings to be able to receive emails from “@asahi-internet.com”. Depending on your settings, you may not receive our emails, so please contact us if you do not receive them.</p>
			</section>
			<form action="/" method="post" class="sendForm">
				<ul class="inputLists">
					<li class="inputList" v-if="selectEvent">
						<p class="inputList_ttl">Reservation date and time</p>
						<div class="inputDate inputList_cont">
							<p class="inputDate_date">{{ selectDay }}, {{ selectMonth }} {{ selectDate }}, {{ selectYear }}</p>
							<div class="inputDate_flex">
								<p v-html="selectEvent.rsv_course_name"></p>
								<p class="inputDate_time">{{ retakeDate(selectedTime) }}</p>
							</div>
						</div>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">Number of visitors</p>
						<div class="peopleBox">
							<div class="peopleMore">
								<p class="peopleMore_people">Over 20 years old {{ adult_num }} person</p>
							</div>
							<div class="peopleBelow" v-show="minor_flg == true">
								<p class="peopleBelow_less">Under 20 years old {{ minor_num }} person</p>
							</div>
						</div>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">Representative Name</p>
						<p class="inputList_cont">{{ group_name }}</p>
					</li>
					<!-- <li class="inputList">
						<p class="inputList_ttl">代表者名（ふりがな）</p>
						<p class="inputList_cont">{{ group_name_kana }}</p>
					</li>
					<li class="inputList" v-if="companion_flg">
						<p class="inputList_ttl">同伴者様</p>
						<p class="inputList_cont">{{ companion_name }}</p>
					</li>
					<li class="inputList" v-if="companion_flg">
						<p class="inputList_ttl">同伴者様（ふりがな）</p>
						<p class="inputList_cont">{{ companion_name_kana }}</p>
					</li> -->
					<li class="inputList">
						<p class="inputList_ttl">Country/Region</p>
						<p class="inputList_cont">{{ prefName }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">Phone number</p>
						<p class="inputList_cont">{{ tel }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">Email address</p>
						<p class="inputList_cont">{{ email }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">Number of visits</p>
						<p class="inputList_cont">{{ countNum }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">Method of transportation</p>
						<p class="inputList_cont">{{ visitWay }}</p>
					</li>
					<!-- <li class="inputList" v-if="unit != null && unit != 'null'">
						<p class="inputList_ttl">ご来場台数</p>
						<p class="inputList_cont">{{ unit }}台</p>
					</li> -->
					<!-- <li class="inputList" v-if="selectCourseInfo[0].chk_allergy_flg == 1">
						<p class="inputList_ttl">アレルギー有無確認</p>
						<p class="inputList_cont" v-show="allergy == 0">アレルギー無し</p>
						<p class="inputList_cont" v-show="allergy == 1">アレルギー有り</p>
					</li> -->
					<!-- <li class="inputList" v-if="mygflg == false && note != null && note != 'null' && note != ''">
						<p class="inputList_ttl">備考</p>
						<pre class="inputList_cont inputList_note">{{ note }}</pre>
					</li> -->
				</ul>
				<div class="lastCheck">
					<ul class="lastCheck_lists">
						<li>*If you have made multiple reservations, we may contact you in advance for confirmation.</li>
						<!-- <li>*Please note that if you cancel the seminar on the day of the event, you will be charged 100% of the cancellation fee.</li> -->
					</ul>
					<p class="lastCheck_txt" v-show="mygflg == true">Are you sure you want to confirm <br class="sp">your reservation <br>at the Miyagikyo Distillery <br class="sp">(Sendai, Miyagi Prefecture)?</p>
					<p class="lastCheck_txt" v-show="mygflg == false">Are you sure you want to confirm <br class="sp">your reservation at the Yoichi <br>Distillery (Yoichi, Hokkaido)?</p>
					<div id="lottie" ref="lottie" v-show="anim_flg"></div>
					<div class="btns">
						<button type="button" class="sendBtn" @click="sendForm()">Confirm reservation</button>
						<button type="button" class="backBtn" @click="backInput()">Change input details</button>
					</div>
				</div>
			</form>
		</div>
		<Tel />
		<div class="loadingBack" v-show="anim_flg">
			<div id="lottie" ref="lottie"></div>
		</div>
		<div class="faildModal" v-show="faild_flg">
			<p class="faildModal_ttl">Communication failure</p>
			<p class="faildModal_txt">In a place with good signal conditions Please check again</p>
			<button type="button" class="faildModal_btn" @click="closeFaild()">close</button>
		</div>
		<div class="reservedModal" v-show="reserved_flg">
			<p class="reservedModal_ttl">Reserved</p>
			<p class="reservedModal_txt">same email address<br>same course<br>same seminar<br>same time,<br>We have received your reservation.</p>
			<button type="button" class="reservedModal_btn" @click="closeReserved()">close</button>
		</div>
		<div class="faildBack" v-show="modal_flg" @click="closeModal()"></div>
	</div>
</template>

<script>
import $ from 'jquery';
import MainVisual from './EngMainVisual'
import Step from './EngStep'
import Tel from './EngTel'
import axios from 'axios'
import lottie from 'lottie-web'
import animationData from "@/assets/anim/nikka_loading_231016_2.json"

export default {
	name: 'RservationThree',
	components: {
		MainVisual,
		Step,
		Tel
	},
	data () {
		return {
			plant_id: localStorage.getItem('plantId'),
			mygflg: false,
			plantName: null,
			prefData: JSON.parse(localStorage.getItem('prefData')),
			visitNum: JSON.parse(localStorage.getItem('visitNum')),
			comeWay: JSON.parse(localStorage.getItem('comeWay')),
			courseInfo: JSON.parse(localStorage.getItem('courseInfo')),
			selectCourseInfo: JSON.parse(localStorage.getItem('selectCourseInfo')),
			selectedDate: localStorage.getItem('selectedDate'),
			selectedTime: localStorage.getItem('selectedTime'),
			selectEvent: JSON.parse(localStorage.getItem('selectedEvent')),
			price: 0,
			selectYear: null,
			selectMonth: null,
			selectDate: null,
			selectDay: null,
			dayOfMonthStr: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
			dayOfWeekStr: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
			paidInfo: false,
			companion_flg: false,
			movePlan: false,
			minor_flg: false,
			anim_flg: false,
			// 入力項目
			// men: localStorage.getItem("men"),
			// women: localStorage.getItem("women"),
			adult_num: localStorage.getItem("adult_num"),
			minor_num: this.minor_num = localStorage.getItem("minor_num"),
			total_num: localStorage.getItem("total_num"),
			group_name: localStorage.getItem("group_name"),
			pref: localStorage.getItem("pref"),
			prefName: null,
			tel: localStorage.getItem("tel"),
			email: localStorage.getItem("email"),
			count: this.count = localStorage.getItem("count"),
			countNum: null,
			visit: localStorage.getItem("visit"),
			visitWay: null,
			unit: localStorage.getItem("unit"),
			// note: localStorage.getItem("note"),
			url: "/api/reserve/save",
			// エラー
			errors: null,
			// データ
			data: null,
			modal_flg: false,
			faild_flg: false,
			reserved_flg: false
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	mounted() {
		$("body, html").scrollTop(0);

		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		// 20歳未満が参加できるか否か
		if(this.selectEvent.condition_minor_disallow_flg == 2) {
			this.minor_flg = true;
		}else {
			this.minor_flg = false;
		}

		// アニメーション初期設定
		lottie.loadAnimation({
			container: this.$refs.lottie,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData,
		});
		
		// 金額
		this.price = this.selectEvent.rsv_course_fee;
		// this.price = this.price.replace(/,/g, '');
		// this.price = this.price.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(this.mygflg == true) {
				this.$router.push({ path: '/eng/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = 'Miyagikyo Distillery';
		}else {
			this.plantName = 'Yoichi Distillery';
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
		}
		this.$emit('updateHead');

		this.selectYear = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getFullYear();
		this.selectMonth = this.dayOfMonthStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getMonth()];
		this.selectDate = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDate();
		this.selectDay = this.dayOfWeekStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDay()];

		// 有料イベントかどうかの判別
		if(this.selectEvent.rsv_course_type_name.indexOf('有料') != -1) {
			this.paidInfo = true;
			if(this.total_num >= 2 && this.mygflg == false) {
				this.companion_flg = true;
			}
		}

		// 住所のnameを保存していたidを元に探す
		for(var i = 0; i < this.prefData.length; i++) {
			if(this.prefData[i].departure_id == this.pref) {
				this.prefName = this.prefData[i].departure_name;
			}
		}

		if(this.paidInfo == true && this.companion_flg == true) {
			this.companion_name = localStorage.getItem("companion_name");
			this.companion_name_kana = localStorage.getItem("companion_name_kana");
		}

		// this.coutの値を元にご来場回数のnameを探す
		for(var j = 0; j < this.visitNum.length; j++) {
			if(this.visitNum[j].vst_come_num_id == this.count) {
				this.countNum = this.visitNum[j].vst_come_num_name;
			}
		}

		// this.visitの値を元にご来場手段のnameを探す
		for(var k = 0; k < this.comeWay.length; k++) {
			if(this.comeWay[k].come_way_id == this.visit) {
				this.visitWay = this.comeWay[k].come_way_name;
			}
		}

		// 申し込んだイベントと来場手段によって送るデータが変わる＋新規予約か編集によっても変更になる
		if(this.companion_flg == false && this.unit == null || this.unit == 'null') {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				} else {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						language: 1
					};
				}
			}
		}else if(this.companion_flg == false && this.unit != null && this.unit != 'null') {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						language: 1
					};
				}
			}
		}else if(this.companion_flg == true && this.unit == null || this.unit == 'null') {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						language: 1
					};
				}
			}
		}else {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						reservation_id: localStorage.getItem('resId'),
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						telno: localStorage.getItem('old_tel'),
						password: localStorage.getItem('password'),
						language: 1
					};
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						language: 1
					};
				}else {
					this.data = {
						plant_id: this.plant_id,
						course_id: this.selectEvent.rsv_course_id,
						date: this.selectedDate,
						time: this.selectedTime,
						group_name: this.group_name,
						// represent_note: this.note,
						start_place_cd: this.pref,
						represent_tel: this.tel,
						vst_mail_address: this.email,
						vst_come_num: this.count,
						total_num: this.total_num,
						adult_num: this.adult_num,
						// adult_men_num: this.men,
						// adult_female_num: this.women,
						minor_num: this.minor_num,
						come_way_cd: this.visit,
						come_way_num: this.unit,
						language: 1
					};
				}
			}
		}
	},
	methods: {
		retakeDate(time) {
			var slice = Number(time);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		backInput() {
			history.back();
		},

		sendForm() {
			$('.sendBtn').prop('disabled',true);

			// アニメーション出す
			this.anim_flg = true;

			axios({
				method: "post",
				url: this.url,
				timeout: 1500000,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: this.data
			}).then(res => {
				this.anim_flg = false;
				window.$('.sendBtn').prop('disabled',false);
				// 予約IDの発行
				localStorage.setItem('password', res.data.data[0].password);

				// 結果ページに飛ぶ
				if(this.plant_id == 1000) {
					this.$router.push({ path: '/eng/miyagikyo/reservation/thanks' });	
				}else {
					this.$router.push({ path: '/eng/yoichi/reservation/thanks' });
				}
			}).catch(err => {
				this.anim_flg = false;
				// this.modal_flg = true;
				// this.faild_flg = true;
				// window.$('.sendBtn').prop('disabled', false);

				if (!err.response || err.response == undefined) {
					this.modal_flg = true;
					this.faild_flg = true;
					window.$('.sendBtn').prop('disabled', false);
				} else {
					this.errors = err.response.data.data;
					localStorage.setItem('errs', JSON.stringify(this.errors));
					window.$('.sendBtn').prop('disabled',false);
					if(this.errors[0].errcode == 400000 || this.errors[0].errcode == 400011 || this.errors[0].errcode == 400020 || this.errors[0].errcode == 400031 || this.errors[0].errcode == 400033 || this.errors[0].errcode == 400034 || this.errors[0].errcode == 400040 || this.errors[0].errcode == 400051 || this.errors[0].errcode == 400052 || this.errors[0].errcode == 400053 || this.errors[0].errcode == 400054 || this.errors[0].errcode == 400055 || this.errors[0].errcode == 400056 || this.errors[0].errcode == 400057 || this.errors[0].errcode == 400058 || this.errors[0].errcode == 400059) {
						history.back();
					}else if(this.errors[0].errcode == 400100) {
						this.modal_flg = true;
						this.reserved_flg = true;
					}else if(this.errors[0].errcode == '' || this.errors[0].errcode == null || this.errors[0].errcode == undefined || this.errors[0].errcode == 0) {
						this.modal_flg = true;
						this.faild_flg = true;
					}
					else {
						if(this.plant_id == 1000) {
							this.$router.push({ path: '/eng/miyagikyo/reservation/error' });
						}else {
							this.$router.push({ path: '/eng/yoichi/reservation/error' });
						}
					}
				}


			});
		},

		closeModal() {
			this.modal_flg = false;
			this.faild_flg = false;
			this.reserved_flg = false;
		},

		closeFaild() {
			this.modal_flg = false;
			this.faild_flg = false;
		},

		closeReserved() {
			this.modal_flg = false;
			this.reserved_flg = false;
		}

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.send.eng {
		background: #251E1C;
	} 

	.eng .sendContent {
		max-width: 746px;
		margin: 0 auto;
		padding: 48px 0 60px;
		border-top: none;
	}

	// 768以下
	.picxel .eng .sendContent {
		width: calc(100% - 20px);
		padding: 80px 0;
		border-top: none;
	}

	.eng .redCareful {
		width: 100%;
		max-width: 746px;
		font-size: 17px;
		color: #BA9326;
		text-align: center;
		margin: 0 0 24px;
		padding: 21px 0 22px;
		background-color: transparent;
		border: 1px solid #BA9326;
		box-sizing: border-box;
	}

	// 768以下
	.picxel .eng .redCareful {
		font-size: 17px;
		text-align: center;
		margin-top: 0;
		padding: 12px 30px;
	}

	.win .mainFont.eng .formSelect {
		margin-top: 48px;
		color: #FFF;
		&_ttl {
			width: auto;
			margin: 0 auto;
			padding-bottom: 15px;
			font-size: 36px;
			letter-spacing: 0.04em;
			text-align: center;
			font-weight: 400;
			border-bottom: none;
		}
		&_txt {
			font-size: 17px;
			line-height: 2 !important;
			margin-top: 30px;
		}
	}

	// 768以下
	.picxel.win .mainFont.eng .formSelect {
		margin-top: 48px;
		&_ttl {
			width: auto;
			font-size: 24px;
			padding-bottom: 0;
			border-bottom: none;
		}
		&_txt {
			font-size: 17px;
			margin-top: 24px;
		}
	}

	// 入力確認
	.eng .inputLists {
		background: transparent;
		width: 100%;
		margin-top: 32px;
		padding: 0;
		list-style: none;
		box-sizing: border-box;
		border-top: 1px solid #595858;
	}

	// 768以下
	.picxel .eng .inputLists {
		margin-top: 32px;
		padding: 0;
	}

	.eng .inputList {
		display: flex;
		align-items: flex-start;
		margin-bottom: 0;
		padding: 24px 30px;
		border-bottom: 1px solid #595858;
		&_ttl {
			width: calc(100% - 442px);
			font-size: 17px;
			color: #898989;
			margin-bottom: 0;
		}
		&_cont {
			width: 442px;
			font-size: 17px;
			font-weight: 400;
			color: #FFF;
			line-height: 1.5 !important;
		}
		// &_note {
		// 	white-space: pre-wrap;
		// }
	}

	// 768以下
	.picxel .eng .inputList {
		display: block;
		margin-bottom: 0;
		padding: 24px 10px;
		&_ttl {
			width: 100%;
			font-size: 17px;
			margin-bottom: 16px;
		}
		&_cont {
			width: 100%;
			font-size: 17px;
		}
	}

	// コース
	.eng .inputDate {
		display: block;
		&_flex {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			p {
				word-break: break-all;
			}
		}
		&_date {
			margin-right: 0;
		}
		&_time {
			margin-left: 8px;
		}
	}

	// 768以下
	.picxel .eng .inputDate {
		&_date {
			margin-right: 0;
		}
	}

	// ご予約人数
	.eng .peopleBox {
		display: block;
		font-size: 17px;
		width: 442px;
	}

	// 768以下
	.picxel .eng .peopleBox {
		display: block;
		font-size: 17px;
	}

	.eng .peopleMore {
		display: block;
		&_people {
			font-size: 17px;
			font-weight: 400;
			color: #FFF;
			margin-left: 0;
		}
	}

	// 768以下
	.picxel .eng .peopleMore {
		display: block;
		&_ttl {
			margin-bottom: 0;
		}
		&_people {
			display: inline-block;
			margin-left: 0;
			margin-right: 32px;
		}
	}

	.eng .peopleBelow {
		display: block;
		margin-left: 0;
		&_less {
			font-size: 17px;
			font-weight: 400;
			color: #FFF;
			margin-left: 0;
		}
	}

	// 768以下
	.picxel .eng .peopleBelow {
		display: block;
		margin-left: 0;
		margin-top: 0;
		&_ttl {
			margin-bottom: 0;
		}
		&_less {
			margin-left: 0;
		}
	}

	// 最終確認
	.win .mainFont.eng .lastCheck {
		width: 100%;
		text-align: center;
		background: transparent;
		margin-top: 24px;
		padding: 0;
		box-sizing: border-box;
		&_lists {
			margin-bottom: 28px;
			li {
				font-size: 14px;
				line-height: 1.5 !important;
				text-align: left;
				color: #FFF;
			}
		}
		&_txt {
			position: relative;
			display: inline-block;
			font-size: 20px;
			font-weight: 400;
			text-align: center;
			color: #FFF;
			&::after {
				display: none;
			}
		}
	}

	.picxel .eng .lastCheck {
		margin-top: 16px;
		&_lists {
			margin-bottom: 32px;
			li {
				font-size: 12px;
			}
		}
		&_txt {
			font-size: 20px;
		}
	}

	.eng .loadingBack {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #FFF;
		z-index: 999;
	}

	.eng #lottie {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 200px;
	}

	.picxel .eng #lottie {
		width: 150px;
	}

	// ボタン
	.eng .btns {
		width: 480px;
		margin: 24px auto 0;
		display: block;
		// 入力内容を変更するボタン
		.backBtn {
			font-size: 20px;
			line-height: 1 !important;
			color: #fff;
			text-align: center;
			background: #898989;
			outline: none;
			padding: 17px 0;
			border: none;
			box-sizing: border-box;
			width: 100%;
			border-radius: 4px;
			cursor: pointer;
		}
		// 予約を確定するボタン
		.sendBtn {
			position: relative;
			font-size: 20px;
			line-height: 1 !important;
			font-weight: 400;
			color: #fff;
			letter-spacing: 0.05em;
			border: none;
			outline: none;
			background-color: #91701e;
			border: none;
			margin-bottom: 16px;
			padding: 17px 0;
			width: 100%;
			border-radius: 4px;
			cursor: pointer;
			&:disabled {
				background-color: #e8e0c4;
				border-color: none;
			}
			&::after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 10px;
				border-color: transparent transparent transparent #fff;
				position: absolute;
				top: 50%;
				right: 32px;
				transform: translateY(-50%);
			}
		}
	}

	// 768以下
	.picxel .eng .btns {
		width: 100%;
		display: block;
		margin: 40px auto 40px;
		// 入力内容を変更するボタン
		.backBtn {
			width: 100%;
			font-size: 20px;
			padding: 17px 0;
			margin-bottom: 0;
			border: none;
		}
		// 予約を確定するボタン
		.sendBtn {
			width: 100%;
			font-size: 20px;
			margin-bottom: 16px;
			padding: 17px 0;
			border: none;
			&::after {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 10px;
				border-color: transparent transparent transparent #fff;
			}
		}
	}

	.eng .sp {
		display: none;
	}

	.picxel .eng .sp {
		display: block;
	}

	//add to 231215

	// 失敗モーダル関連
	.faildModal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 640px;
		padding: 64px 100px 70px;
		background: #FFF;
		box-sizing: border-box;
		z-index: 999;
		.faildModal_ttl {
			color: #000;
			font-size: 32px;
			text-align: center;
			margin-bottom: 33px;
		}
		.faildModal_txt {
			color: #000;
			font-size: 16px;
			text-align: center;
			margin-bottom: 48px;
		}
		.faildModal_btn {
			display: block;
			width: 222px;
			font-size: 18px;
			text-align: center;
			color: #FFF;
			margin: 0 auto;
			padding: 16px;
			border: none;
			outline: none;
			background: #898989;
			box-sizing: border-box;
			cursor: pointer;
		}
	}

	.picxel .faildModal {
		width: calc(100% - 50px);
		padding: 64px 24px;
		.faildModal_ttl {
			font-size: 24px;
		}
		.faildModal_txt {
			font-size: 16px;
		}
		.faildModal_btn {
			max-width: 100%;
		}
	}

	.reservedModal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 640px;
		padding: 64px 100px 70px;
		background: #FFF;
		box-sizing: border-box;
		z-index: 999;
		.reservedModal_ttl {
			font-size: 32px;
			color: #FF0000;
			text-align: center;
			margin-bottom: 33px;
		}
		.reservedModal_txt {
			font-size: 16px;
			text-align: center;
			margin-bottom: 48px;
			color: #000;
		}
		.reservedModal_btn {
			display: block;
			width: 222px;
			font-size: 18px;
			text-align: center;
			color: #FFF;
			margin: 0 auto;
			padding: 16px;
			border: none;
			outline: none;
			background: #898989;
			box-sizing: border-box;
			cursor: pointer;
		}
	}

	.picxel .reservedModal {
		width: calc(100% - 50px);
		padding: 64px 24px;
		.reservedModal_ttl {
			font-size: 24px;
		}
		.reservedModal_txt {
			font-size: 16px;
			text-align: left;
		}
		.reservedModal_btn {
			max-width: 100%;
		}
	}

	// 失敗モーダル背景
	.faildBack {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: rgba($color: #000000, $alpha: .8);
		z-index: 99;
		cursor: pointer;
	}
</style>
