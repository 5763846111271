<template>
	<div class="stepContent">
		<ul class="formSteps">
			<li class="step stepOne">
				<p class="step_ttl">Select desired date</p>
				<!-- <p class="step_cont">見学希望日選択</p> -->
			</li>
			<li class="step stepTwo">
				<p class="step_ttl">Input information</p>
				<!-- <p class="step_cont">お客様情報入力</p> -->
			</li>
			<li class="step stepThree">
				<p class="step_ttl">Confirmation</p>
				<!-- <p class="step_cont">お客様情報確認</p> -->
			</li>
			<li class="step stepFour">
				<p class="step_ttl">Reservation complete</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Step',
	data () {
		return {
			
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	// ステップ
	.eng .formSteps {
		display: flex;
		justify-content: space-between;
		width: 652px;
		list-style: none;
		margin: 0 auto;
	}

	// 768以下
	.picxel .eng .formSteps {
		width: 100%;
		display: block;
	}

	.step {
		text-align: center;
		color: #FFF;
		&_ttl {
			position: relative;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.05em;
			border-radius: 4px;
			background-color: #91701e;
			padding: 10px 16px 10px;
			opacity: .3;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: -23px;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-left: 10px solid #91701e;
				border-top: 7.5px solid transparent;
				border-bottom: 7.5px solid transparent;
				opacity: .3;
			}
		}
		// &_cont {
		// 	font-size: 16px;
		// 	text-align: center;
		// 	letter-spacing: 0.075em;
		// 	font-weight: bold;
		// }
	}

	// 768以下
	.picxel .eng .step {
		width: 100%;
		margin-bottom: 15px;
		&_ttl {
			font-size: 14px;
			padding: 6px 0;
			border-radius: 4px;
			&::after {
				content: "";
				position: absolute;
				top: auto;
				left: 50%;
				right: auto;
				bottom: -12px;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7px 4px 0 4px;
				border-color: #91701e transparent transparent transparent;
				opacity: .3;
			}
		}
		&_cont {
			text-align: left;
			font-size: 26px;
		}
	}

	.stepOne {
		.step_ttl {
			opacity: 1;
			&::after {
				opacity: 1;
			}
		}
	}

	.stepFour {
		.step_ttl {
			&::after {
				display: none;
			}
		}
	}

	// お客様情報入力の画面時
	.twoStep {
		.stepOne {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
		.stepTwo {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
	}

	// お客様情報確認の画面時
	.threeStep {
		.stepOne {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
		.stepTwo {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
		.stepThree {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
	}

	// 予約完了の画面時
	.fourStep {
		.stepOne {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
		.stepTwo {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
		.stepThree {
			.step_ttl {
				opacity: 1;
				&::after {
					opacity: 1;
				}
			}
		}
		.stepFour {
			.step_ttl {
				opacity: 1;
			}
		}
	}

	.sp {
		display: none;
	}

	.picxel .sp {
		display: block;
	}
</style>
