<template>
	<div class="engVisual">
		<div class="visualInner">
			<div class="visualInner_tag miyagi" v-show="mygflg == true && eventflg == false">MIYAGIKYO</div>
			<div class="visualInner_tag yoichi" v-show="mygflg == false && eventflg == false">YOICHI</div>
			<p class="visualInner_ttl">Distillery tour<br class="sp"> reservation</p>
			<router-link tag="a" to="/eng/miyagikyo/reservation/reserve" class="visualInner_link" v-show="mygflg == true && eventflg == false">Change/cancellation of your reservation<br class="sp"> can be made here.</router-link>
			<router-link tag="a" to="/eng/yoichi/reservation/reserve" class="visualInner_link" v-show="mygflg == false && eventflg == false">Change/cancellation of your reservation<br class="sp"> can be made here.</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MainVisual',
	data () {
		return {
			mygflg: false,
			eventflg: false
		}
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
			if(location.pathname.indexOf('event') != -1) {
				this.eventflg = true;
				window.$('.engVisual').addClass('event');
			}else {
				window.$('.engVisual').addClass('miyagi');
			}
		}else {
			if(location.pathname.indexOf('event') != -1) {
				this.eventflg = true;
				window.$('.engVisual').addClass('event');
			}else {
				window.$('.engVisual').addClass('yoichi');
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	.engVisual {
		position: relative;
		width: 100%;
		height: 336px;
		color: #FFF;
		&.miyagi {
			background-image: url(../assets/img/miyagiVisual_eng.jpg);
			background-size: cover;
			background-position: center center;
		}
		&.yoichi {
			background-image: url(../assets/img/yoichiVisual_eng.jpg);
			background-size: cover;
			background-position: center center;
		}
	}
	.picxel .engVisual {
		height: 320px;
	}

	.visualInner {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		&_tag {
			font-size: 14px;
			letter-spacing: 0.1em;
			text-align: center;
			margin: 0 auto 8px;
			padding: 6px 0;
			border: 1px solid #FFF;
			&.miyagi {
				width: 124px;
			}
			&.yoichi {
				width: 88px;
			}
		}
		&_ttl {
			font-size: 42px;
			margin-bottom: 13px;
			text-align: center;
		}
		&_link {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 17px;
			color: #FFF;
			text-decoration: underline;
			&::before {
				content: "";
				display: inline-block;
				margin-right: 8px;
				margin-bottom: 2px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 3.5px 0 3.5px 6.5px;
				border-color: transparent transparent transparent #FFF;
			}
		}
	}
	.picxel .visualInner {
		&_tag {
			font-size: 12px;
			padding: 4px 0;
			&.miyagi {
				width: 111px;
			}
			&.yoichi {
				width: 80px;
			}
		}
		&_ttl {
			font-size: 32px;
			margin-bottom: 17px;
		}
		&_link {
			align-items: flex-start;
			width: calc(100% - 72px);
			font-size: 14px;
			text-align: center;
			margin: 0 auto;
			&::before {
				margin-top: 8px;
			}
		}
	}
</style>
