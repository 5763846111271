<template>
	<div class="result">
		<Step class="fourStep" />
		<h2 class="result_ttl" v-html="ttlMessage"></h2>
		<div class="resultBox" v-if="reserve_flg == true">
			<p class="resultBox_careful">「予約番号」は、ご予約の確認や変更、キャンセルの際に必要となりますので必ずお控えください。</p>
			<ul>
				<li v-show="mygflg == true && this.reserve_flg == true" class="resultRice">
					※インターネットでの日時・人数などの変更・キャンセルは、見学日前日12時までとなっております。<br>
					ご予約の変更・キャンセルは<router-link tag="a" :to="{path: '/miyagikyo/reservation/reserve'}" class="resultRice_link">こちら</router-link>からお願いいたします。
				</li>
				<li v-show="mygflg == false && this.reserve_flg == true" class="resultRice">
					※インターネットでの日時・人数などの変更・キャンセルは、見学日前日12時までとなっております。<br>
					ご予約の変更・キャンセルは<router-link tag="a" :to="{path: '/yoichi/reservation/reserve'}" class="resultRice_link">こちら</router-link>からお願いいたします。
				</li>
				<li class="resultList">
					<p class="resultList_ttl">予約番号</p>
					<p class="resultList_txt">{{ password }}</p>
				</li>
			</ul>
			<ul class="explanList">
				<li class="explanList_li">・上記の予約番号については、ご予約いただきました内容とあわせ、ご登録いただいたメールアドレス宛に「確認メール」としてご送付しておりますのでご確認ください。</li>
				<li class="explanList_li">・ご予約の変更、キャンセルをご希望の場合は、予約申込ページ上部の予約変更・キャンセルボタンで変更、キャンセルが可能です。</li>
			</ul>
		</div>
		<p class="result_txt" v-if="reserve_flg == false" v-html="resultTxt"></p>
		<a href="/miyagikyo/reservation" class="goToLink" v-if="reserveErr == false">トップページへ戻る</a>
		<router-link tag="a" :to="{path: '/miyagikyo/reservation', query: { date: selectedDate }}" class="goToLink" v-if="reserveErr == true && mygflg == true">日時選択へ戻る</router-link>
		<router-link tag="a" :to="{path: '/yoichi/reservation', query: { date: selectedDate }}" class="goToLink" v-if="reserveErr == true && mygflg == false">日時選択へ戻る</router-link>
		<Tel />
	</div>
</template>

<script>
import Tel from './Tel'

export default {
	name: 'Result',
	components: {
		Tel
	},
	data () {
		return {
			plant_id: localStorage.getItem('plantId'),
			plantName: null,
			complementName: null,
			mygflg: false,
			selectedDate: null,
			errors: JSON.parse(localStorage.getItem('errs')),
			reserveErr: false,
			err_flg: false,
			ttlMessage: null,
			resultTxt: null,
			reserve_flg: false,
			password: localStorage.getItem('password')
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
			separator: '|',
			complement: this.complementName
			}
		},
		link: [
      { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
      { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    ],
    script: [
      { type: 'text/javascript', src: '/include/include.js' }
    ]
	},
	mounted() {
		// 一番上まで移動
		window.$("body, html").scrollTop(0);

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(location.pathname.indexOf('miyagikyo') != -1) {
				this.$router.push({ path: '/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = '宮城峡蒸溜所';
		}else {
			this.plantName = '余市蒸溜所';
		}

		// トップに戻る先の変更デフォルトが宮城峡トップなのでpland_idが1001の場合は余市のトップをリンク先に設定する
		if(this.plant_id == 1000) {
			this.mygflg = true;
			this.selectedDate = localStorage.getItem('selectedDate');
		}else {
			window.$(".goToLink").attr('href', '/yoichi/reservation');
		}

		if(location.pathname.indexOf('reservation/error') != -1) {
			this.reserveErr = true;
			this.complementName = 'ご予約受付失敗';
			if(this.errors[0].errcode == 500999 || this.errors[0].errcode == 500001 || this.errors[0].errcode == 401000 || this.errors[0].errcode == 401010 || this.errors[0].errcode == 400010 || this.errors[0].errcode == 400075) {
				this.ttlMessage = 'ご予約ができませんでした';
				this.resultTxt = '予約に失敗しました。';	
			}else if(this.errors[0].errcode == 400060 || this.errors[0].errcode == 400065) {
				this.ttlMessage = '定員となり、ご予約ができませんでした。';
				this.resultTxt = '誠に申し訳ございません。ご希望のコース、時間が定員に達しました。ほかの時間をご検討ください。';
			}else {
				this.ttlMessage = 'ご予約ができませんでした';
				this.resultTxt = this.errors[0].message;
			}
		}else if(location.pathname.indexOf('thanks') != -1) {
			this.ttlMessage = 'ありがとうございます。<br>ご予約を受け付けました';
			this.reserve_flg = true;
			this.complementName = 'ご予約受付完了';
		}else if(location.pathname.indexOf('cancel') != -1) {
			this.ttlMessage = 'キャンセル完了<br>ご予約のキャンセルを<br class="sp">受け付けました';
			this.resultTxt = 'ご連絡いただきありがとうございました。<br class="sp">またのご予約をお待ちしています。';
			this.complementName = 'キャンセル完了';
			localStorage.removeItem('password');
		}else {
			this.ttlMessage = 'キャンセルに失敗しました';
			this.resultTxt = this.errors[0].message;
			this.complementName = 'キャンセル失敗';
		}
		this.$emit('updateHead');

		if(this.reserveErr == false) {
			// それ以外のlocalStorageは全て外す
			localStorage.removeItem('courseInfo');
			localStorage.removeItem('selectCourseInfo');
			localStorage.removeItem('prefData');
			localStorage.removeItem('visitNum');
			localStorage.removeItem('comeWay');
			localStorage.removeItem('selectedDate');
			localStorage.removeItem('selectedTime');
			localStorage.removeItem('selectedEvent');
			// localStorage.removeItem('men');
			// localStorage.removeItem('women');
			localStorage.removeItem('adult_num');
			localStorage.removeItem('adult_num');
			localStorage.removeItem('minor_num');
			localStorage.removeItem('total_num');
			localStorage.removeItem('group_name');
			localStorage.removeItem('group_name_kana');
			localStorage.removeItem('companion_name');
			localStorage.removeItem('companion_name_kana');
			localStorage.removeItem('pref');
			localStorage.removeItem('tel');
			localStorage.removeItem('email');
			localStorage.removeItem('count');
			localStorage.removeItem('visit');
			localStorage.removeItem('unit');
			// localStorage.removeItem('note');
			localStorage.removeItem('old_tel');
			localStorage.removeItem('resId');
			localStorage.removeItem('errs');
			localStorage.removeItem('error_code');
			localStorage.removeItem('allergy');
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.result {
		padding-top: 70px;
		&_ttl {
			font-size: 32px;
			text-align: center;
			font-weight: 300;
			margin-bottom: 52px;
		}
		&_txt {
			font-size: 18px;
			text-align: center;
			line-height: 2 !important;
		}
		&_red {
			font-size: 18px;
			width: 600px;
			line-height: 2 !important;
			margin: 10px auto 40px;
			list-style: none;
			color: #DF0202;
		}
	}

	// 768以下
	.picxel .result {
		padding-top: 40px;
		&_ttl {
			font-size: 22px;
			margin-bottom: 40px;
		}
		&_txt {
			font-size: 12px;
			width: calc(100% - 50px);
			margin: 0 auto;
		}
		&_red {
			font-size: 12px;
			margin: 20px auto 30px;
			width: calc(100% - 50px);
			li {
				margin-bottom: 5px;
			}
		}
	}

	.resultBox {
		width: calc(100% - 100px);
		max-width: 840px;
		margin: 0 auto;
		&_careful {
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			color: #DF0202;
		}
	}

	// 768以下
	.picxel .resultBox {
		width: calc(100% - 50px);
		&_careful {
			text-align: left;
			font-size: 15px;
		}
	}

	.win .mainFont .resultRice {
		font-size: 14px;
		line-height: 1.75 !important;
		margin-top: 52px;
		&_link {
			color: #AF9754;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.picxel .resultRice {
		font-size: 12px;
		margin-top: 40px;
	}

	.resultList {
		list-style: none;
		border-top: 1px solid #E8E8E8;
		border-bottom: 1px solid #E8E8E8;
		margin-top: 24px;
		padding: 30px 20px;
		box-sizing: border-box;
		display: flex;
		&_ttl {
			position: relative;
			font-size: 18px;
			font-weight: bold;
			width: 212px;
			&::after {
				content: "：";
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
		&_txt {
			font-size: 18px;
			padding-left: 70px;
			width: calc(100% - 212px);
			box-sizing: border-box;
		}
	}

	// 768以下
	.picxel .resultList {
		width: 100%;
		border-top: 1px solid #E8E8E8;
		border-bottom: 1px solid #E8E8E8;
		margin-top: 24px;
		padding: 20px 0;
		&_ttl {
			font-size: 15px;
			width: 90px;
		}
		&_txt {
			width: calc(100% - 113px);
			font-size: 15px;
			padding-left: 15px;
		}
	}

	.explanList {
		list-style: none;
		margin-top: 24px;
		&_li {
			font-size: 18px;
		}
	}

	// 768以下
	.picxel .explanList {
		width: 100%;
		margin: 15px auto 0;
		&_li {
			font-size: 12px;
		}
	}

	.goToLink {
		display: block;
		text-decoration: none;
		font-size: 18px;
		width: 320px;
		text-align: center;
		line-height: 1 !important;
		margin: 40px auto 79px;
		background-color: #AF9754;
		color: #fff;
		padding: 21px 0;
		cursor: pointer;
	}

	// 768以下
	.picxel .goToLink {
		font-size: 15px;
		width: calc(100% - 50px);
		margin: 50px auto 40px;
		padding: 21px 0;
	}

	.pc {
		display: block;
	}

	// 768以下
	.picxel .pc {
		display: none;
	}
</style>
