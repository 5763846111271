<template>
  <div id="app">
    <div class="container clearfix">
      <div v-show="engflg" class="engCommon" v-html="gnaviHtml"></div>
      <div class="main-column" v-if="engflg">
        <div class="link_japanese">
          <a href="https://www.nikka.com/">Japanese website</a>
        </div>
        <router-view class="mainFont" />
      </div>

      <router-view class="mainFont" v-else />
    </div>

    <div v-show="engflg" class="engCommon" v-html="footerHtml"></div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      engflg: false,
      gnaviHtml: "",
      footerHtml: ""
    }
	},
	head: {
    meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: '蒸溜所,北海道,余市,仙台,宮城峡,工場,蒸溜所,NIKKA,WHISKY,ニッカ,ニッカウヰスキー,ウイスキー'},
      { name: 'description', content: 'ニッカウヰスキー北海道工場余市蒸溜所、仙台工場宮城峡蒸溜所のご紹介。2つの蒸溜所の特徴や施設の詳細などご覧いただけます。無料の蒸溜所見学のご案内もございます。' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'http://nikka.com/distilleries/' },
      { property: 'og:title', content: '工場見学お申し込み | NIKKA WHISKY' },
      { property: 'og:description', content: 'ニッカウヰスキー北海道工場余市蒸溜所、仙台工場宮城峡蒸溜所のご紹介。2つの蒸溜所の特徴や施設の詳細などご覧いただけます。無料の蒸溜所見学のご案内もございます。' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY' },
      { property: 'og:locale', content: 'ja_JP' },
    ]
  },
  created() {
    // 英語ページか否か
    if(location.pathname.indexOf('eng') != -1) {
      window.$("body").addClass("en");
      this.engflg = true;
    }else {
      window.$("body").addClass("jp");
      this.engflg = false;
    }
        
    fetch("/eng/inc/gnavi.html")
    .then((response) => response.text())
    .then((gnavi) => {
      this.gnaviHtml = gnavi;
    });

    fetch("/eng/inc/eng_footer.html")
    .then((response) => response.text())
    .then((footer) => {
      this.footerHtml = footer;
    });
  },
  mounted() {


    this.pixcel();
    window.addEventListener('resize', this.pixcel);
  },
  beforeDestroy() {
		window.addEventListener('resize', this.pixcel);
	},
  methods: {
    pixcel() {
      var Pixcel = window.$("#app").width();
			if(Pixcel <= 768) {
				// スマホ
				window.$("body").addClass("picxel");
			}else {
				// PC
				window.$("body").removeClass("picxel");
			}
    }
  }
}
</script>

<style>
@import "https://fonts.googleapis.com/css?family=Noto+Sans+JP";
@import "https://fonts.cdnfonts.com/css/times-new-roman";
/* @import "https://www.nikka.com/common/css/reset.css";
@import "https://www.nikka.com/common/css/style.css"; */

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans CJK JP"),
    local("Noto Sans JP"),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
}

* {
  padding: 0;
  margin: 0;
}

body,html {
  width: 100%;
  min-width: 100%;
}

@media screen and (max-width: 788px) {
  body, html {
    min-width: 100% !important;
  }

  .win.picxel #header {
    height: 60px;
  }

  .win.picxel #header_inner #logo {
    height: 34.5px;
    width: 150px;
    top: 14px;
  }

  .win.picxel #header_inner #sp_menu {
    width: 58.5px;
    top: 21px;
  }

  .win.picxel #header_inner #sp_menu img {
    width: 100%;
  }

  .win.picxel #sp_menu_content_inner {
    width: calc(100% - 25px);
    margin: 0;
    transform: translateX(-50%);
  }

  .win.picxel #sp_menu_close {
    width: 40px;
    top: -24px;
    right: -6px;
  }

  .win.picxel #sp_menu_content_inner a {
    width: calc(50% - 20px);
  }

  .win.picxel #sp_menu_content_inner a img {
    width: 100%;
  }

  .win.picxel .backToTop a {
    width: 112px;
    height: 15.5px;
    background-size: contain;
  }

  .win.picxel #sp_footer_menu li a {
    font-size: 15px;
    margin: 15px auto 0 auto;
    padding: 0 0 0 25px;
    background-size: 15px;
    background-position: center left;
  }
}

.win .mainFont *{
  font-family: 'Noto Sans JP', sans-serif !important;
  line-height: 1.5 !important;
}

.win .mainFont.eng * {
  font-family: 'Times New Roman', sans-serif !important;
  line-height: 1.5 !important;
}

#header {
  font-family: "ＭＳ Ｐゴシック","MS PGothic","メイリオ", "Meiryo",sans-serif !important;
}

#footer {
  font-family: "ＭＳ Ｐゴシック","MS PGothic","メイリオ", "Meiryo",sans-serif !important;
}

body {
  line-height: 1.5 !important;
}

body.fixed {
  position: fixed;
  width: 100%;
  left: 0;
}

#app {
  /* font-family: "HiraMinProN-W6", "Noto Serif JP", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  width: 100%;
  overflow: hidden;
}

.win .mainFont .mincho {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif !important;
}

.win .mainFont .mincho p{
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif !important;
}

.picxel.win .mainFont *{
  font-family: 'Noto Sans JP', sans-serif !important;
  line-height: 1.5 !important;
}

.picxel.win .mainFont.eng *{
  font-family: 'Times New Roman', sans-serif !important;
  line-height: 1.5 !important;
  letter-spacing: 0.05rem !important;
}

.picxel.win .mainFont .mincho {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif !important;
}

.picxel #wrapper {
  width: 100%;
  min-width: 100%;
  background-size: 320px auto;
  overflow-x: hidden;
}

.picxel.en #wrapper {
  width: 100%;
  min-width: 100%;
}

/* 強制上書き */
.container {
  width: 100% !important;
}

.picxel.eng #wrapper {
  width: 100%;
  min-width: 100%;
}

/* 英語独自の設定 */
.en {
  background-color: #251E1C;
}

.en .container.clearfix {
  position: relative;
  width: 100%;
  max-width: 970px !important;
  margin: 0 auto;
}

.en .container.clearfix .mainFont {
  width: 746px;
  margin: 0 0 0 auto;
}

.en.picxel .container.clearfix .mainFont {
  width: 100%;
  margin: 0 0 0 auto;
}

.win #header {
  min-width: 100%;
}

.win.picxel .backToTop {
  width: calc(100% - 20px);
}

/* .picxel #footer #sp_footer_menu {
  display: calc(100% - 20px);
} */

/* 英語の時は日本語パーツ削除 */
.en #header {
  display: none;
}
.en .backToTop {
  display: none;
}
.en #footer {
  display: none;
}

.en .engCommon #header,
.en .engCommon #footer {
  display: block;
}
</style>
