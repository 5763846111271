<template>
	<div class="check eng">
		<div class="sendContent">
			<section class="checkSec">
				<h3 class="checkSec_ttl">Reservation change/<br class="sp">Cancellation</h3>
				<section class="customerInfo">
					<h4 class="customerInfo_ttl">Customer Information</h4>
					<ul class="inputLists">
						<li class="inputList" v-if="selectEvent">
							<p class="inputList_ttl">Reservation date and time</p>
							<div class="inputDate inputList_cont">
								<p class="inputDate_date">{{ selectDay }}, {{ selectMonth }} {{ selectDate }}, {{ selectYear }}</p>
								<div class="inputDate_flex">
									<p v-html="selectEvent.rsv_course_name"></p>
									<p class="inputDate_time">{{ retakeDate(selectedTime) }}</p>
								</div>
							</div>
						</li>
						<!-- <li class="inputList" v-if="paidInfo == true">
							<p class="inputList_ttl">料金</p>
							<p class="inputList_cont">{{ price }}</p>
						</li> -->
						<li class="inputList">
							<p class="inputList_ttl">Number of visitors</p>
							<div class="peopleBox">
								<div class="peopleMore">
									<p class="peopleMore_people">Over 20 years old {{ adult_num }} person</p>
									<!-- <p class="peopleMore_people">女性{{ women }}人</p> -->
								</div>
								<div class="peopleBelow" v-show="minor_flg == true">
									<p class="peopleBelow_less">Under 20 years old {{ minor_num }} person</p>
								</div>
							</div>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">Representative Name</p>
							<p class="inputList_cont">{{ group_name }}</p>
						</li>
						<!-- <li class="inputList">
							<p class="inputList_ttl">代表者名（ふりがな）</p>
							<p class="inputList_cont">{{ group_name_kana }}</p>
						</li>
						<li class="inputList" v-if="paidInfo == true && total_num >= 2 && mygflg == false">
							<p class="inputList_ttl">同伴者様</p>
							<p class="inputList_cont">{{ companion_name }}</p>
						</li>
						<li class="inputList" v-if="paidInfo == true && total_num >= 2 && mygflg == false">
							<p class="inputList_ttl">同伴者様（ふりがな）</p>
							<p class="inputList_cont">{{ companion_name_kana }}</p>
						</li> -->
						<li class="inputList">
							<p class="inputList_ttl">Country/Region</p>
							<p class="inputList_cont">{{ prefName }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">Phone number</p>
							<p class="inputList_cont">{{ tel }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">Email address</p>
							<p class="inputList_cont">{{ email }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">Number of visits</p>
							<p class="inputList_cont">{{ countNum }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">Method of transportation</p>
							<p class="inputList_cont">{{ visitWay }}</p>
						</li>
						<!-- <li class="inputList" v-if="unit != null && unit != 'null'">
							<p class="inputList_ttl">ご来場台数</p>
							<p class="inputList_cont">{{ unit }}台</p>
						</li>
						<li class="inputList" v-if="selectCourseInfo[0].chk_allergy_flg == 1">
							<p class="inputList_ttl">アレルギー有無確認</p>
							<p class="inputList_cont" v-show="allergy == 0">アレルギー無し</p>
							<p class="inputList_cont" v-show="allergy == 1">アレルギー有り</p>
						</li> -->
						<!-- <li class="inputList" v-if="mygflg == false && note != null && note != 'null'">
							<p class="inputList_ttl">備考</p>
							<pre class="inputList_cont inputList_note">{{ note }}</pre>
						</li> -->
					</ul>
					<div class="btns">
						<button type="button" class="moveBtn changeBtn" @click="changeCont()">Change input details</button>
						<button type="button" class="moveBtn cancelBtn" @click="openModal()">Cancel reservation</button>
					</div>
				</section>
			</section>
		</div>
		<router-link tag="a" to="/eng/miyagikyo/reservation" class="goToLink" v-show="mygflg == true">Return to Top Page</router-link>
		<router-link tag="a" to="/eng/yoichi/reservation" class="goToLink" v-show="mygflg == false">Return to Top Page</router-link>
		<Tel />

		<div class="modal">
			<div class="modal_img">
				<img src="../assets/img/careful_red.png" alt="careful">
			</div>
			<p class="modal_ttl">Are you sure <br class="sp">you want to <br class="sp">cancel ?</p>
			<p class="modal_txt">Reservation details will be<br class="sp"> invalid after cancellation</p>
			<div class="carefuls">
				<button type="button" class="carefuls_close" @click="closeModal()">Close</button>
				<button type="button" class="carefuls_cancel" @click="reserveCancel()">Cancel</button>
			</div>
		</div>
		<div class="modalShadow" @click="closeModal()"></div>
	</div>
</template>

<script>
import $ from 'jquery';
import Tel from './EngTel'
import axios from 'axios'

export default {
	name: 'RservationTop',
	components: {
		Tel
	},
	data () {
		return {
			old_tel: localStorage.getItem('old_tel'),
			res_id: localStorage.getItem('resId'),
			password: localStorage.getItem('password'),
			plant_id: localStorage.getItem('plantId'),
			plantName: null,
			course_id: localStorage.getItem('course_id'),
			courseInfo: JSON.parse(localStorage.getItem('courseInfo')),
			selectCourseInfo: JSON.parse(localStorage.getItem('selectCourseInfo')),
			prefData: JSON.parse(localStorage.getItem('prefData')),
			visitNum: JSON.parse(localStorage.getItem('visitNum')),
			comeWay: JSON.parse(localStorage.getItem('comeWay')),
			selectedDate: localStorage.getItem('selectedDate'),
			selectedTime: localStorage.getItem('selectedTime'),
			selectEvent: JSON.parse(localStorage.getItem('selectedEvent')),
			price: 0,
			selectYear: null,
			selectMonth: null,
			selectDate: null,
			selectDay: null,
			dayOfMonthStr: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
			dayOfWeekStr: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
			paidInfo: false,
			movePlan: false,
			mygflg: false,
			// 新規取得データ
			targetDate: null,
			minor_flg: false,
			// 入力項目
			// men: localStorage.getItem("men"),
			// women: localStorage.getItem("women"),
			adult_num: localStorage.getItem("adult_num"),
			minor_num: this.minor_num = localStorage.getItem("minor_num"),
			total_num: localStorage.getItem("total_num"),
			group_name: localStorage.getItem("group_name"),
			group_name_kana: localStorage.getItem("group_name_kana"),
			companion_name: null,
			companion_name_kana: null,
			pref: localStorage.getItem("pref"),
			prefName: null,
			tel: localStorage.getItem("tel"),
			email: localStorage.getItem("email"),
			count: localStorage.getItem("count"),
			countNum: null,
			visit: localStorage.getItem("visit"),
			visitWay: null,
			unit: localStorage.getItem("unit"),
			allergy: localStorage.getItem("allergy"),
			// note: localStorage.getItem("note"),
			url: "/api/reserve/delete",
			scrollPos: '',
			errors: null,
			winter: null
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		// 20歳未満が参加できるか否か
		if(this.selectEvent.condition_minor_disallow_flg == 2) {
			this.minor_flg = true;
		}else {
			this.minor_flg = false;
		}

		// 金額
		this.price = this.selectEvent.rsv_course_fee;
		// this.price = this.price.replace(/,/g, '');
		// this.price = this.price.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );

		// 住所のnameを保存していたidを元に探す
		for(var k = 0; k < this.prefData.length; k++) {
			if(this.prefData[k].departure_id == this.pref) {
				this.prefName = this.prefData[k].departure_name;
			}
		}

		// this.visitの値を元にご来場手段のnameを探す
		for(var m = 0; m < this.comeWay.length; m++) {
			if(this.comeWay[m].come_way_id == this.visit) {
				this.visitWay = this.comeWay[m].come_way_name;
			}
		}

		// this.coutの値を元にご来場回数のnameを探す
		for(var l = 0; l < this.visitNum.length; l++) {
			if(this.visitNum[l].vst_come_num_id == this.count) {
				this.countNum = this.visitNum[l].vst_come_num_name;
			}
		}

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(this.mygflg == true) {
				this.$router.push({ path: '/eng/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = 'Miyagikyo Distillery';
		}else {
			this.plantName = 'Yoichi Distillery';
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
		}
		this.$emit('updateHead');

		this.selectYear = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getUTCFullYear();
		this.selectMonth = this.dayOfMonthStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getUTCMonth()];
		this.selectDate = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getUTCDate();
		this.selectDay = this.dayOfWeekStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDay()];
	},
	methods: {
		retakeDate(time) {
			var slice = Number(time);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		changeCont() {
			if(this.plant_id == 1000) {
				this.$router.push({ path: '/eng/miyagikyo/reservation/form' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation/form' });
			}
		},

		openModal() {
			this.scrollPos = $(window).scrollTop();
			$("body").addClass("fixed").css({
				top: -this.scrollPos
			});
			$(".modal, .modalShadow").show();
		},

		closeModal() {
			$("body").removeClass("fixed").css({
				top: 0
			});
			window.scrollTo(0, this.scrollPos);
			$(".modal, .modalShadow").hide();
		},

		reserveCancel() {
			axios({
				method: "post",
				url: this.url,
				timeout: 5000000,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					reservation_id: this.res_id,
					telno: this.old_tel,
					password: this.password,
					language: 1
				}
			}).then(() => {
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref_id');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				// bodyの固定を解除する
				$("body").removeClass("fixed").css({
					top: 0
				});
				// 結果ページに飛ぶ
				if(location.pathname.indexOf('miyagikyo') != -1) {
					this.$router.push({ path: '/eng/miyagikyo/reservation/cancel' });
				}else {
					this.$router.push({ path: '/eng/yoichi/reservation/cancel' });
				}
			}).catch(err => {
				// bodyの固定を解除する
				$("body").removeClass("fixed").css({
					top: 0
				});
				this.errors = err.response.data.data;
				localStorage.setItem('errs', JSON.stringify(this.errors));
				// 失敗ページに飛ぶ
				this.$router.push({ path: '/eng/reservation/fatal_error' });
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.check.eng {
		background-color: #251E1C;
	}

	.eng .sendContent {
		width: 100%;
		max-width: 746px;
		margin: 0 auto;
		padding: 124px 0 40px;
		border-top: none;
	}

	// 768以下
	.picxel .eng .sendContent {
		width: calc(100% - 20px);
		padding: 40px 0;
		border-top: none;
	}

	.eng .checkSec {
		margin-top: 0;
		color: #FFF;
		&_ttl {
			margin: 0 auto;
			font-size: 42px;
			letter-spacing: 0.04em;
			text-align: center;
			font-weight: 400;
		}
	}

	// 768以下
	.picxel .eng .checkSec {
		margin-top: 0;
		&_ttl {
			font-size: 28px;
		}
	}

	.eng .customerInfo {
		&_ttl {
			font-size: 24px;
			font-weight: 400;
			color: #FFF;
			margin: 48px 0 33px;
		}
	}

	// 768以下
	.picxel .eng .customerInfo {
		&_ttl {
			font-size: 20px;
			margin: 40px 0 32px;
		}
	}

	// 入力確認
	.eng .inputLists {
		background-color: transparent;
		width: 100%;
		padding: 0;
		list-style: none;
		box-sizing: border-box;
	}

	// 768以下
	.picxel .eng .inputLists {
		padding: 0;
		border-top: 1px solid #595858;
	}

	.eng .inputList {
		display: flex;
		align-items: flex-start;
		border-bottom: 1px solid #595858;
		margin-bottom: 0;
		padding: 24px 30px;
		&_ttl {
			width: calc(100% - 442px);
			font-size: 17px;
			color: #898989;
			margin-bottom: 0;
		}
		&_cont {
			width: 442px;
			font-size: 17px;
			font-weight: 400;
			line-height: 1.5 !important;
			color: #FFF;
		}
		// &_note {
		// 	white-space: pre-wrap;
		// }
	}

	// 768以下
	.picxel .eng .inputList {
		display: block;
		margin-bottom: 0;
		&_ttl {
			width: 100%;
			font-size: 17px;
			margin-bottom: 16px;
		}
		&_cont {
			width: 100%;
			font-size: 17px;
		}
	}

	// コース
	.eng .inputDate {
		display: block;
		&_date {
			margin-right: 0;
		}
		&_flex {
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			p {
				word-break: break-all;
			}
		}
		&_time {
			margin-left: 8px;
		}
	}

	// 768以下
	.picxel .eng .inputDate {
		&_date {
			margin-right: 0;
		}
	}

	// ご予約人数
	.eng .peopleBox {
		display: block;
	}

	// 768以下
	.picxel .eng .peopleBox {
		font-size: 17px;
	}

	.eng .peopleMore {
		display: block;
		&_people {
			font-size: 17px;
			font-weight: 400;
			margin-left: 0;
		}
	}

	// 768以下
	.picxel .eng .peopleMore {
		display: block;
		&_people {
			display: block;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.eng .peopleBelow {
		display: block;
		margin-left: 0;
		&_less {
			font-size: 17px;
			font-weight: 400;
			margin-left: 0;
		}
	}

	// 768以下
	.picxel .eng .peopleBelow {
		display: block;
		margin-left: 0;
		margin-top: 0;
		&_less {
			margin-left: 0;
		}
	}

	// ボタン
	.eng .btns {
		width: 100%;
		max-width: 480px;
		margin: 48px auto 0;
		display: block;
		.moveBtn {
			font-size: 20px;
			text-align: center;
			line-height: 1 !important;
			outline: none;
			cursor: pointer;
			border: none;
			width: 100%;
			padding: 17px 0;
			color: #FFF;
			border-radius: 4px;
		}
		.changeBtn {
			position: relative;
			text-decoration: none;
			text-align: center;
			background-color: #91701e;
			margin-bottom: 16px;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: 32px;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 10px;
				border-color: transparent transparent transparent #FFF;
			}
		}
		.cancelBtn {
			background-color: #D60000;
		}
	}

	// 768以下
	.picxel .eng .btns {
		width: 100%;
		display: block;
		margin-top: 32px;
		.moveBtn {
			width: 100%;
			font-size: 20px;
			padding: 17px 0;
			margin-bottom: 0;
		}
		.changeBtn {
			padding: 25px 0;
			margin-bottom: 16px;
		}
	}

	.goToLink {
		display: block;
		text-decoration: none;
		font-size: 20px;
		width: 100%;
		max-width: 480px;
		text-align: center;
		line-height: 1 !important;
		margin: 0 auto 80px;
		background-color: #898989;
		color: #fff;
		padding: 17px 0;
		border-radius: 4px;
		cursor: pointer;
	}

	// 768以下
	.picxel .eng .goToLink {
		font-size: 20px;
		width: calc(100% - 20px);
		margin-bottom: 64px;
		padding: 17px 0;
	}

	.check.eng .modal {
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		background-color: #251E1C;
		width: 640px;
		padding: 56px 63px;
		box-sizing: border-box;
		&_img {
			display: block;
			width: 50px;
			margin: 0 auto 10px;
			img {
				display: block;
				width: 100%;
			}
		}
		&_ttl {
			font-size: 26px;
			text-align: center;
			color: #FFF;
			margin-bottom: 16px;
		}
		&_txt {
			font-size: 17px;
			color: #FFF;
			text-align: center;
		}
	}

	// 768以下
	.picxel .check.eng .modal {
		width: calc(100% - 20px);
		padding: 32px 16px 48px;
		&_img {
			width: 50px;
			margin-bottom: 10px;
		}
		&_ttl {
			font-size: 36px;
		}
		&_txt {
			font-size: 17px;
		}
	}

	.eng .carefuls {
		width: 100%;
		max-width: 456px;
		display: flex;
		justify-content: space-between;
		margin: 32px auto 0;
		&_cancel {
			width: calc(50% - 8px);
			font-size: 20px;
			color: #fff;
			outline: none;
			background-color: #D60000;
			padding: 17px 0;
			border: none;
			border-radius: 4px;
			cursor: pointer;
		}
		&_close {
			width: calc(50% - 8px);
			font-size: 20px;
			line-height: 1;
			color: #FFF;
			outline: none;
			background: #898989;
			padding: 17px 0;
			border: none;
			border-radius: 4px;
			cursor: pointer;
		}
	}

	// 768以下
	.picxel .eng .carefuls {
		width: calc(100% - 40px);
		display: block;
		margin-top: 24px;
		&_cancel {
			width: 100%;
			font-size: 20px;
			margin-bottom: 0;
			padding: 17px 0;
			border: none;
		}
		&_close {
			width: 100%;
			font-size: 20px;
			margin-bottom: 8px;
			padding: 17px 0;
			border: none;
		}
	}

	.modalShadow {
		display: none;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		background-color: rgba($color: #000000, $alpha: 0.8);
		cursor: pointer;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}
</style>
