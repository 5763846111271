import Vue from 'vue'
import VueHead from 'vue-head'
import VueRouter from 'vue-router'
import ReservationTop from '@/components/ReservationTop'
import EngReservationTop from '@/components/EngReservationTop'
import ReservationTwo from '@/components/ReservationTwo'
import EngReservationTwo from '@/components/EngReservationTwo'
import ReservationThree from '@/components/ReservationThree'
import EngReservationThree from '@/components/EngReservationThree'
import ReservationInput from '@/components/ReservationInput'
import EngReservationInput from '@/components/EngReservationInput'
import ReservationCheck from '@/components/ReservationCheck'
import EngReservationCheck from '@/components/EngReservationCheck'
import Result from '@/components/Result'
import EngResult from '@/components/EngResult'
import Maintenance from '@/components/Maintenance'
import EngMaintenance from '@/components/EngMaintenance'
import SelectEvent from '@/components/SelectEvent'
import EngSelectEvent from '@/components/EngSelectEvent'
import EventDetail from '@/components/EventDetail'
import EventInfo from '@/components/EventInfo'
// import Info from '@/components/Info'

Vue.use(VueHead)
Vue.use(VueRouter)

const routes = [
  {
    path: '/miyagikyo/reservation/',
    name: 'ReservationTop',
    component: ReservationTop
  },
  {
    path: '/eng/miyagikyo/reservation/',
    name: 'EngReservationTop',
    component: EngReservationTop
  },
  {
    path: '/yoichi/reservation/',
    name: 'ReservationTop',
    component: ReservationTop
  },
  {
    path: '/eng/yoichi/reservation/',
    name: 'EngReservationTop',
    component: EngReservationTop
  },
  {
    path: '/miyagikyo/reservation/form/',
    name: 'ReservationTwo',
    component: ReservationTwo
  },
  {
    path: '/yoichi/reservation/form/',
    name: 'ReservationTwo',
    component: ReservationTwo
  },
  {
    path: '/eng/miyagikyo/reservation/form/',
    name: 'EngReservationTwo',
    component: EngReservationTwo
  },
  {
    path: '/eng/yoichi/reservation/form/',
    name: 'EngReservationTwo',
    component: EngReservationTwo
  },
  {
    path: '/miyagikyo/reservation/confirm/',
    name: 'ReservationThree',
    component: ReservationThree
  },
  {
    path: '/yoichi/reservation/confirm/',
    name: 'ReservationThree',
    component: ReservationThree
  },
  {
    path: '/eng/miyagikyo/reservation/confirm/',
    name: 'EngReservationThree',
    component: EngReservationThree
  },
  {
    path: '/eng/yoichi/reservation/confirm/',
    name: 'EngReservationThree',
    component: EngReservationThree
  },
  {
    path: '/miyagikyo/reservation/reserve/',
    name: 'ReservationInput',
    component: ReservationInput
  },
  {
    path: '/yoichi/reservation/reserve/',
    name: 'ReservationInput',
    component: ReservationInput
  },
  {
    path: '/eng/miyagikyo/reservation/reserve/',
    name: 'EngReservationInput',
    component: EngReservationInput
  },
  {
    path: '/eng/yoichi/reservation/reserve/',
    name: 'EngReservationInput',
    component: EngReservationInput
  },
  {
    path: '/miyagikyo/reservation/reserveconfirm/',
    name: 'ReservationCheck',
    component: ReservationCheck
  },
  {
    path: '/yoichi/reservation/reserveconfirm/',
    name: 'ReservationCheck',
    component: ReservationCheck
  },
  {
    path: '/eng/miyagikyo/reservation/reserveconfirm/',
    name: 'EngReservationCheck',
    component: EngReservationCheck
  },
  {
    path: '/eng/yoichi/reservation/reserveconfirm/',
    name: 'EngReservationCheck',
    component: EngReservationCheck
  },
  {
    path: '/miyagikyo/reservation/error/',
    name: 'Result',
    component: Result
  },
  {
    path: '/yoichi/reservation/error/',
    name: 'Result',
    component: Result
  },
  {
    path: '/miyagikyo/reservation/thanks/',
    name: 'Result',
    component: Result
  },
  {
    path: '/yoichi/reservation/thanks/',
    name: 'Result',
    component: Result
  },
  {
    path: '/miyagikyo/reservation/cancel/',
    name: 'Result',
    component: Result
  },
  {
    path: '/yoichi/reservation/cancel/',
    name: 'Result',
    component: Result
  },
  {
    path: '/reservation/fatal_error/',
    name: 'Result',
    component: Result
  },
  {
    path: '/eng/miyagikyo/reservation/error/',
    name: 'EngResult',
    component: Result
  },
  {
    path: '/eng/yoichi/reservation/error/',
    name: 'EngResult',
    component: EngResult
  },
  {
    path: '/eng/miyagikyo/reservation/thanks/',
    name: 'EngResult',
    component: EngResult
  },
  {
    path: '/eng/yoichi/reservation/thanks/',
    name: 'EngResult',
    component: EngResult
  },
  {
    path: '/eng/miyagikyo/reservation/cancel/',
    name: 'EngResult',
    component: EngResult
  },
  {
    path: '/eng/yoichi/reservation/cancel/',
    name: 'EngResult',
    component: EngResult
  },
  {
    path: '/eng/reservation/fatal_error/',
    name: 'EngResult',
    component: EngResult
  },
  {
    path: '/reservation/miyagikyo/maintenance/',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/reservation/yoichi/maintenance/',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/eng/reservation/miyagikyo/maintenance/',
    name: 'EngMaintenance',
    component: EngMaintenance
  },
  {
    path: '/eng/reservation/yoichi/maintenance/',
    name: 'EngMaintenance',
    component: EngMaintenance
  },
  {
    path: '/miyagikyo/reservation/select_event/',
    name: 'SelectEvent',
    component: SelectEvent
  },
  {
    path: '/yoichi/reservation/select_event/',
    name: 'SelectEvent',
    component: SelectEvent
  },
  {
    path: '/eng/miyagikyo/reservation/select_event/',
    name: 'EngSelectEvent',
    component: EngSelectEvent
  },
  {
    path: '/eng/yoichi/reservation/select_event/',
    name: 'EngSelectEvent',
    component: EngSelectEvent
  },
  {
    path: '/event/detail/',
    name: 'EventDetail',
    component: EventDetail
  },
  // イベントページ
  {
    path: '/event/',
    name: 'EventInfo',
    component: EventInfo
  }
  // お詫びページ
  // {
  //   path: '/info/',
  //   name: 'Info',
  //   component: Info
  // }
  // {
  //   path: '/miyagikyo/event/',
  //   name: 'EventInfo',
  //   component: EventInfo
  // },
  // {
  //   path: '/yoichi/event/',
  //   name: 'EventInfo',
  //   component: EventInfo
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
